import React, { useState } from 'react';
import Images from '../themes/Images';
import * as DOMPurify from "dompurify";
// Accordion Component
const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {data.map((item, index) => (
        <div className={`accordion-item ${activeIndex === index ? 'active' : ''}`} key={index}>
          <div className="accordion-title" onClick={() => handleToggle(index)}>
            <div className='acrd-ttl-lt'> <img src={Images.list_icom} alt="Icon" style={{ width: '20px', marginRight: '8px' }} /> {item.title}</div>
            <div className='pls-mins'><img src={activeIndex === index ? Images.minus : Images.plus} /></div>
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.content),
                    }}
                  />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;