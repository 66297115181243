import { useEffect, useState } from "react";
import { Images } from "../themes";
import { Outlet, useLocation } from "react-router-dom";
import { AppRoute } from "../router/routerConstants";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import ConsumerOnboarding from "../screens/ConsumerOnboarding";
import ComingSoon from "./ComingSoon";
import { getStorage, getStorageJson } from "../utils/localStorage";
import AgeVerificationModal from "../components/AgeVerificationModal";
import { IsUserSubscribed } from "../utils/Common";
import ConsumerSearch from "../screens/creator/search/Search";

const SplashScreen = () => { 
  const [NavigateTo] = useCustomNavigate();
  const [consumerOnboarding, setConsumerOnboarding] = useState(false);
  const { state } = useLocation();

  const IsSubscribed = () => {
    const user = getStorageJson("user");
    return IsUserSubscribed(user);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isUserLogin()) {
        if (isCreator()) {
          if (isSetupComplete()) {
            NavigateTo(AppRoute.CreatorHome, true);
          } else {
            NavigateTo(AppRoute.REGISTER, true);
          }
        } else {
          NavigateTo(AppRoute.CONS_Search, true);
        }
      } else {
        // NavigateTo(AppRoute.LOGIN, true);

        if (state?.from != "logo") {
          setConsumerOnboarding(true);
        }
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const isUserLogin = () => {
    const token = getStorage("token");
    const user = getStorageJson("user");

    if (token && user && user.roleId) {
      return true;
    }
    return false;
  };

  const isCreator = () => {
    const user = getStorageJson("user");
    return user.role === "Creator";
  };

  const isSetupComplete = () => {
    const user = getStorageJson("user");
    return user.isSetupComplete;
  };

  return (
    <>
      {state?.from === "logo" || consumerOnboarding ? (
        <ConsumerSearch />
      ) : (
        // <AgeVerificationModal />
        <div className="wrapper splash">
          <div className="container">
            <div className="splash-wrap">
              <figure>
                <img src={Images.coming_soon_logo} alt="" />
              </figure>
            </div>
          </div>
          <Outlet />
        </div>
      )}
    </>
  );
};

export default SplashScreen;

/* import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Images } from "../themes";
import ComingSoon from "./ComingSoon";
const SplashScreen = () => {
  const [comingSoon, setComingSoon] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setComingSoon(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return ( <>
  { comingSoon ? (
    <ComingSoon />
  ) : (
    <div className="wrapper splash">
      <div className="container">
        <div className="splash-wrap">
          <figure>
            <img src={Images.coming_soon_logo} alt="" />
          </figure>
        </div>
      </div>
      <Outlet />
    </div>
  )}
</> );
}; 
export default SplashScreen; */
