import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as CommonFunc from "../utils/Common";
import { saveData } from "../utils/api/apiHelper";
import { Cons_CreateFolder } from "../utils/api/actionConstants";
import { deleteData } from "../utils/api/apiHelper";
import { Delete_Folder } from "../utils/api/actionConstants";
import { Get_Cons_CreateFolder_Creator } from "../utils/api/actionConstants";
import { getData } from "../utils/api/apiHelper";
const DeleteModal = ({ modalVisibility,deleteFolder }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const hideModal = () => {
    if (modalVisibility) {
      modalVisibility(false);
    }
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const saveBtnClkd = (e) => {
    e.preventDefault();
    hideModal();
    dispatch(
        deleteData({
          action: Delete_Folder,
          data: { id: deleteFolder },
        })
      ).then((r) => {
        dispatch(
            getData({
                action: Get_Cons_CreateFolder_Creator,
            })
        );
      });

  };
  return (
    <div className="modal-wrapper create-folder" onClick={hideModal}>
      <div className="model-inner">
        <div className="model-box" onClick={handleChildClick}>
          <h3>Delete Folder</h3>
          <form>
            {/* <div className="form-group">
              <input
                type="text"
                placeholder="Folder Name"
                onChange={onNameChange}
              />
            </div> */}
            <h4>Are you sure ? You want to delete this folder</h4>
            <div className="btn-block">
              <input
                type="button"
                className="submit"
                defaultValue="Delete"
                onClick={saveBtnClkd}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
