import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { AppRoute } from "../../../router/routerConstants";
import {
  LoadAllBillingAddress,
  DeleteBillingAddress,
} from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useBillingAddress, useUser } from "../../../hooks/useStore";
import { getData, deleteData } from "../../../utils/api/apiHelper";
import { saveData } from "../../../utils/api/apiHelper";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const ManageBillingAddress = () => {
  const [NavigateTo] = useCustomNavigate();
  const [seletedAddress, setSelectedAddress] = useState("");
  const dispatch = useDispatch();
  let Address = useBillingAddress();
  let user = useUser();

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = () => {
    dispatch(
      getData({
        action: LoadAllBillingAddress,
        data: { offset: 0, limit: 20 },
      })
    );
  };

  const deleteAddress = () => {
    if (!seletedAddress) return "";
    dispatch(
      deleteData({
        action: DeleteBillingAddress,
        data: { id: seletedAddress },
      })
    ).then((r) => {
      setSelectedAddress("");
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="add-money-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Manage Billing Address</h1>
            </div>
            <div className="manage-card-block">
              {Address &&
                Address.length > 0 &&
                Address.map((v, k) => {
                  return (
                    <div className="add-money-account" key={k}>
                      <div className="add-money-account-top">
                        <i>
                          <img src={Images.address_icon} alt="card_icon" />
                        </i>
                        <h5>{v.name}</h5>
                        <i
                          className="trash-icon"
                          onClick={() => setSelectedAddress(v.id)}
                        >
                          <img src={Images.trash} alt="trash" />
                        </i>
                      </div>
                      <div className="card-detail">
                        <div className="card-holder-name address-item">
                          <label>{user.name}</label>
                          <label>
                            {v.lane1}, {v.lane2}, {v.city}, {v.state},{" "}
                            {v.zipcode}, {v.country}
                          </label>
                        </div>
                        {/* <div className="card-number-block">
                          <div className="select-cotent">
                            <input
                              onChange={() => !v.isDefault && setDefault(v.id)}
                              type="checkbox"
                              name="select"
                              checked={v.isDefault}
                            />
                            <label />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              <div
                className="btn-block"
                onClick={() => NavigateTo(AppRoute.AddBillingAddress)}
              >
                <span className="submit">Add New Address</span>
                <div className="btn-loader" />
              </div>
            </div>
            <div className="loader-wrap">
              <div className="loader" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {seletedAddress && seletedAddress.length > 0 && (
        <div className="modal-wrapper">
          <div className="model-inner delete-model">
            <div className="model-box">
              <h3>Delete Address</h3>
              <figure className="delete-icon">
                <img src={Images.address_icon_delete} alt="delete" />
              </figure>
              <div className="delet-content">
                Are you sure, want to delete this Address?
              </div>
              <div className="btn-block">
                <span className="reset-btn" onClick={() => setSelectedAddress("")}>
                  Not, now
                </span>
                <div className="btn-inner" onClick={() => deleteAddress()}>
                  <span className="submit">Yes, sure</span>
                  <div className="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageBillingAddress;
