import React from "react";
import { Navigate } from "react-router-dom";

import { getStorageJson } from "./localStorage";
import { IsUserSubscribed } from "./Common";

function IsChatAllowed({ children }) {
  const user = getStorageJson("user");
  if (user.role === "Creator") {
    return children;
  } else {
    if (user && user?.id) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  }
}

export default IsChatAllowed;
