import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setStorageJson,
  setStorage,
  getStorageJson,
} from "../utils/localStorage";
const initialState = {
  userData: {},
  walletCurrent: {},
  isLoggedIn: false,
  subscription: {},
  setupPage: 0,
  wallet: [],
  withDrawal: [],
  cards: [],
  banks: [],
  bankData: {},
  address: [],
  addressData: {},
  billingAddress: [],
  paymentHistory: [],
  freeProfiles: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userData = action.user;
    },
    removeUserData: (state) => {
      state.userData = {};
    },
    nextSetupPage: (state) => {
      state.setupPage = state.setupPage + 1;
    },
    resetSetupPage: (state) => {
      state.setupPage = 0;
    },
    resetUserData: (state) => {
      state.userData = {};
      state.isLoggedIn = false;
      state.setupPage = 0;
    },
    prevSetupPage: (state) => {
      state.setupPage = state.setupPage - 1;
    },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
      setStorageJson("user", state.userData);
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {
    LoginUser: (state, action) => {
      if (action.payload) {
        let payload = action.payload;
        if (payload.authToken) {
          state.isLoggedIn = true;
          state.userData = payload.user;
          setStorageJson("user", payload.user);
          setStorage("token", payload.authToken);
        }
      }
    },
    VerifyOtp: (state, action) => {
      if (action.payload) {
        let payload = action.payload;
        if (payload.authToken) {
          state.isLoggedIn = true;
          state.userData = payload.user;
          setStorageJson("user", payload.user);
          setStorage("token", payload.authToken);
        }
      }
    },
    GetUserData: (state, action) => {
      if (action.payload) {
        state.userData = { ...state.userData, ...action.payload.user };
        setStorageJson("user", state.userData);
      }
    },
    UpdateIsBlur: (state, action) => {
      let userData = state.userData;
      if (action.payload) {
        userData.isBlur = action.payload.isBlur;
        userData.isBlurImages = action.payload.isBlurImages;
        state.userData = userData;
        setStorageJson("user", userData);
      }
    },
    UpdateIsNotification: (state, action) => {
      let userData = state.userData;
      if (action.payload) {
        Object.keys(action.payload).forEach((key) => {
          if (userData.hasOwnProperty(key)) {
            userData[key] = action.payload[key];
          }
        });
        state.userData = userData;
        setStorageJson("user", userData);
      }
    },
    SetPrice: (state, action) => {
      if (action.payload) {
        let userData = state.userData;
        userData.contentAmount = action.payload.contentAmount;
        state.userData = userData;
        setStorageJson("user", userData);
      }
    },
    SignUp: (state, action) => {
      if (action.payload) {
        /*let payload = action.payload;
        if (payload.authToken) {
          state.isLoggedIn = true;
          state.userData = payload.user;
          setStorageJson("user", payload.user);
          setStorage("token", payload.authToken);
        }*/
      }
    },
    LoadWallet: (state, action) => {
      if (action.payload) {
        state.wallet = action.payload;
      }
    },
    LoadCurrentWalletBalance: (state, action) => {
      if (action.payload) {
        state.walletCurrent = action.payload;
      }
    },
    ListWithDrawal: (state, action) => {
      if (action.payload) {
        state.withDrawal = action.payload;
      }
    },
    LoadSubscription: (state, action) => {
      if (action.payload) {
        state.subscription = action.payload;
      }
    },
    LoadPaymentHistory: (state, action) => {
      if (action.payload) {
        state.paymentHistory = action.payload;
      }
    },
    LoadAllCards: (state, action) => {
      if (action.payload) {
        state.cards = action.payload;
      }
    },
    LoadFreeProfiles: (state, action) => {
      if (action.payload) {
        state.freeProfiles = action.payload;
      }
    },
    BuySubscription: (state, action) => {
      if (action.payload) {
        const userData = getStorageJson("user");
        userData.subscription = action.payload;

        setStorageJson("user", userData);

        state.subscription = action.payload;
      }
    },
    CancelSubscription: (state, action) => {
      if (action.payload) {
        const userData = getStorageJson("user");
        userData.subscription = null;
        setStorageJson("user", userData);
        state.subscription = action.payload;
      }
    },
    DeleteCards: (state, action) => {
      if (action.payload) {
        let cards = state.cards;
        let index = -1;
        for (const [i, v] of cards.entries()) {
          if (v.id === action.payload.id) {
            index = i;
          }
        }
        if (index !== -1) {
          cards.splice(index, 1);
        }
        state.cards = cards;
      }
    },
    AddCards: (state, action) => {
      if (action.payload) {
        let cards = state.cards;
        cards.push(action.payload);
        state.cards = cards;
      }
    },
    LoadAllBanks: (state, action) => {
      if (action.payload) {
        state.banks = action.payload;
      }
    },

    LoadBankData : (state, action) => {
      if (action.payload) {
        state.bankData = action.payload;
      }
    },

    DeleteBanks: (state, action) => {
      if (action.payload) {
        let Banks = state.banks;
        let index = -1;
        for (const [i, v] of Banks.entries()) {
          if (v.id === action.payload.id) {
            index = i;
          }
        }
        if (index !== -1) {
          Banks.splice(index, 1);
        }
        state.banks = Banks;
      }
    },
    AddBanks: (state, action) => {
      if (action.payload) {
        let Banks = state.banks;
        Banks.push(action.payload);
        state.banks = Banks;
      }
    },
    LoadAllBillingAddress: (state, action) => {
      if (action.payload) {
        state.billingAddress = action.payload;
      }
    },
    LoadAddressData : (state, action) => {
      if (action.payload) {
        state.addressData = action.payload;
      }
    },
    DeleteBillingAddress: (state, action) => {
      if (action.payload) {
        let billingAddress = state.billingAddress;
        let index = -1;
        for (const [i, v] of billingAddress.entries()) {
          if (v.id === action.payload.id) {
            index = i;
          }
        }
        if (index !== -1) {
          billingAddress.splice(index, 1);
        }
        state.billingAddress = billingAddress;
      }
    },
    AddBillingAddress: (state, action) => {
      if (action.payload) {
        let billingAddress = state.billingAddress;
        billingAddress.push(action.payload);
        state.billingAddress = billingAddress;
      }
    },
    LoadAllAddress: (state, action) => {
      if (action.payload) {
        state.address = action.payload;
      }
    },
    DeleteAddress: (state, action) => {
      if (action.payload) {
        let Address = state.address;
        let index = -1;
        for (const [i, v] of Address.entries()) {
          if (v.id === action.payload.id) {
            index = i;
          }
        }
        if (index !== -1) {
          Address.splice(index, 1);
        }
        state.address = Address;
      }
    },
    AddAddress: (state, action) => {
      if (action.payload) {
        let Address = state.address;
        Address.push(action.payload);
        state.address = Address;
      }
    },
  },
});

export const {
  setUserData,
  removeUserData,
  setUserLogin,
  updateUserData,
  nextSetupPage,
  prevSetupPage,
  resetSetupPage,
  resetUserData,
  ListWithDrawal,
} = userSlice.actions;
export const userData = (state) => state.user;
export default userSlice.reducer;
