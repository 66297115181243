import { Images } from "../../themes";
import { useEffect } from "react";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { getStorageJson } from "../../utils/localStorage";
import { AppRoute } from "../../router/routerConstants";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const RegistrationSuccess = () => {
  const [NavigateTo] = useCustomNavigate();

  const user = getStorageJson("user");

  const userType = user && user.role;

  const continueClicked = () => {
    // if (userType == "Creator") {
    //   NavigateTo(AppRoute.SETUP);
    // } else {
    //   NavigateTo(AppRoute.CONS_Search);
    // }
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="registraion-successful">
          <div className="successful-img">
            <img src={Images.star} alt="star" />
          </div>
          <div className="success-block">
            <h2>Registration Successful!</h2>
            {userType == "Creator" && <h2>Processing Account Verification</h2>}
          </div>
          {userType == "Creator" && (
            <p>Press continue to begin setting up your profile</p>
          )}
          {userType == "Creator" && (
            <figure>
              <img src={Images.dollarIcon} alt="icon" />
            </figure>
          )}

          <span
            className="submit"
            type="button"
            onClick={() => continueClicked()}
          >
            Continue
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationSuccess;
