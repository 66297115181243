// import React, { useEffect ,useState } from "react";
// import { Images } from "../../../themes";
// import { useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { LoadFolder } from "../../../utils/api/actionConstants";
// import { saveData, getData } from "../../../utils/api/apiHelper";
// import { AppRoute } from "../../../router/routerConstants";
// import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
// import Header from "../../../components/Header";
// import Footer from "../../../components/Footer";
// import { getExtension } from "../../../utils/Common";
// import ReactPlayer from "react-player/lazy";
// import CreatorTab from "../../../components/CreatorTab";
// import CreateFolderModal from "../../../components/CreateFolderModal";

// const FolderList = () => {
//   const [showModal, setShowModal] = useState(false);
//   const dispatch = useDispatch();
//   const folder = useSelector((state) => state.folder);
//   const [NavigateTo] = useCustomNavigate();
//   const { state } = useLocation();
//   const setModalVisibility = (status) => {
//     setShowModal(status);
//   };
//   useEffect(() => {
//     dispatch(getData({ action: LoadFolder }));
//   }, []);

//   const moveToFiles = (folder) => {
//     NavigateTo(AppRoute.Folder_Files, false, {
//       type: folder,
//       from: state && state.from,
//     });
//   };

//   return (
//     <div className="wrapper">
//       <Header />
//       <div className="container">
//         <div className="chat-box-wrap content-library my-folder">
//           <h1>My Folder</h1>
//           <span
//               className="crate-new"
//               onClick={() => setModalVisibility(true)}
//             >
//               Create New
//             </span>
//           <p>
//             {/* Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry. */}
//           </p>
//           <div className="content-library-box-list">
//             <div
//               className="content-library-box"
//               onClick={() => moveToFiles("R")}
//             >
//               <>
//                 <figure>
//                   {folder.folderR &&
//                   folder.folderR.length > 0 &&
//                   folder.folderR[0].type === "video" ? (
//                     // <video controls>
//                     //   <source
//                     //     src={folder.folderR[0].source}
//                     //     type={"video/" + getExtension(folder.folderR[0].source)}
//                     //   />
//                     //   Your browser does not support the video tag.
//                     // </video>
//                     <ReactPlayer url={folder.folderR[0].source} />
//                   ) : (
//                     <img
//                       src={
//                         folder.folderR[0]
//                           ? folder.folderR[0].source
//                           : Images.logo
//                       }
//                       alt=""
//                     />
//                   )}
//                 </figure>
//                 <div className="library-inner">
//                   <div className="name-tab">R</div>
//                   <div className="Title">Photo &amp; Video</div>
//                   <span className="next-link" />
//                 </div>
//               </>
//             </div>
//             <div
//               className="content-library-box"
//               onClick={() => moveToFiles("X")}
//             >
//               <>
//                 <figure>
//                   {folder.folderX &&
//                   folder.folderX.length > 0 &&
//                   folder.folderX[0].type === "video" ? (
//                     <video controls>
//                       <source
//                         src={folder.folderX[0].source}
//                         type={"video/" + getExtension(folder.folderX[0].source)}
//                       />
//                       Your browser does not support the video tag.
//                     </video>
//                   ) : (
//                     <img
//                       src={
//                         folder.folderX[0]
//                           ? folder.folderX[0].source
//                           : Images.logo
//                       }
//                       alt=""
//                     />
//                   )}
//                 </figure>
//                 <div className="library-inner">
//                   <div className="name-tab">X</div>
//                   <div className="Title">Photo &amp; Video</div>
//                   <span href="#" className="next-link" />
//                 </div>
//               </>
//             </div>

//             <div
//               className="content-library-box"
//               onClick={() => moveToFiles("Private")}
//             >
//               <>
//                 <figure>
//                   {folder.folderPrivate &&
//                   folder.folderPrivate.length > 0 &&
//                   folder.folderPrivate[0].type === "video" ? (
//                     <video controls>
//                       <source
//                         src={folder.folderPrivate[0].source}
//                         type={
//                           "video/" +
//                           getExtension(folder.folderPrivate[0].source)
//                         }
//                       />
//                       Your browser does not support the video tag.
//                     </video>
//                   ) : (
//                     <img
//                       src={
//                         folder.folderPrivate[0]
//                           ? folder.folderPrivate[0].source
//                           : Images.logo
//                       }
//                       alt=""
//                     />
//                   )}
//                 </figure>
//                 <div className="library-inner">
//                   <div className="name-tab">Private</div>
//                   <div className="Title">Photo</div>
//                   <span href="#" className="next-link" />
//                 </div>
//               </>
//             </div>
//           </div>
//           <CreatorTab activeTab={1} />
//         </div>
//       </div>
//       <Footer />
//       {showModal && <CreateFolderModal modalVisibility={setModalVisibility} />}
//     </div>
//   );
// };

// export default FolderList;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppRoute } from "../../../router/routerConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import ReactPlayer from "react-player";
import { Images } from "../../../themes";
import FolderIc from "../../../assets/images/folder-icon.png";
import CreateFolderModalCreator from "../../../components/CreateFolderModalCreator";
import { getData } from "../../../utils/api/apiHelper";
import { Get_Cons_CreateFolder_Creator } from "../../../utils/api/actionConstants";

const FolderList = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [NavigateTo] = useCustomNavigate();
  const { state } = useLocation();
  const setModalVisibility = (status) => {
    setShowModal(status);
  };
  const [listFolders, setListFolders] = useState([]);
  // const [listFolders1, setListFolders1] = useState([]);
  const folder = useSelector((state) => state.folder?.folders);

  useEffect(() => {
    dispatch(
      getData({
        action: Get_Cons_CreateFolder_Creator,
      })
    );
    // (async () => {
    //   try {
    //     const data = await GET(Get_Cons_CreateFolder_Creator);
    //     console.log(data);
    //     setListFolders(data);
    //   } catch (error) {
    //     console.log(error);
    //     setListFolders([]);
    //   }
    // })();
  }, []);

  useEffect(() => {
    if (folder.length !== 0) setListFolders(folder);
  }, [folder]);

  const moveToFiles = ({ id, name }) => {
    NavigateTo(AppRoute.Folder_Files, false, {
      // type: folder,
      id,
      name,
      from: state && state.from,
    });
  };

  //console.log(listFolders);
  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className="chat-box-wrap content-library my-folder">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
          </div>
          <div className="page-heading-block text-center">
            <h2>My Folder</h2>
          </div>

          <div className="my-ac-form-info">
            <span
              className="crate-new"
              onClick={() => setModalVisibility(true)}
            >
              Create New
            </span>
          </div>
          <div className="content-library-box-list">
            {listFolders && listFolders.length !== 0 ? (
              <>
                {listFolders.map((folder) => {
                  return (
                    <React.Fragment key={folder.id}>
                      <div
                        className="content-library-box"
                        onClick={() =>
                          moveToFiles({ id: folder.id, name: folder.name })
                        }
                      >
                        <figure>
                          {folder.folderR &&
                          folder.folderR.length > 0 &&
                          folder.folderR[0].type === "video" ? (
                            // <video controls>
                            //   <source
                            //     src={folder.folderR[0].source}
                            //     type={"video/" + getExtension(folder.folderR[0].source)}
                            //   />
                            //   Your browser does not support the video tag.
                            // </video>
                            <ReactPlayer url={folder.folderR[0].source} />
                          ) : (
                            <img src={FolderIc} alt="" />
                          )}
                        </figure>
                        <div className="library-inner">
                          {/* <div className="name-tab"></div> */}
                          <div className="Title">{folder.name}</div>
                          <span className="next-link" />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {showModal && (
        <CreateFolderModalCreator modalVisibility={setModalVisibility} />
      )}
    </div>
  );
};

export default FolderList;
