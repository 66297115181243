import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const allMonth = [
  { value: "January", name: "01" },
  { value: "February", name: "02" },
  { value: "March", name: "03" },
  { value: "April", name: "04" },
  { value: "May", name: "05" },
  { value: "June", name: "06" },
  { value: "July", name: "07" },
  { value: "August", name: "08" },
  { value: "September", name: "09" },
  { value: "October", name: "10" },
  { value: "November", name: "11" },
  { value: "December", name: "12" },
];
const allYear = [
  { value: "2024", name: "2024" },
  { value: "2023", name: "2023" },
  { value: "2022", name: "2022" },
];

const MonthYearFilter = (props) => {
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (props.onSubmit) {
      props.onSubmit({
        month,
        year,
      });
    }
  };

  const onClear = () => {
    setMonth("");
    setYear("");

    if (props.onSubmit) {
      props.onSubmit({
        month: "",
        year: "",
      });
    }
  };

  return (
    <div className="data-filter-wrap">
      <div className="data-filter-block">
        <h3>Date Filter</h3>
        <div className="data-filter-form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label for="month">
                <select
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Month
                  </option>
                  {allMonth &&
                    allMonth.length > 0 &&
                    allMonth.map((v, k) => {
                      return (
                        <option key={k} value={v.name}>
                          {v.value}
                        </option>
                      );
                    })}
                </select>
              </label>
            </div>
            <div className="form-group">
              <label for="Year">
                <select
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Year
                  </option>
                  {allYear &&
                    allYear.length > 0 &&
                    allYear.map((v, k) => {
                      return (
                        <option key={k} value={v.name}>
                          {v.value}
                        </option>
                      );
                    })}
                </select>
              </label>
            </div>
            <div className="btn-block">
              <input className="submit" type="submit" value="Apply" />
              <div className="btn-loader"></div>
            </div>
            <div className="btn-block">
              <input className="submit" type="Button" value="Clear" onClick={e => {
                onClear()
              }} />
              <div className="btn-loader"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MonthYearFilter;
