import { useEffect, useRef, useState } from "react";
import { Images } from "../../../themes";
import { AppRoute } from "../../../router/routerConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useSelector, useDispatch } from "react-redux";
import * as CommonFunc from "../../../utils/Common";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import { nextSetupPage, updateUserData } from "../../../redux/userReducer";
import { UpdateConsumer } from "../../../utils/api/actionConstants";
import { UploadFile } from "../../../utils/api/actionConstants";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FullScreenLoader from "../../../components/FullScreenLoader";
import Successfull from "../../Successfull";

const EditProfile = () => {
  const ref = useRef();
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userData);
  const [name, setName] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState();
  const [phone, setPhone] = useState("");
  const [success, setSuccess] = useState(false);
  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    if(value){
      const cleaned = value.replace(/\D/g, '');
      
      // Format according to phone number length
      const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
      
      if (match) {
        const formattedNumber = `${match[1]}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
        return formattedNumber.trim();
      }
    }
    return value;
  };
  const handlePhoneChange = (e) => {
      const { value } = e.target;
      
      // Remove all non-digit characters
      const cleaned = value.replace(/\D/g, '');
    
      // Limit the input to 10 digits
      if (cleaned.length <= 10) {
        const formattedPhone = formatPhoneNumber(cleaned);
        setPhone(formattedPhone);
      }
  };
  useEffect(() => {
    setName(userData.name);
    setPhone(formatPhoneNumber(userData.mobileNumber));
    setImgUrl(userData.profilePic);
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();
    // if (!imgUrl || imgUrl == "") {
    //   CommonFunc.showError("Please select a profile Image");
    //   return;
    // }
    
    if (!name || name == "") {
      CommonFunc.showError("Username is mandatory");
      return;
    }
    const regex = /^[^@]+$/;
    if(!regex.test(name)){
      CommonFunc.showError("Username should not contain the '@' character");
      return;
    }

    if (!phone || phone.trim() === "") {
        CommonFunc.showError("Please enter your phone number");
        return;
    }
    
    // Optional: Validate phone number format (e.g., must be digits only and of a certain length)
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phone.replace(/\D/g, ''))) {
        CommonFunc.showError("Please enter a valid phone number with 10 digits.");
        return;
    }

    const params = {
      name: name,
      mobileNumber:  phone.replace(/\D/g, ''),
      profilePic: "",
    };

    dispatch(updateUserData(params));

    const payload = {
      action: UpdateConsumer,
      data: params,
    };
    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        // NavigateTo(AppRoute.SETUP);
        // dispatch(nextSetupPage());
        setSuccess(true);
      }
    });
  };

  const onImgClick = (event) => {
    ref.current.click();
  };
  const onImageChange = (e) => {
    setLoading(true);

    const [file] = e.target.files;
    const fd = new FormData();
    fd.append("file", file);
    setImg(URL.createObjectURL(file));
    setImgUrl("");

    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      setLoading(false);

      if (r.length > 0) {
        setImgUrl(r[0]);
      }
    });
  };

  return (
    <>
      <FullScreenLoader loading={loading} />
      <div className="wrapper">
        

        {success ? (
          <Successfull type={"profileUpdate"} />
        ) : (
          <>
          <Header showLogout={true} />
          <div className="container">
            <div className=" edit-profile-wrap">
              <div className="heading-block">
                <span className="back-btn">
                  <img
                    src={Images.backImg}
                    alt="back-btn"
                    onClick={() => NavigateTo(-1)}
                  />
                </span>
                <h1>Edit Profile</h1>
              </div>
              <div className="edit-content">
                {/* <div className="upload-img">
                  <figure>
                    <img
                      src={
                        imgUrl != "" ? imgUrl : img ?? Images.default_profile
                      }
                      alt="profile"
                    />
                  </figure>
                  <span className="upload-icon" onClick={onImgClick}>
                    <img src={Images.cameraIcon} alt="" />
                    <input
                      ref={ref}
                      type="file"
                      id="input_file"
                      accept=".jpg,.jpeg,.png"
                      onChange={onImageChange}
                      style={{ display: "none" }}
                    />
                  </span>
                </div> */}
                <p className="info-text">
                  {/* Lorem Ipsum is simply dummy text of the of the printing and
                  typesetting industry. */}
                </p>
                <div className="update-profile">
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email"
                        disabled
                        value={userData.email}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Phone"
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </div>
                    <div className="btn-block">
                      <input
                        type="button"
                        className="submit"
                        value="Update Profile"
                        onClick={handleContinue}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
        <Footer />
      </div>
    </>
  );
};

export default EditProfile;
