import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Images } from "../../../themes";
import axios from "axios";
import { Baseurl } from "../../../utils/api/Api";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useDispatch } from "react-redux";
import { AddContent } from "../../../utils/api/actionConstants";
import { saveData } from "../../../utils/api/apiHelper";

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_CONCURRENT_UPLOADS = 3; // Limit concurrent uploads

const UploadContent = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { state } = useLocation();
  const abortControllers = useRef({}); // Track abort controllers per file index
  const navigate = useNavigate();
  useEffect(() => {
    const handleNetworkChange = () => {
      if (navigator.onLine) {
        console.log("Network reconnected");
        files.forEach((fileData, index) => {
          if (fileData.isUploading && fileData.isPaused) {
            resumeUpload(index); // Resume upload on reconnection
          }
        });
      } else {
        console.log("Network disconnected");
        files.forEach((fileData, index) => {
          if (fileData.isUploading) {
            pauseUpload(index); // Pause all uploads when offline
          }
        });
      }
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, [files]);

  const handleFileChange = (e) => {
    const allowedTypes = ["image/", "video/"];
    const selectedFiles = Array.from(e.target.files);

    const newFiles = selectedFiles
      .filter((file) => allowedTypes.some((type) => file.type.startsWith(type)))
      .map((file) => ({
        file,
        isUploading: false,
        isPaused: false,
        isComplete:false,
        uploadId: null,
        id:null,
        chunkIndex: 0,
        hasError: false,
        retryCount: 0,
        etags: [],
        key: "",
        isCompressionApplied: false,
        compressionLevel: "medium",
        compressionMessage: "",
        downloadFile: "",
        fileUploadByte: 0,
      }));

    if (newFiles.length < selectedFiles.length) {
      toast.error("Some files were skipped because they are not images or videos.");
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
 useEffect(() => {
    files.forEach((fileData, index) => {
      if (!fileData.isUploading && !fileData.uploadId) {
        startUpload(index); // Start upload when a new file is added
      }
    });
  }, [files]);
  const startUpload = async (fileIndex) => {
    const fileData = files[fileIndex];
    if (!fileData.file) return;

    const updatedFiles = [...files];
    updatedFiles[fileIndex].isUploading = true;
    updatedFiles[fileIndex].isPaused = false;
    updatedFiles[fileIndex].hasError = false;

    setFiles(updatedFiles);

    try {
      const { data } = await axios.post(Baseurl + "upload/start", {
        fileName: fileData.file.name,
        contentType: fileData.file.type || "application/octet-stream",
      });

      updatedFiles[fileIndex].uploadId = data.uploadId;
      updatedFiles[fileIndex].chunkIndex = 0;

      setFiles(updatedFiles);
      uploadChunksInParallel(fileIndex);
    } catch (error) {
      console.error("Error starting upload:", error);
      updatedFiles[fileIndex].isUploading = false;
      setFiles(updatedFiles);
    }
  };

  const pauseUpload = (fileIndex) => {
    const updatedFiles = [...files];
    updatedFiles[fileIndex].isPaused = true;
    setFiles(updatedFiles);

    if (abortControllers.current[fileIndex]) {
      abortControllers.current[fileIndex].abort();
      delete abortControllers.current[fileIndex]; 
    }
  };

  const resumeUpload = (fileIndex) => {
    const updatedFiles = [...files];
    updatedFiles[fileIndex].isPaused = false;
    updatedFiles[fileIndex].hasError = false;
    updatedFiles[fileIndex].retryCount = 0;
    setFiles(updatedFiles);

    uploadChunksInParallel(fileIndex);
  };

  const uploadChunk = async (fileIndex, chunkIndex) => {
    const fileData = files[fileIndex];
    const { file, uploadId, etags } = fileData;
    // Skip uploading if ETag for this chunk already exists
    if (etags[chunkIndex]) {
      console.log(`Chunk ${chunkIndex} already uploaded.`);
      return { success: true };
    }
    const start = chunkIndex * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const chunk = file.slice(start, end);
    let chunksize= 0;
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("index", chunkIndex);
    formData.append("uploadId", uploadId);
    formData.append("fileName", fileData.file.name);
    
    const controller = new AbortController();
    abortControllers.current[fileIndex] = controller;
    const totalChunks = Math.ceil(fileData.file.size / CHUNK_SIZE);
    try {
      const { data } = await axios.post(Baseurl + "upload/chunk", formData, {
        signal: controller.signal,
        onUploadProgress: (progressEvent) => {
          chunksize = progressEvent.total
          /*const updatedFiles = [...files];
          updatedFiles[fileIndex].fileUploadByte += progressEvent.loaded;
          setFiles(updatedFiles);*/
        },
      });
      if(data.status){
        const updatedFiles = [...files];
        updatedFiles[fileIndex].fileUploadByte += chunksize;
        if(updatedFiles[fileIndex].fileUploadByte >=  updatedFiles[fileIndex].file.size){
          updatedFiles[fileIndex].fileUploadByte = updatedFiles[fileIndex].fileUploadByte -1000;
        }
        updatedFiles[fileIndex].etags[chunkIndex] = data.ETag;
        setFiles(updatedFiles);
      }

      
      return { success: true };
    } catch (error) {
      console.error(`Error uploading chunk ${chunkIndex}:`, error);
      return { success: false };
    }
  };

  const uploadChunksInParallel = async (fileIndex) => {
    const fileData = files[fileIndex];
    if (!fileData.isUploading || fileData.isPaused || !navigator.onLine) return;

    const totalChunks = Math.ceil(fileData.file.size / CHUNK_SIZE);
    const uploadPromises = [];

    for (
      let i = fileData.chunkIndex;
      i < Math.min(fileData.chunkIndex + MAX_CONCURRENT_UPLOADS, totalChunks);
      i++
    ) {
      uploadPromises.push(uploadChunk(fileIndex, i));
    }

    try {
      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((result) => result.success).length;

      const updatedFiles = [...files];
      updatedFiles[fileIndex].chunkIndex += successfulUploads;

      if (updatedFiles[fileIndex].chunkIndex >= totalChunks) {
        await completeUpload(fileIndex);
      } else {
        uploadChunksInParallel(fileIndex);
      }

      setFiles(updatedFiles);
    } catch (error) {
      console.error("Error during parallel uploads:", error);
    }
  };

  const completeUpload = async (fileIndex) => {
    const fileData = files[fileIndex];
    const { uploadId, etags } = fileData;
    const totalChunks = Math.ceil(fileData.file.size / CHUNK_SIZE);
    for (let i = 0; i < totalChunks; i++) {
      if (!etags[i]) {
        console.log(`Chunk ${i} missing. Uploading...`);
        await uploadChunk(fileIndex, i);
      }
    }

    try {
      if(!fileData.isComplete){
        const { data } = await axios.post(Baseurl + "upload/complete", {
          uploadId,
          fileName: fileData.file.name,
          parts: etags,
        });

        if (data.status) {
          const updatedFiles = [...files];
          updatedFiles[fileIndex].fileUploadByte  =  updatedFiles[fileIndex].file.size
          //updatedFiles[fileIndex].uploadProgress = 100;
          updatedFiles[fileIndex].isUploading = false;
          updatedFiles[fileIndex].isComplete = true;
          updatedFiles[fileIndex].key = data.key;
          updatedFiles[fileIndex].downloadFile = data.url;
          setFiles(updatedFiles);
          addContent(data.url).then((newId) => {
            // Update the file with the returned ID
            const updatedFilesWithId = [...files];
            updatedFilesWithId[fileIndex].id = newId;
          
            setFiles(updatedFilesWithId);
            toast.success("File uploaded & saved successfully in your folder, If you want you can compress your file");
          });
        } else {
          throw new Error("Completion failed");
        }
      }
    } catch (error) {
      console.error("Error completing upload:", error);
    }
  };

  const applyCompression = async (fileIndex) => {
    const fileData = files[fileIndex];
    const { key } = fileData;

    try {
      const updatedFiles = [...files];
      updatedFiles[fileIndex].compressionMessage = `Compression in progress... Level: ${fileData.compressionLevel}`;
      updatedFiles[fileIndex].isCompressionApplied = true;
      setFiles(updatedFiles);
      const { data } = await axios.post(Baseurl + "upload/compression", {
        key,
        compressionLevel: fileData.compressionLevel,
        id: fileData.id
      });
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[fileIndex].compressionMessage = "Compression successful!";
        if (data.status) {
          updatedFiles[fileIndex].downloadFile = data.url;
          //addContent(data.url) // Store the download link if available
        }
        return updatedFiles;
      });
    } catch (error) {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[fileIndex].compressionMessage = "Compression successful!";
        return updatedFiles;
      });
    }
  };
  const addContent = async (url) => {
    const params = {
      creatorFolderId: state?.id,
      files: [url],
    };
    const payload = {
      action: AddContent,
      data: params,
    };
  
    try {
      const response = await dispatch(saveData(payload, true));
      const newId = response[0]?.id;
      return newId; // Return the ID for the file
    } catch (error) {
      console.error("Error saving content:", error);
      toast.error("Failed to save content.");
      return null; // Return null in case of error
    }
  };
  const ShowUploadProgress = () => {
    
    return files.map((fileData, index) => {
      const fileType = fileData.file.type.split('/')[0];
      let progress = ((fileData.fileUploadByte / fileData.file.size) * 100).toFixed(2);
      if (progress > 100) {
        progress = 100;
      }
      return (
      
         <div className="upload-process" key={index}>
         <figure>
         {fileType === 'image' ? (
            <img src={URL.createObjectURL(fileData.file)} alt="" />
          ) : fileType === 'video' ? (
            <video controls>
              <source src={URL.createObjectURL(fileData.file)} type={fileData.file.type} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>Unsupported file type</p>
          )}
         </figure>
         <div className="upoad-detail">
          <div className="upload-rt-wrap">
           <div className="upload-detail-left">
             <h5>{fileData.file.name}</h5>
             <p>{(fileData.file.size / (1024 * 1024)).toFixed(2)} MB</p>
           </div>
           <div className="btn-icon">
            {Math.round(progress) < 100 ? (
              fileData.isUploading ? (
                <>
                 <button  onClick={() => resumeUpload(index)} disabled={!fileData.isUploading || !fileData.isPaused} title="Resume"> <img src={Images.play} alt="play-btn" /></button>
              
                  <button onClick={() => pauseUpload(index)} disabled={fileData.isPaused} title="Pause"> <img src={Images.pause} alt="pause-btn" /></button>
    
                </>
              ) : (
                <>
               
                </>
              )
            ) : null}
            
           </div>
          </div>
           {
            !fileData.isCompressionApplied /* && Math.round(progress) !== 100 */ && ( <>
             <div className="progress">
                <div
                  className="bar"
                  style={{ width: progress + "%" }}
                >
                  <p className="percent">{progress}%</p>
                </div>
              </div>
            </> )
            }
            {fileData.hasError && <div style={{ color: "red" }}>Error occurred. Retry the upload.</div>}
            {/* Display compression options only after the upload is complete */}
           {/* {fileData.compressionMessage && <div style={{ color: "white" }}>{fileData.compressionMessage}</div>}
            {Math.round(progress) === 100 && !fileData.isCompressionApplied && (
          
            <div className="compress-wrap">
            <div className="compressionlevel">
            <input
              type="range"
              min="1"
              max="3"
              step="1"
              style={{ width: "100%" }}
              onChange={(e) => {
                const updatedFiles = [...files];
                const value = e.target.value;
                let compressionLevel = "";
        
                // Map slider values to compression levels
                if (value === "1") compressionLevel = "low";
                if (value === "2") compressionLevel = "medium";
                if (value === "3") compressionLevel = "high";
        
                updatedFiles[index].compressionLevel = compressionLevel;
                setFiles(updatedFiles);
              }}
              disabled={fileData.isCompressionApplied || fileData.isUploading}
              value={
                fileData.compressionLevel === "low"
                  ? 1
                  : fileData.compressionLevel === "medium"
                  ? 2
                  : 3
              }
            />
            <div>
              <span style={{ marginRight: "10px" }}>Low</span>
              <span style={{ marginRight: "10px" }}>Medium</span>
              <span>High</span>
            </div>
          </div>
            <button onClick={() => applyCompression(index)} className="submit">Apply Compression & Save</button>
            </div>
          )} */ }
         </div>
        
       </div>
      )}) ;
  }

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className="chat-box-wrap">
          <div className="heading-block">
            <span onClick={() => navigate(-1)} className="back-btn">
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>Upload Files to {state.name}</h1>
          </div>
          <div className="upload-wrap">
            <div className="upload-file-tab">
              <input
                type="file"
                multiple
                onDrop={(e) => e.preventDefault()}
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png,.mp4"
              />
              <div className="upload-content">
                <figure>
                  <img src={Images.browseImg} alt="" />
                </figure>
                <p>Upload your photos & video</p>
                <span>Browse</span>
              </div>
            </div>

            {files &&
              files.length > 0 &&
              ShowUploadProgress()}
          </div>
          <div>
            <span>
          
            </span>
          </div>
          <div className="uploaded-btn-block">
            <span onClick={() => navigate(-1)} className="submit">
              Back to Folder
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

};
export default UploadContent;