import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import folderReducer from "./folderReducer";
import creatorReducer from "./creatorReducer";
import chatReducer from "./chatReducer";
import staticpages from "./staticpages";
import reportsReducer from "./reportsReducer";

const appReducer = combineReducers({
  user: userReducer,
  folder: folderReducer,
  creator: creatorReducer,
  chat: chatReducer,
  staticPage: staticpages,
  reports: reportsReducer
});

const reducerProxy = (state, action) => {
  if (action.type === "LogOutUser") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: reducerProxy,
});
