import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
const ChatInformation = () => {
  const [NavigateTo] = useCustomNavigate();
  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="benfits-block">
        <div className="my-ac-form-info">
          <div className="form-inner-holder consumer-info-text mt-5">
          <p><b>Welcome to <a href=".">Arouzed.com</a> Chat!</b></p>
          <p>We're excited to facilitate your interactions and ensure a mutually satisfying experience. Please take a moment to read through our expectations for both Creators and Members:</p>
          <p><b>For Creators:</b></p>  
          <ul>
            <li>Provide high-quality custom content that meets the expectations of the Member.</li>
            <li>Communicate openly and promptly with Members to understand their preferences and deliver accordingly.</li>
            <li>Foster a relationship of trust and professionalism with Members.</li>
          </ul>
          <p><b>For Members:</b></p>  
          <ul>
            <li>Clearly communicate your preferences and expectations to the Creator.</li>
            <li>Work collaboratively with the Creator to ensure your custom content meets your desires.</li>
            <li>Respect the time and effort Creators put into crafting custom content.</li>
            <li>Pay the agreed-upon amount promptly upon receiving the custom content.</li>
            <li>Understand that failure to pay for received content will result in account suspension.</li>
          </ul>
            <p>Remember,our platform thrives on trust and collaboration between Creators and Members. Let's work together to create a positive and rewarding experience for all.</p>
            <p>Thank you for being part of <a href=".">Arouzed.com</a>!</p>
            <span className="btn back-btn" onClick={() => NavigateTo(-1)}>
                Return
              </span>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ChatInformation;
