import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Images from "../../../themes/Images";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useReports } from "../../../hooks/useStore";
import { fetchReportData } from "../../../utils/api/apiHelper";
import {
  LoadCreatorReports,
  LoadCreatorReportsLimit,
} from "../../../utils/api/actionConstants";
import { AppRoute } from "../../../router/routerConstants";
import { viewDateDDMMYYY } from "../../../utils/Common";
import DateFilter from "../../../components/DateFilter";
import ShowReportRow from "../../../components/ShowReportRow";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CreatorTab from "../../../components/CreatorTab";

const ReportsHome = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const reports = useReports();
  const [NavigateTo] = useCustomNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filterVisibility = (status) => {
    setShowFilter(status);
  };

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadCreatorReports,
        data: {
          action: "lifeTimeEarning",
        },
      })
    );
    dispatch(
      fetchReportData({
        action: LoadCreatorReports,
        data: {
          action: "monthlyEarning",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadCreatorReportsLimit,
        data: {
          action: "walletEntries",
          limit: 10,
          offset: page,
        },
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    filterVisibility(false);
    dispatch(
      fetchReportData({
        action: LoadCreatorReportsLimit,
        data: {
          action: "betweenWalletEntries",
          startDate,
          endDate,
        },
      })
    );
  }, [dispatch, startDate, endDate]);

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="request-purchased-wrap report-wrap">
          <div className="heading-block">
            <h1>Reports</h1>
          </div>
          <div className="reports-block">
            <div
              className="earning-block"
              onClick={(e) => {
                NavigateTo(AppRoute.Earnings, false, {
                  lifeEarning: reports?.lifetimeEarning,
                });
              }}
            >
              <div className="earning-inner">
                <div className="left-block">
                  <figure>
                    <img src={Images.save_money} />
                  </figure>
                  <h2>${reports?.lifetimeEarning || 0}</h2>
                  <h5>Lifetime Earning</h5>
                </div>
                <div className="right-block"></div>
              </div>
            </div>
            <div
              className="earning-block"
              onClick={(e) => {
                NavigateTo(AppRoute.MonthlyEarningsList, false, {
                  monthlyEarning: reports?.monthlyEarning,
                });
              }}
            >
              <div className="earning-inner">
                <div className="left-block">
                  <figure>
                    <img src={Images.monthly_icon} />
                  </figure>
                  <h2>${reports?.monthlyEarning}</h2>
                  <h5>Monthly Earning</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-block">
            <div className="filter-inner">
              <h3>Requests Purchased</h3>
              <span
                className="fiter-icon"
                onClick={() => filterVisibility(true)}
              >
                <img src={Images.filter_icon} alt="filter icon" />
              </span>
            </div>
          </div>
          <div className="view-request-items">
            <ShowReportRow rows={reports.walletEntries} />
            {
              (reports.walletEntries.length ==10)?<><span
              href=""
              onClick={(e) => {
                setPage(page + 1);
              }}
              className="view-more-btn"
            >
              View more
            </span></> : null

            }
            
            {showFilter && (
              <DateFilter
                filtersSubmit={(e) => {
                  setStartDate(e.startDate);
                  setEndDate(e.endDate);
                }}
                modalVisibility={filterVisibility}
              />
            )}
          </div>
          <CreatorTab activeTab={2} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReportsHome;
