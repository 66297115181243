import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadPaymentHistory } from "../../utils/api/actionConstants";
import { usePaymentHistory } from "../../hooks/useStore";
import { getData } from "../../utils/api/apiHelper";
import { Images } from "../../themes";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { generateRandomString, getDateShow } from "../../utils/Common";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const PaymentHistory = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  let paymentHistory = usePaymentHistory();

  useEffect(() => {
    dispatch(
      getData({
        action: LoadPaymentHistory,
        data: {
          offset: 0,
          limit: 100,
        },
      })
    );
  }, []);

  const rowItem = () => {
    return paymentHistory.map((r, v) => {
      let nextRenewal = getDateShow(r.createdAt);
      return (
        <div className="view-request-list" key={generateRandomString()}>
          <div className="view-request-img">
            <figure>
              <img src={Images.ic_add_money} alt="add money" />{" "}
            </figure>
          </div>
          <div className="view-request-detail">
            <div className="View-request-inner">
              <div className="wallet-top-block">
                <h3>Premium Plan Subscribe</h3>
                <div className="price">${r.amount}</div>
              </div>
              <div className="wallet-bottom-block">
                <p>
                  {nextRenewal?.day} {nextRenewal?.month}, {nextRenewal?.year}
                </p>
                <p className="successful">Payment Successful</p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="request-purchased-wrap payment-history">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Payment History</h1>
            </div>
            <div className="view-request-items wallet-items">{rowItem()}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentHistory;
