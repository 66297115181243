import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import { useLocation } from "react-router-dom";
import Successfull from "../../Successfull";

import Footer from "../../../components/Footer";
import Setup1 from "./Setup1";
import AgeVerification from "./AgeVerification";
import Setup2 from "./Setup2";
import Setup3 from "./Setup3";
import Setup4 from "./Setup4";
import Setup4New from "./Setup4New";
const Setup = () => {
  const { state } = useLocation();
  const setupPage = useSelector((state) => state.user.setupPage);
  const from = state && state.from;

  const GoToPage = () => {
    if (setupPage == 0) {
      return <AgeVerification from={from} />;  
    } else if (setupPage == 1) {
      return <Setup2 from={from} />;
    } else if (setupPage == 2) {
      return <Setup1 from={from} />;
    } else if (setupPage == 3) {
      return <Setup3 from={from} />;
    } else if (setupPage == 4) {
      return <Setup4New />;
    } else if (setupPage == 5) {
      if (from && from === "edit") {
        return <Successfull type={"profileUpdate"} />;
      }
      else {
        return <Successfull type={"profileCreated"} />;
      }
    } else {
      return <Setup2 />;
    }
  };
  return (
    <div className="wrapper">
      <Header />
      {GoToPage()}
      <Footer />
    </div>
  );
};

export default Setup;
