import { useEffect, useState } from "react";
import { Images } from "../../../themes";
import { useDispatch } from "react-redux";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useCards } from "../../../hooks/useStore";
import { AppRoute } from "../../../router/routerConstants";
import {
  LoadAllCards,
  MakePayment,
  // SetDefaultCard,
   DeleteCards,
} from "../../../utils/api/actionConstants";
import {
  getData,
  saveData,
  deleteData,
  showErrorInUI,
} from "../../../utils/api/apiHelper";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Successfull from "../../Successfull";
import FullScreenLoader from "../../../components/FullScreenLoader";
const AddMoneyWallet = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amountToAdd, setAmountToAdd] = useState(0);
  
  const [seletedCard, setSelectedCard] = useState("");
  const [paymentCard, setPaymentCard] = useState("");
  
  const { state } = useLocation();
  let cards = useCards();
//console.log(state);
  useEffect(() => {
    if (state?.amount) {
      setAmountToAdd(0);
    }
  }, [state]);

  useEffect(() => {
    if (cards.length && cards[0] && cards[0]?.id){
      setPaymentCard(cards[0].id);
    }
  }, [cards]);

  const addMoneyBtnClkd = () => {
    if(cards.length<=0){
      showErrorInUI("Please add the card first");
      return;
    }
    if (!paymentCard) {
      showErrorInUI("Please Select the Card");
      return;
    }
    if (amountToAdd >= 10) {
      setLoading(true);
      /*let card = cards.find((element) => element.isDefault === true);
      if (!card) card = cards[0]; */

      dispatch(
        saveData({
          action: MakePayment,
          data: {
            amount: amountToAdd,
            card: paymentCard,
          },
        })
      ).then((r) => {
        setLoading(false);
        if (r === 1) {
          setSuccess(true);
        }
      });
    }else {
      showErrorInUI("Minimum amount to add is $10");
    }
  };
  const getCards = () => {
    dispatch(
      getData({
        action: LoadAllCards,
        data: { offset: 0, limit: 20 },
      })
    );
  };
  useEffect(() => {
    getCards();
  }, []);

 

  // const setDefault = (id) => {
  //   dispatch(
  //     saveData({
  //       action: SetDefaultCard,
  //       data: { id: id },
  //     })
  //   ).then((r) => {
  //     getCards();
  //   });
  // };
  const deleteCard = () => {
    setLoading(true);
    dispatch(
      deleteData({
        action: DeleteCards,
        data: { id: seletedCard },
      })
    ).then((r) => {
      setLoading(false);
      setSelectedCard("");
    });
  };
  return (
    <>
   <FullScreenLoader loading={loading} />
   <div className="wrapper">
      <Header showLogout={true} />

      {success ? (
        <Successfull
          type={"addMoney"}
          amount={<strong>${amountToAdd}</strong>}
          profileid={  state?.id }
          url={  state?.url }
        />
      ) : (
        <div className="container">
          <div className="add-money-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Add Money to Wallet</h1>
            </div>
            <div className="my-ac-form-info">
                <div className="form-inner-holder consumer-info-text">
                  <p className="m-0">The minimum deposit amount is $10.00 - email <a href="mailto:support@arouzed.com">support@arouzed.com</a> if you experience any issues.</p>
                </div>
            </div>
            <div className="add-money-block">
              <div className="add-money-filed">
                <input
                  type="number"
                  className="add-money"
                  placeholder="Add Money"
                  //placeholder={amountToAdd}
                  min={10}
                  onChange={(e) => setAmountToAdd(e.target.value)}
                />
              </div>
              <div
                className="add-money-heading"
                 onClick={() => NavigateTo(AppRoute.Cons_AddCard,false, { id: state?.id })}
              >
                <h3>Select Card</h3>
                <span className="add-money-btn">Add New Card</span> 
              </div>
              <div className="manage-card-block multiple-card">
                {cards &&
                  cards.length > 0 &&
                  cards.map((v, k) => {
                    return (
                      <div className="add-money-account" key={k}>
                        <div className="add-money-account-top">
                          <i>
                            <img src={Images.ic_add_card} alt="card_icon" />
                          </i>
                          <h5>{v.name}</h5>
                          <i
                        className="trash-icon"
                        onClick={() => {
                          //setCardName(v.name);
                          setSelectedCard(v.id);
                        }}
                      >
                        <img src={Images.trash} alt="trash" />
                      </i>
                        </div>
                        <div className="card-detail">
                          <label>Card Number</label>
                          <div className="card-number-block">
                            <p className="card-number">
                              <span className="hide"> ● ● ● ●</span>
                              <span className="hide"> ● ● ● ●</span>
                              <span className="hide"> ● ● ● ●</span>
                              <span className="show">{v.number}</span>
                            </p>
                            <div className="select-cotent">
                              <input
                                onChange={() => setPaymentCard(v.id)}
                                type="checkbox"
                                name="select"
                                checked={(paymentCard===v.id)?true:false}
                              />

                              <label />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
           
              <div className="btn-block">
                {!loading && (
                  // <span>Loading</span>
                
                  <span className="submit" onClick={addMoneyBtnClkd}>
                    Proceed to add ${amountToAdd}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      {seletedCard && seletedCard.length > 0 && (
        <div className="modal-wrapper">
          <div className="model-inner delete-model">
            <div className="model-box">
              <h3>Delete Card</h3>
              <figure className="delete-icon">
                <img src={Images.ic_delete_card} alt="delete" />
              </figure>
              <div className="delet-content">
                Are you sure? you want to delete your card
              </div>
              <div className="btn-block">
                <span className="reset-btn" onClick={() => setSelectedCard("")}>
                  Not, now
                </span>
                <div className="btn-inner" onClick={() => deleteCard()}>
                  <span className="submit">Yes, sure</span>
                  <div className="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
 
  );
};

export default AddMoneyWallet;
