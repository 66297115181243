import { useEffect, useState } from "react";
import { Images } from "../../../themes";
import { useSelector, useDispatch } from "react-redux";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import * as CommonFunc from "../../../utils/Common";
import {
  nextSetupPage,
  prevSetupPage,
  updateUserData,
} from "../../../redux/userReducer";
import { UploadFile } from "../../../utils/api/actionConstants";
import {
  UpdateUserpics,
  UpdateCreator,
  UpdateIsBlur,
} from "../../../utils/api/actionConstants";
import FullScreenLoader from "../../../components/FullScreenLoader";

const Setup3 = ({ from }) => {
  // const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [img4, setImg4] = useState();
  const [loading, setLoading] = useState(false);

  const [imgurlArray, setImgUrlArray] = useState(["", "", "", ""]);

  let userPics = useSelector((state) => state.user.userData?.allProfilePics);

  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    //Remove after image approval from admin
    if (userPics && userPics.length > 4) {
      userPics = userPics.slice(-4).map((r) => r);
    }

    if (userPics && userPics.length == 4) {
      if (typeof userPics[0] == "object") {
        userPics = userPics.map((r) => r.source);
      }
      setImgUrlArray(userPics);
      setImg1(userPics[0]);
      setImg2(userPics[1]);
      setImg3(userPics[2]);
      setImg4(userPics[3]);
    }
  }, []);

  const onImageChange1 = (e, imgNum) => {
    const [file] = e.target.files;

    if (imgNum === 1) {
      setImg1(URL.createObjectURL(file));
    } else if (imgNum === 2) {
      setImg2(URL.createObjectURL(file));
    } else if (imgNum === 3) {
      setImg3(URL.createObjectURL(file));
    } else if (imgNum === 4) {
      setImg4(URL.createObjectURL(file));
    }
    setLoading(true);
    imageUpload(file, imgNum);
  };

  const imageUpload = (file, imgNum) => {
    const fd = new FormData();
    fd.append("file", file);
    let imgUrls = imgurlArray;
    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      if (r.length > 0) {
        if (imgNum === 1) {
          // setImg1(r[0]);
          imgUrls[0] = r[0];
        } else if (imgNum === 2) {
          // setImg2(r[0]);
          imgUrls[1] = r[0];
        } else if (imgNum === 3) {
          // setImg3(r[0]);
          imgUrls[2] = r[0];
        } else if (imgNum === 4) {
          // setImg4(r[0]);
          imgUrls[3] = r[0];
        }
        setLoading(false);
        setImgUrlArray(imgUrls);
      }
    });
  };

  const DefaultView = ({ imgNum }) => {
    return (
      <div className="inner-uploaded">
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={(e) => onImageChange1(e, imgNum)}
        />
        <img className="upload-icon" src={Images.uploadImgIcon} />
      </div>
    );
  };

  const ImageView = ({ imgNum }) => {
    let image = "";
    if (imgNum === 1) {
      image = img1;
    } else if (imgNum === 2) {
      image = img2;
    } else if (imgNum === 3) {
      image = img3;
    } else if (imgNum === 4) {
      image = img4;
    }

    return (
      <div className="inner-uploaded">
        <figure>
          <img src={image} />
          <span className="upload-link">
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={(e) => onImageChange1(e, imgNum)}
            />
            <img src={Images.cameraIcon2} alt="" />
          </span>
        </figure>
      </div>
    );
  };

  const handleContinue = (e) => {
    e.preventDefault();

    const imgNotSelected = imgurlArray.filter((r) => r === "");
    if (imgNotSelected.length > 0) {
      CommonFunc.showError("Please select all Images");
      return;
    }

    const params = {
      allProfilePics: imgurlArray,
    };
    dispatch(updateUserData(params));

    const payload = {
      action: UpdateUserpics,
      data: { profiles: imgurlArray },
    };
    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        dispatch(nextSetupPage());
      }
    });
  };

  const onBlurChange = (value) => {
    dispatch(
      saveData({
        action: UpdateIsBlur,
        data: { type: "image" },
      })
    );
  };

  return (
    <div>
      <FullScreenLoader loading={loading} />
      <div className="container">
        <div className="setup-profile-wrap">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => dispatch(prevSetupPage())}
              />
            </span>
            <h1>General Profile Pics</h1>
          </div>
          <div className="step-bar">
            <ul className="steps">
              <li className="step is-complete is-active" data-step={1} />
              <li className="step is-complete" data-step={2} />
              <li className="step is-complete" data-step={3} />
              <li className="step is-active" data-step={4} />
              {from && from === "edit" ? (
                <li className="step " data-step={5} />
              ) : (
                <li className="step " data-step={5} />
              )}
            </ul>
          </div>
          <div className="form-block">
            <form>
              <div className="upload-text">
                <p>
                  <img src={Images.tellMeMore} alt="" />
                  Upload 4 flirty fun pictures, but don't show too much.
                </p>
              </div>
              <div className="blur-image">
                <label for="blutcheck">Blur General Profile Pics</label>
                <input
                  id="blutcheck"
                  type="checkbox"
                  onChange={(e) => onBlurChange(e.target.checked)}
                  checked={userData.isBlurImages}
                ></input>
              </div>

              {/* <div>
                <span className="">Blur pics</span>
                <input
                  type="checkbox"
                  onChange={(e) => onBlurChange(e.target.checked)}
                  checked={userData.isBlurImages}
                />
              </div> */}
              <div className="uploded-imges">
                <div className="row">
                  <div className="col-6">
                    {img1 ? (
                      <ImageView imgNum={1} />
                    ) : (
                      <DefaultView imgNum={1} />
                    )}
                  </div>
                  <div className="col-6">
                    {img2 ? (
                      <ImageView imgNum={2} />
                    ) : (
                      <DefaultView imgNum={2} />
                    )}
                  </div>
                  <div className="col-6">
                    {img3 ? (
                      <ImageView imgNum={3} />
                    ) : (
                      <DefaultView imgNum={3} />
                    )}
                  </div>
                  <div className="col-6">
                    {img4 ? (
                      <ImageView imgNum={4} />
                    ) : (
                      <DefaultView imgNum={4} />
                    )}
                  </div>
                </div>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleContinue}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup3;
