import { Images } from "../../../themes";
import { useEffect, useState } from "react";
import { AppRoute } from "../../../router/routerConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import DateFilter from "../../../components/DateFilter";

const PurchasedRequests = () => {
  const [NavigateTo] = useCustomNavigate();

  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState(["", "", "", "", ""]);

  const filterVisibility = (status) => {
    setShowFilter(status);
  };

  const rowItem = () => {
    return data.map((row) => {
      return (
        <div className="view-request-list">
          <div className="view-request-img">
            <figure>
              <img src={Images.profile_Img} alt="back-btn" />
            </figure>
          </div>
          <div className="view-request-detail">
            <div className="view-request-inner">
              <h3>Abram Fleischer</h3>
              <p>
                15/04/2022 <i>●</i> <span>5 photos</span>
              </p>
            </div>
            <div className="price">$25</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="request-purchased-wrap">
            <div className="heading-block">
              <span className="back-btn">
                <img
                  src={Images.backImg}
                  alt="back-btn"
                  onClick={() => NavigateTo(-1)}
                />
              </span>
              <h1>View request purchased</h1>{" "}
              <span className="filter-icon">
                <img
                  src={Images.filter_icon}
                  alt="filter icon"
                  onClick={() => filterVisibility(true)}
                />
              </span>
            </div>
            <div className="view-request-items">{rowItem()}</div>
            {showFilter && <DateFilter modalVisibility={filterVisibility} />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PurchasedRequests;
