import Mustache from "mustache";
import { toast } from "react-toastify";
import { getStorageJson } from "./localStorage";
import { useSelector } from "react-redux";

export const replaceValues = (template, data) => {
  return Mustache.render(template, data);
};

function getStorage() {
  return localStorage;
}

export function readToken() {
  return getStorage().getItem("token");
}

export function readUserData() {
  return getStorage().getItem("user");
}

export const showError = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export const showSuccess = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export const showErrorFileType = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export const showErrorFileSize = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export function read(key) {
  let data = JSON.parse(getStorage().getItem("data"));
  if (data) {
    return data[key];
  } else {
    return undefined;
  }
}

export const IsSubscribed = () => {
  const user = useSelector((state) => state.user);
  const valid = user?.subscription?.validTill;

  if (valid !== undefined && valid !== null) {
    const date1 = new Date(valid);
    const date2 = new Date();
    const dateDiff = date1 - date2;
    return dateDiff > 0;
  }
  return false;
};

export const IsUserSubscribed = (user) => {
  const valid = user?.subscription?.validTill;

  if (valid) {
    const date1 = new Date(valid);
    const date2 = new Date();
    const dateDiff = date1 - date2;
    return dateDiff > 0;
  }
  return false;
};

export const IsSubscriptionValid = (validTill) => {
  const valid = validTill;
  if (valid) {
    const date1 = new Date(valid);
    const date2 = new Date();
    const dateDiff = date1 - date2;
    return dateDiff > 0;
  }
  return false;
};

export function write(data) {
  getStorage().setItem("data", JSON.stringify(data));
}

export function remove(key) {
  getStorage().removeItem(key);
}

export function clearAll() {
  getStorage().clear();
}

export function generateRandomString(length = 15) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

/**
 * Card expiry validation
 */

export function validateExpiry(expiry) {
  expiry = expiry.split("/");
  let month = expiry[0];
  let year = expiry[1];
  let response = true;

  let currentYear = new Date().getFullYear().toString().slice(-2);
  let currentMonth = (new Date().getMonth() + 1).toString();

  if (currentMonth.length === 1) {
    currentMonth = "0" + currentMonth;
  }

  if (
    month > 12 ||
    month < 1 ||
    year < currentYear ||
    (year === currentYear && month < currentMonth)
  ) {
    response = false;
  }
  return response;
}

/**
 *
 * @param {data: Object/Array, type: String}
 *
 * Parsing and unparsing json/js object/array
 */
export function stringify(data, type = "object") {
  try {
    return JSON.stringify(data);
  } catch (error) {
    return "object" === type ? {} : [];
  }
}

export function parse(data, type = "object") {
  try {
    return JSON.parse(data);
  } catch (error) {
    return "object" === type ? {} : [];
  }
}

export function Sort(data, key, order) {
  if (order == "ASC") {
    return data.sort((a, b) =>
      (a[key] && typeof a[key] == "String" && a[key].toLowerCase()) >
      (b[key] && typeof a[key] == "String" && b[key].toLowerCase())
        ? 1
        : -1
    );
  } else {
    return data.sort((a, b) =>
      (a[key] && typeof a[key] == "String" && a[key].toLowerCase()) <
      (b[key] && typeof a[key] == "String" && b[key].toLowerCase())
        ? 1
        : -1
    );
  }
}

export function Search(data, txt) {
  if (data.length > 0) {
    let firstitem = data[0];
    let allkeys = Object.keys(firstitem);
    const filtered = data.filter((item) => {
      for (const k of allkeys) {
        if (item[k]) {
          if (item[k].toString().toLowerCase().includes(txt.toLowerCase())) {
            return true;
          }
        }
      }
    });
    return filtered;
  } else {
    return data;
  }
}

export function formatDate(date) {
  if (!date || date == "") {
    return "";
  }

  try {
    // var d = new Date(date),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // // if (month.length < 2) month = "0" + month;
    // // if (day.length < 2) day = "0" + day;
    // return year + "-" + month + "-" + day;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function getExtension(str) {
  let index = str.indexOf(".");
  if (index !== -1) {
    str.substr(index + 1, str.length);
  }

  return "";
}

export function getAmPm(date) {
  try {
    let d = new Date(date),
      hour = d.getHours();

    if (hour >= 12) {
      return "PM";
    } else {
      return "AM";
    }
  } catch (error) {
    return "";
  }
}

export function getDateShow(date) {
  try {
    let d = new Date(date),
      year = d.getFullYear(),
      day = d.getDate(),
      month = d.getMonth();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return {
      year,
      month: monthNames[month],
      day,
    };
  } catch (error) {
    return "";
  }
}

export function getDate(date) {
  try {
    let d = new Date(date);
    return d.toLocaleDateString("en-US");
  } catch (error) {
    return "";
  }
}

export function getDateFull(date) {
  try {
    const event = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return event.toLocaleDateString(undefined, options);
  } catch (error) {
    return "";
  }
}

export function getHourMinute(date) {
  try {
    let d = new Date(date),
      hour = d.getHours(),
      minutes = d.getMinutes();

    if (hour >= 12) {
      hour -= 12;
    }
    return (
      String(hour).padStart(2, "0") + ":" + String(minutes).padStart(2, "0")
    );
  } catch (error) {
    return "";
  }
}

export function getCurrentDateTime() {
  let current = new Date();
  return current.toISOString();
}

/**
 * get date in dd/mm/yyyy
 */

export function viewDateDDMMYYY(date) {
  try {
    let d = new Date(date),
      year = d.getFullYear(),
      month = d.getMonth(),
      day = d.getDate();

    return (
      String(day).padStart(2, "0") +
      "/" +
      String(month + 1).padStart(2, "0") +
      "/" +
      year
    );
  } catch (error) {
    return "";
  }
}
