import React, { useEffect, useState,useRef } from "react";
import { Images } from "../../../themes";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Cons_LoadFolderFiles } from "../../../utils/api/actionConstants";
import { getData } from "../../../utils/api/apiHelper";
import { AppRoute } from "../../../router/routerConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Lightbox from "../../../components/Lightbox";
import ReactPlayer from "react-player";

const FolderFiles = () => {
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const folder = useSelector((state) => state.folder);
  const { state } = useLocation();
  useEffect(() => {
    dispatch(
      getData({
        action: Cons_LoadFolderFiles,
        data: { id: state?.id },
      })
    );
  }, []);
  

  const folderFiles = folder?.co_files;
  const media= folderFiles.map((v, k) => {
    if(v.type ==='video'){
      return  { url: v.source, type: v.type };
    }
    return  { url: v.source, type:"photo" };
      
    
  })
  const openLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  



  const ShowList = () => {
    return (
      folderFiles.length > 0 &&
      folderFiles.map((v, k) => {
        if (v.type === "video") {
          return (
            <div className="col-6" key={k}>
              <div className="inner-uploaded files-to-edit-del">
                <div className="image-box">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={v.source}
                    controls
                    config={{
                      file: {
                        attributes: {
                          controlsList: " nodownload  ",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="col-6" key={k}>
              <div className="inner-uploaded files-to-edit-del">
                <div className="image-box">
                  <figure>
                    <img src={v.source} alt="" onClick={() => openLightbox(k)} />
                  </figure>
                </div>
              </div>
            </div>
          );
        }
      })
    );
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className=" setup-profile-wrap photo-video-wrap full-pages">
          <div className="heading-block">
            <span onClick={() => NavigateTo(-1)} className="back-btn">
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>Photo & Video</h1>
          </div>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p> */}
          <div className="gallery-img">
            <div className="uploded-imges">
              <div className="row">{folderFiles && ShowList()}
              {lightboxOpen && (
                <Lightbox media={media} startIndex={currentIndex} onClose={closeLightbox} />
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FolderFiles;
