import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { useEffect, useState } from "react";
import { Images } from "../../../themes";
import { useSelector, useDispatch } from "react-redux";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import * as CommonFunc from "../../../utils/Common";
import { updateUserData } from "../../../redux/userReducer";
import { FetchFolderFiles } from "../../../utils/api/actionConstants";
import { UploadFile } from "../../../utils/api/actionConstants";
import { UpdateCreator } from "../../../utils/api/actionConstants";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { AddContent, SetPrice } from "../../../utils/api/actionConstants";
import { getData } from "../../../utils/api/apiHelper";
import backBtn from '../../../assets/images/back-btn.png';
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useLocation } from "react-router-dom";
import { AppRoute } from "../../../router/routerConstants";

/*const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB max file size
const ALLOWED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png"];*/

const PrivateIntroductoryFolder = () => {
    const dispatch = useDispatch();
    const [NavigateTo] = useCustomNavigate();
    const [img1, setImg1] = useState();
    const [img2, setImg2] = useState();
    const [img3, setImg3] = useState();
    const [img4, setImg4] = useState();
    const [img5, setImg5] = useState();
    const [img6, setImg6] = useState();
    const [img7, setImg7] = useState();
    const [img8, setImg8] = useState();
    const [img9, setImg9] = useState();
    const [img10, setImg10] = useState();
    const [price, setPrice] = useState();
    const [loading, setLoading] = useState(false);
    const [imgurlArray, setImgUrlArray] = useState(["", "", "", "", "", "", "", "", "", ""]);
  
    let privatePic = useSelector((state) => state.folder?.folderFiles);
    let currentUser = useSelector((state) => state.user.userData);

    const { state } = useLocation();
  
    useEffect(() => {
      dispatch(
        getData({
          action: FetchFolderFiles,
          data: { folderId: state?.id, offset: 0, limit: 10 },
        })
      );
      setPrice(currentUser.contentAmount);
    }, []);
  
    useEffect(() => {
      //Remove after image approval from admin
      if (privatePic && privatePic.length > 10) {
        privatePic = privatePic.slice(-10).map((r) => r);
      }
  
      if (privatePic && privatePic.length == 10) {
        if (typeof privatePic[0] == "object") {
          privatePic = privatePic.map((r) => r.source);
        }
        setImgUrlArray(privatePic);
        setImg1(privatePic[0]);
        setImg2(privatePic[1]);
        setImg3(privatePic[2]);
        setImg4(privatePic[3]);
        setImg5(privatePic[4]);
        setImg6(privatePic[5]);
        setImg7(privatePic[6]);
        setImg8(privatePic[7]);
        setImg9(privatePic[8]);
        setImg10(privatePic[9]);
      }
    }, [privatePic]);
  
    const onImageChange1 = (e, imgNum) => {
      const [file] = e.target.files;

      /*if (file) {
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
          CommonFunc.showErrorFileType("Invalid file type. Please upload a JPEG or PNG image.");
          setLoading(false);
          return;
        }
  
        if (file.size > MAX_FILE_SIZE) {
          CommonFunc.showErrorFileSize("File is too large. Please upload an image under 2MB.");
          setLoading(false);
          return;
        }
      }*/
  
      if (imgNum === 1) {
        setImg1(URL.createObjectURL(file));
      } else if (imgNum === 2) {
        setImg2(URL.createObjectURL(file));
      } else if (imgNum === 3) {
        setImg3(URL.createObjectURL(file));
      } else if (imgNum === 4) {
        setImg4(URL.createObjectURL(file));
      } else if (imgNum === 5) {
        setImg5(URL.createObjectURL(file));
      } else if (imgNum === 6) {
        setImg6(URL.createObjectURL(file));
      } else if (imgNum === 7) {
        setImg7(URL.createObjectURL(file));
      } else if (imgNum === 8) {
        setImg8(URL.createObjectURL(file));
      } else if (imgNum === 9) {
        setImg9(URL.createObjectURL(file));
      } else if (imgNum === 10) {
        setImg10(URL.createObjectURL(file));
      }
      setLoading(true);
      imageUpload(file, imgNum);
    };
  
    const imageUpload = (file, imgNum) => {
      const fd = new FormData();
      fd.append("file", file);
      let imgUrls = imgurlArray;
      dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
        if (r.length > 0) {
          if (imgNum === 1) {
            imgUrls[0] = r[0];
          } else if (imgNum === 2) {
            imgUrls[1] = r[0];
          } else if (imgNum === 3) {
            imgUrls[2] = r[0];
          } else if (imgNum === 4) {
            imgUrls[3] = r[0];
          } else if (imgNum === 5) {
            imgUrls[4] = r[0];
          } else if (imgNum === 6) {
            imgUrls[5] = r[0];
          } else if (imgNum === 7) {
            imgUrls[6] = r[0];
          } else if (imgNum === 8) {
            imgUrls[7] = r[0];
          } else if (imgNum === 9) {
            imgUrls[8] = r[0];
          } else if (imgNum === 10) {
            imgUrls[9] = r[0];
          }
          setLoading(false);
          setImgUrlArray(imgUrls);
        }
      });
    };
  
    const DefaultView = ({ imgNum }) => {
      return (
        <div className="inner-uploaded">
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={(e) => onImageChange1(e, imgNum)}
          />
          <img className="upload-icon" src={Images.uploadImgIcon} />
        </div>
      );
    };
  
    const ImageView = ({ imgNum }) => {
      let image = "";
      if (imgNum === 1) {
        image = img1;
      } else if (imgNum === 2) {
        image = img2;
      } else if (imgNum === 3) {
        image = img3;
      } else if (imgNum === 4) {
        image = img4;
      } else if (imgNum === 5) {
        image = img5;
      } else if (imgNum === 6) {
        image = img6;
      } else if (imgNum === 7) {
        image = img7;
      } else if (imgNum === 8) {
        image = img8;
      } else if (imgNum === 9) {
        image = img9;
      } else if (imgNum === 10) {
        image = img10;
      }
  
      return (
        <div className="inner-uploaded">
          <figure>
            <img src={image} />
            <span className="upload-link">
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => onImageChange1(e, imgNum)}
              />
              <img src={Images.cameraIcon2} alt="" />
            </span>
          </figure>
        </div>
      );
    };
  
    const handleContinue = (e) => {
      e.preventDefault();
      const imgNotSelected = imgurlArray.filter((r) => r === "");
  
      if (imgNotSelected.length > 0) {
        CommonFunc.showError("Please select all Images");
        return;
      }
  
      if (!price || !(Number(price) > 0)) {
        CommonFunc.showError("Please enter price for your private images");
        return;
      }
  
      const params = {
        creatorFolderId: state?.id,
        files: imgurlArray,
      };
      const payload = {
        action: AddContent,
        data: params,
      };
  
      const pricePayload = {
        action: SetPrice,
        data: { price: price },
      };
      const pData = {
        isPrivateFolderCompleted: true
      }
      dispatch(updateUserData(pData));
      dispatch(saveData(pricePayload)).then((r) => {});
      dispatch(saveData(payload)).then((r) => {
        if (r === 1) {
          const payload1 = {
            action: UpdateCreator,
            data: { isPrivateFolderCompleted: true },
          };
  
          dispatch(saveData(payload1)).then((r) => {
            if (r === 1) {
              CommonFunc.showSuccess("Data saved successfully");
              NavigateTo(AppRoute.Profile);
            }
          });
        }
      });
    }
    return (
        <>
        <div className="wrapper">
        <Header showLogout={true} />
            <FullScreenLoader loading={loading} />
            <div className="container">
                <div className="setup-profile-wrap">
                <div className="heading-block">
                    <span className="back-btn" onClick={() => NavigateTo(-1)}><img src={backBtn} alt="back-btn"/></span>
                </div>
                <div className="page-heading-block text-center">
                    <h2>Private Introductory Images</h2>
                </div>
                <div className="my-ac-form-info">
                  <div className="form-inner-holder">
                      <p>Your Private Introductory Folder is what allows our Members to privately message you for custom content.  Unlike your Profile Images, adult content is allowed and recommended here. Once you're happy with your ten images, set the price you want in order for members to view this folder and begin messaging you directly.</p>
                  </div>
                </div>
                <div className="my-ac-form-info">
                  <div className="form-inner-holder">
                      <p><b>Create an enticing profile—it's what men see when deciding to spend money on you. Use sexy photos, good lighting, swimwear, and lingerie. More effort means better results and more money.</b></p>
                      <p><b>Tip: Set your Private Folder pricing between $5 and $20 for higher click rates.</b></p>
                      <p><b>For any assistance, email <a href="mailto:support@arouzed.com">support@arouzed.com</a>. We're here to help.</b></p>
                  </div>
                </div>
                <div className="form-block">
                    <form>
                    <div className="uploded-imges">
                        <div className="row">
                        <div className="col-6">
                            {img1 ? (
                            <ImageView imgNum={1} />
                            ) : (
                            <DefaultView imgNum={1} />
                            )}
                        </div>
                        <div className="col-6">
                            {img2 ? (
                            <ImageView imgNum={2} />
                            ) : (
                            <DefaultView imgNum={2} />
                            )}
                        </div>
                        <div className="col-6">
                            {img3 ? (
                            <ImageView imgNum={3} />
                            ) : (
                            <DefaultView imgNum={3} />
                            )}
                        </div>
                        <div className="col-6">
                            {img4 ? (
                            <ImageView imgNum={4} />
                            ) : (
                            <DefaultView imgNum={4} />
                            )}
                        </div>

                        <div className="col-6">
                            {img5 ? (
                            <ImageView imgNum={5} />
                            ) : (
                            <DefaultView imgNum={5} />
                            )}
                        </div>
                        <div className="col-6">
                            {img6 ? (
                            <ImageView imgNum={6} />
                            ) : (
                            <DefaultView imgNum={6} />
                            )}
                        </div>
                        <div className="col-6">
                            {img7 ? (
                            <ImageView imgNum={7} />
                            ) : (
                            <DefaultView imgNum={7} />
                            )}
                        </div>
                        <div className="col-6">
                            {img8 ? (
                            <ImageView imgNum={8} />
                            ) : (
                            <DefaultView imgNum={8} />
                            )}
                        </div>

                        <div className="col-6">
                            {img9 ? (
                            <ImageView imgNum={9} />
                            ) : (
                            <DefaultView imgNum={9} />
                            )}
                        </div>
                        <div className="col-6">
                            {img10 ? (
                            <ImageView imgNum={10} />
                            ) : (
                            <DefaultView imgNum={10} />
                            )}
                        </div>

                        <div>
                            <p>Set the price for your private introductory folder. No user will be able to message you until they pay this price.</p>
                        </div>
                        <div className="model-inner">
                            <div className="model-box">
                            <input
                                value={price}
                                onChange={(event) => setPrice(event.target.value)}
                                step={0.01}
                                min={0}
                                type="number"
                                pattern="[0-9]*"
                                placeholder="Enter $ amount"
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <input
                        className="submit"
                        type="button"
                        defaultValue="Save"
                        onClick={handleContinue}
                    />
                    </form>
                </div>
                </div>
            </div>
            <Footer/>
            </div>
        </>
    )
}

export default PrivateIntroductoryFolder;