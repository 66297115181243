import { useState, useEffect } from "react";
import { Images } from "../../../themes";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { AppRoute } from "../../../router/routerConstants";
import { showErrorInUI } from "../../../utils/api/apiHelper";

const WithdrawAmount = () => {
  const [NavigateTo] = useCustomNavigate();
  const [originalValue, setOriginalValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState(50);
  const [valueToBeSend, setValueToBeSend] = useState(50);
  const [type, setType] = useState("Check");
  const [amountType, setAmountType] = useState("Full");
  const { state } = useLocation();

  useEffect(() => {
    if (!state || !state.id) {
      NavigateTo("/");
    }
  }, []);

  useEffect(() => {
    if (state && state.id) {
      setOriginalValue(state.current);
      setValueToBeSend(state.current);
    }
  }, []);

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="withdraw-amount-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Withdraw Amount</h1>
            </div>
            <div className="my-ac-form-info">
              <div className="form-inner-holder">
                <p>Due to the costs associated with processing withdrawals and sending payments, we do ask that all withdrawal requests be at least $50.00. If you experience any issues or have any questions please email <a href="mailto:support@arouzed.com">support@arouzed.com</a></p>
              </div>
            </div>
            <div className="my-ac-form-info">
              <div className="form-inner-holder">
                <p><b>Sutton Bank/Cash App is not available for wire transfers</b></p>
              </div>
            </div>
            <div className="withdraw-amount-inner">
              <div className="withdraw-amount-item">
                <div className="radio-select">
                  <input
                    type="radio"
                    name="amout"
                    value="Full"
                    checked={amountType === "Full"}
                    onChange={(e) => {
                      setAmountType("Full");
                      if (state.current >= 50) {
                        setValueToBeSend(state.current);
                      }
                    }}
                  />{" "}
                  <label />
                </div>
                <div className="withdraw-amoun-right">
                  <h4>Full Amount</h4>
                  <div className="amount-block">${state.current}</div>
                </div>
              </div>
              <div className="withdraw-amount-item">
                <div className="radio-select">
                  <input
                    type="radio"
                    name="amout"
                    value="Custom"
                    checked={amountType === "Custom" ? true : false}
                    onChange={(e) => {
                      setAmountType("Custom");
                      setValueToBeSend(state.current);
                      setSelectedValue(state.current);
                    }}
                  />{" "}
                  <label />
                </div>
                <div className="withdraw-amoun-right">
                  <h4>Custom Amount</h4>
                  <div className="amount-block">
                    <span>$</span>
                    <input
                      type="number"
                      value={selectedValue}
                      onChange={(e) => {
                        if (e.target.value > originalValue) return;
                        //console.log(e.target.value)
                        if (amountType === "Custom")
                          setValueToBeSend(e.target.value);
                        setSelectedValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <h2>How you would like to get paid?</h2>
              <div className="payment-type-block">
                <div className="payment-type-item">
                  <p>Check Payment</p>
                  <div className="select-cotent">
                    <input
                      type="checkbox"
                      name="select"
                      value="Check"
                      checked={type === "Check" ? true : false}
                      onChange={(e) => setType("Check")}
                    />
                    <label />
                  </div>
                </div>
                <div className="payment-type-item">
                  <p>Bank Transfer</p>
                  <div className="select-cotent">
                    <input
                      type="checkbox"
                      name="select"
                      value="Bank"
                      checked={type === "Bank" ? true : false}
                      onChange={(e) => setType("Bank")}
                    />
                    <label />
                  </div>
                </div>
              </div>
              <div
                className="btn-block"
                onClick={(e) => {
                  if (amountType === "Custom" && selectedValue < 50) {
                    return showErrorInUI("Minimum withdrawal amount is $50.00");
                  } 
                 if (amountType === "Full" && state.current < 50) {
                    return showErrorInUI("Minimum withdrawal amount is $50.00");
                  }
                  NavigateTo(AppRoute.Crea_WithdrawPage, false, {
                    amount: parseInt(valueToBeSend),
                    remaining: parseInt(state.current) - parseInt(valueToBeSend),
                    type: type,
                    selectedValue,
                  });
                }}
              >
                <span className="submit">Continue</span>
                <div className="btn-loader" />
              </div>
            </div>
            <div className="loader-wrap">
              <div className="loader" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WithdrawAmount;
