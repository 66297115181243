import React, { useEffect, useState } from "react";
import { Images } from "../../../themes";
import Header from "../../../components/Header";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../../components/Footer";
import ConsumerTab from "../../../components/ConsumerTab";
import { getData } from "../../../utils/api/apiHelper";
import { AppRoute } from "../../../router/routerConstants";
import {
  Cons_LoadFolder,
  LoadSubscription,
} from "../../../utils/api/actionConstants";
import CreateFolderModal from "../../../components/CreateFolderModal";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import InfiniteScroll from "react-infinite-scroll-component";

const FolderList = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  const folder = useSelector((state) => state.folder);
  const limit = 12;
  const [offset, setOffset] = useState(0);
  const hasMore1 = useSelector((state) => {
    return state.folder.hasMore;
  });

  const [hasMore, setHasMore] = useState(hasMore1);

  const setModalVisibility = (status) => {
    setShowModal(status);
  };

  useEffect(() => {
    if (!showModal) {
      fetchData(); 
    }
  }, [showModal]);

  useEffect(() => {
    const root = document.getElementById("scrollableDiv");
    if (hasMore && root.scrollHeight <= root.clientHeight) {
      fetchData( false, offset);
    }
  }, [hasMore, offset]);
  const fetchData = ( reset = true, offset = 0) => {
    const filterData = {
      limit: limit,
      offset: offset,
      
    };

    dispatch(
      getData({
        action: Cons_LoadFolder,
        data: filterData,
        sendAll:true
      })
    ).then((r) => {
      if (r.length === limit) {
        setHasMore(true);
        dispatch({ type: "HasMore", payload: true });
      } else {
        setHasMore(false);
        dispatch({ type: "HasMore", payload: false });
      }

      let list = [];
      if (reset) {
        list = r;
      } else {
          list = [...folder.co_folders, ...r];
      }
      setOffset(list.length);
      dispatch({ type: Cons_LoadFolder, payload: list });
    });
  };
  

  useEffect(() => {
    dispatch(
      getData({
        action: LoadSubscription,
      })
    );
  }, []);

  const ShowFolderLists = () => {
    return folder.co_folders.map((row,key) => {
      return (
        <div
          className="videos-photos-item"
          key={row.id}
          onClick={() => moveToFiles(row)}
        >
          <figure>
            <img src={Images.video_image_folder} alt="" />
          </figure>
          <label>{row.name}</label>
        </div>
      );
    });
  };

  const moveToFiles = (folder) => {
    NavigateTo(AppRoute.CONS_FILES, false, {
      id: folder.id,
    });
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="container folder-block">
        <div className="chat-box-wrap">
          <div className="heading-block create_new">
            <h1>My Folder</h1>{" "}
            <span
              className="crate-new"
              onClick={() => setModalVisibility(true)}
            >
              Create New
            </span>
          </div>
          <div className="setup-profile-wrap search-wrap">
          <div id="scrollableDiv" className="folder-mid-content gallery-img">
           
          <div className="my-acc-form-info">
            <div className="form-inner-holder">
            Important: The content you view on Arouzed.com is for your eyes only. Exposing content to non-Members can result in civil penalties up to $250,000. For more details be sure to read our  <span onClick={() => NavigateTo("/terms_conditions")}>Terms and Conditions</span>
            </div>
          </div>

             <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={folder.co_folders.length} //This is important field to render the next data
                next={() => fetchData( false, offset)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <div className="videos-photos-block">{ShowFolderLists()}</div>
              </InfiniteScroll>
            
          </div>
          </div>
          <ConsumerTab activeTab={1} />
        </div>
      </div>
      <Footer />
      {showModal && <CreateFolderModal modalVisibility={setModalVisibility} />}
    </div>
  );
};

export default FolderList;
