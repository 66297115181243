import { Images } from "../../../themes";
import { useEffect, useState } from "react";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useSelector, useDispatch } from "react-redux";
import { AppRoute } from "../../../router/routerConstants";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CreatorTab from "../../../components/CreatorTab";
import socket from "../../../socket/socket";
import { removeStorage } from "../../../utils/localStorage";
import { LogOutUser,UpdateIsNotification } from "../../../utils/api/actionConstants";
import BlurryLoadingImage from "../../../components/BlurryLoadingImage";
import { getData,saveData } from "../../../utils/api/apiHelper";
import {
  GetUserData
} from "../../../utils/api/actionConstants";
import { toast } from "react-toastify";
import Accordion from "../../../components/Accordion";
const MyProfile = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [emailNotification, setEmailNotification] = useState(userData.isEmailNotification);
  const [phoneNotification, setPhoneNotification] = useState(userData.isPhoneNotification);
  const [totalViews, setTotalViews] = useState(0);
  const [uniqueViews, setUniqueViews] = useState(0);

  const benefitData = [
    {
      title: 'New Revenue Model Starting Jan 1, 2025',
      content: `At Arouzed.com, we’re committed to providing a safe and supportive platform for women content creators. After listening to your feedback, we’re making an important change to our earnings structure.</br>
      Starting January 1, 2025, we’ll be moving to a revenue split model where creators keep 85% of their earnings, and the platform takes 15%.</br></br>
<strong>Until January 1, creators will still keep 100% of their earnings</strong>, so you can continue to enjoy that benefit for the rest of the year! </br></br>
Why the change? We’ve heard from both creators and the public that our previous model—where creators kept 100% of their earnings—caused confusion and even suspicion, with some thinking the platform might be a scam. We believe this new model will help build greater trust while still offering creators the chance to earn more!</br></br>
At Arouzed.com, there’s no need for self-promotion—we bring paying customers to you so you can focus on creating. Our platform is 100% women-owned, fully based in the U.S., and designed with your safety and privacy in mind. Enjoy full control over your content and the peace of mind that comes with working on a secure, U.S.-only platform.</br></br>
<strong>Thank you for being a part of the Arouzed community</strong>—we’re excited to continue supporting you with a platform that prioritizes your success, privacy, and security.`
    },
  ];
  const LogOut = () => {
    if (socket) {
      socket.close();
    }
    removeStorage("user");
    removeStorage("token");
    dispatch({ type: LogOutUser });
    NavigateTo(AppRoute.LOGIN, true);
  };
  useEffect(() => {
    dispatch(
      getData({
        action: GetUserData,
        sendAll: true,
      })
    ).then((r)=>{
      setTotalViews(r.totalViews);
      setUniqueViews(r.uniqueViews);
    });
  }, []);
  let profileUrl ="";
  if (typeof window !== 'undefined') {
     profileUrl = window.location.protocol + '//' + window.location.host + '/creator/profile/'+userData.id; // (or whatever)
  }
  const handlePhoneNotificationChange = (event) => {
    if(userData.mobileNumber){
      const value = !phoneNotification;
      setPhoneNotification(value);
      dispatch(
        saveData({
          action: UpdateIsNotification,
          data: {isPhoneNotification:value},
        })
      );
    }else{
      alert("We are redirecting you to update profile page. It seems you didn't update your phone number");
      NavigateTo(AppRoute.MyInformation)
    }
  };
  const handleEmailNotificationChange = (event) => {
    const value = !emailNotification;
    setEmailNotification(value);
    dispatch(
      saveData({
        action: UpdateIsNotification,
        data: {isEmailNotification : value},
      })
    );
   
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="my_account-wrap">
          <div className="heading-block">
            {/* <h1 className="">My Account</h1> */}
          </div>
          <div className="page-heading-block text-center"><h2>Dashboard</h2></div>
          
          <div className="my-ac-form-info">
            <div className="form-inner-holder">
              <p>This is your Creator Profile Dashboard, where you have overall control over your account. In order for your account to be approved, make sure to complete your Bio, and upload your Profile Images, at your earliest convenience.</p>
              <h4 className="cagg"><a href="../../arouzed_creator_agreement.pdf" target="_blank">Check Creator Agreement</a></h4>
            </div>
          </div>
          
          
             {/* <p><b>Updates Coming Soon:</b></p><br />
              <ul className="profile-feature-list">
              <li><i className="list-icon">
                  <img src={Images.list_icom} />
                </i>Optional Text Message Notifications</li>
              <li><i className="list-icon">
                  <img src={Images.list_icom} />
                </i>Profile View Counter</li>
              <li><i className="list-icon">
                  <img src={Images.list_icom} />
                </i>Ability to Initiate Chats with Members</li>
              <li><i className="list-icon">
                  <img src={Images.list_icom} />
                </i>Direct to Profile Promotional Links</li>
              </ul> */ }
              <div className="creator-benefits new-data">
                <Accordion data={benefitData} />
              </div>
          <div className="my-ac-top-wrap">
            
            <div className="my-account-content"> 
              <div className="ac-status-top">
                <h2>Account Status:</h2>
                <ul>
                  <li className={userData.isIdUploaded ? "sts-vrfyd" : "sts-pending"}>ID Uploaded</li>
                  <li className={userData.isMyInfoCompleted ? "sts-vrfyd" : "sts-pending"}>My Info Completed</li>
                  <li className={userData.isProfileImagesCompleted ? "sts-vrfyd" : "sts-pending"}>Profile Images Completed</li>
                 {/* <li className={userData.isPrivateFolderCompleted ? "sts-vrfyd" : "sts-pending"}>Private Folder Completed</li> */}
                  <li className={userData.isIdUploaded && userData.isMyInfoCompleted && userData.isProfileImagesCompleted ? "sts-vrfyd" : "sts-pending"}>Under Review</li>
                  {userData.isApproved === 1 ? (
                    <li className="sts-live">Live</li>
                  ) : (
                    <li className="sts-pending">Live</li> 
                  )}
                </ul>
              </div> 
            </div>
            <div className="my-account-img-block">
              <figure>
                <BlurryLoadingImage
                  profilePic={userData.profilePic}
                  isBlur={userData.isBlur}
                />
              </figure>
            </div>
          </div>

          <div className="my-account-buttons">
            <div className="mb-15">
              <h2 className="c-usename">Username: {userData.name} </h2>
                <div className="view-wrap">
                  <h3 className="total-view">Total page views: {totalViews}</h3>
                  <h3 className="total-view">Unique page views: {uniqueViews}</h3>
                </div>
              {(profileUrl && userData.isApproved === 1)?
                <div className="Share-link">
                    <h3 className="share-link">Share your profile with fans: <a title="Copy profile share link" onClick={async()=>{
                      await navigator.clipboard.writeText(profileUrl);
                      toast.success('Copied');
                    }} className="pointer"><svg width="20" height="20" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M66.6667 0C63.1304 0 59.7391 1.40476 57.2386 3.90524C54.7381 6.40573 53.3333 9.79711 53.3333 13.3333C53.3393 14.2706 53.444 15.2045 53.6458 16.1198L23.3724 31.2565C22.1238 29.819 20.5818 28.6656 18.8502 27.8739C17.1185 27.0822 15.2374 26.6706 13.3333 26.6667C9.79711 26.6667 6.40573 28.0714 3.90524 30.5719C1.40476 33.0724 0 36.4638 0 40C0 43.5362 1.40476 46.9276 3.90524 49.4281C6.40573 51.9286 9.79711 53.3333 13.3333 53.3333C15.238 53.3312 17.1201 52.921 18.8529 52.1304C20.5857 51.3398 22.129 50.1871 23.3789 48.75L53.6328 63.8802C53.4354 64.7959 53.335 65.7299 53.3333 66.6667C53.3333 70.2029 54.7381 73.5943 57.2386 76.0947C59.7391 78.5952 63.1304 80 66.6667 80C70.2029 80 73.5943 78.5952 76.0947 76.0947C78.5952 73.5943 80 70.2029 80 66.6667C80 63.1304 78.5952 59.7391 76.0947 57.2386C73.5943 54.7381 70.2029 53.3333 66.6667 53.3333C64.762 53.3355 62.8799 53.7457 61.1471 54.5363C59.4143 55.3269 57.871 56.4795 56.6211 57.9167L26.3672 42.7865C26.5646 41.8707 26.665 40.9368 26.6667 40C26.6603 39.065 26.5555 38.1332 26.3542 37.2201L56.6276 22.0833C57.8768 23.5196 59.4191 24.6718 61.1507 25.4624C62.8823 26.253 64.7631 26.6636 66.6667 26.6667C70.2029 26.6667 73.5943 25.2619 76.0947 22.7614C78.5952 20.2609 80 16.8696 80 13.3333C80 9.79711 78.5952 6.40573 76.0947 3.90524C73.5943 1.40476 70.2029 0 66.6667 0Z" fill="black"/>
                    </svg></a></h3> 
                </div>
                :null}
                 
                    <div className="notication-txt">
                      <div className="form-inner-holder">
                        
                         <p>Your text messages will come from  number "+18333246135". We will never mention “Arouzed", we will only tell you "Friendly reminder, you have unread messages.</p> 
                      </div>
                    </div> 
                    <div className="notification-switch">
                      <div className="hdr-switch">
                      <h3>Email Notification : </h3> 
                      <span className="btn-switch">			
                        <input type="radio" id="emailOn" value="true" name="emailNotification" onChange={(e) => handleEmailNotificationChange(e)} checked={emailNotification} className="btn-switch__radio btn-switch__radio_yes" />
                        <input type="radio" value="false" onChange={(e) => handleEmailNotificationChange(e)}   checked={!emailNotification}  id="emailOff" name="emailNotification" className="btn-switch__radio btn-switch__radio_no" />		
                        <label htmlFor="emailOff" className="btn-switch__label btn-switch__label_no"><span className="btn-switch__txt">Off</span></label>
                        <label htmlFor="emailOn" className="btn-switch__label btn-switch__label_yes"><span className="btn-switch__txt">On</span></label>
                        
                      </span>
                    </div>
                    <div className="hdr-switch">
                      <h3>Text Notification : </h3> 
                      <span className="btn-switch">			
                        <input type="radio" id="phoneOn" value="true" name="phoneNotification" onChange={(e) => handlePhoneNotificationChange(e)} checked={phoneNotification} className="btn-switch__radio btn-switch__radio_yes" />
                        <input type="radio" value="false" onChange={(e) => handlePhoneNotificationChange(e)}   checked={!phoneNotification}  id="phoneOff" name="phoneNotification" className="btn-switch__radio btn-switch__radio_no" />		
                        <label htmlFor="phoneOff" className="btn-switch__label btn-switch__label_no"><span className="btn-switch__txt">Off</span></label>
                        <label htmlFor="phoneOn" className="btn-switch__label btn-switch__label_yes"><span className="btn-switch__txt">On</span></label>
                        
                      </span>
                    </div>  
                </div> 
                
            </div>
            <div className="row creator">
              <div className="col-6">
                <button className="" onClick={() => NavigateTo(AppRoute.MyInformation)}>My Information & Settings</button>
              </div>
              <div className="col-6">
                <button className="" onClick={() => NavigateTo(AppRoute.PicVideos)}>My Pictures and Videos</button>
              </div>
              <div className="col-6">
                 <button className="" onClick={() => NavigateTo(AppRoute.Cons_Wallet)}>My Wallet</button>
              </div>
              <div className="col-6">
                <button className="" onClick={() => NavigateTo(AppRoute.StateExclusion)}>State Exclusion List</button> 
              </div>
              
              <div className="col-6">
                <button className="" onClick={() => NavigateTo(AppRoute.AutomatedMessage)}>Automated Message</button> 
              </div> 
            </div>
          </div>
          <div className="my-account-detail">
            { /*<div
              className="my-account-detail-item edit-profile"
              onClick={() =>
                NavigateTo(AppRoute.SETUP, false, { from: "edit" })
              }
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.edit_icon} alt="edit" />
                </figure>
                <span className="item-name">Edit Profile</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

              <div
              className="my-account-detail-item my-wallet"
              onClick={() => NavigateTo(AppRoute.Cons_Wallet)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.my_wallet} alt="wallet" />
                </figure>
                <span className="item-name">My Wallet</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

           <div
              className="my-account-detail-item my-wallet"
              onClick={() => NavigateTo(AppRoute.Withdrawal_request)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.my_wallet} alt="wallet" />
                </figure>
                <span className="item-name">Withdrawal Requests</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

            <div
              className="my-account-detail-item manage-bank"
              onClick={() => NavigateTo(AppRoute.ManageBank)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.bank_icon} alt="wallet" />
                </figure>
                <span className="item-name">Manage Bank</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

            <div
              className="my-account-detail-item manage-address"
              onClick={() => NavigateTo(AppRoute.ManageAddress)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.address_icon} alt="wallet" />
                </figure>
                <span className="item-name">Manage Address</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

            <div
              className="my-account-detail-item setting"
              onClick={() => NavigateTo(AppRoute.Settings)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.Setting} alt="wallet" />
                </figure>
                <span className="item-name">Settings</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div> */ }
            <span className="logout-btn" onClick={LogOut}>
              Logout
            </span>
          </div>
          <CreatorTab activeTab={3} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyProfile;
