import { useDispatch } from "react-redux";

import { AppRoute } from "../router/routerConstants";
import { getStorage, getStorageJson } from "../utils/localStorage";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { useState } from "react";
import {
  SubscribeToCreator,
  UpdateCreatorProfileSubscribed,
} from "../utils/api/actionConstants";
import { saveData } from "../utils/api/apiHelper";
import FullScreenLoader from "../components/FullScreenLoader";

const Subscription = ({ profileData }) => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const moveToSubscription = () => {
  //   dispatch(
  //     getData({
  //       action: CheckIfSubscriptionAllowed,
  //       sendAll: true,
  //     })
  //   ).then((resp) => {
  //     let current = resp;
  //     if (typeof current !== "undefined" && current === true) {
  //       NavigateTo(AppRoute.Subscriptions, false);
  //     } else {
  //       NavigateTo(AppRoute.Cons_AddMoney, false);
  //     }
  //   });
  // };
  const isUserLogin = () => {
    const token = getStorage("token");
    const user = getStorageJson("user");

    if (token && user && user.roleId) {
      return true;
    }
    return false;
  };
  const subscribeToCreator = () => {
    if (!isUserLogin()) {
      NavigateTo(AppRoute.CBENEFITS, false, { id: profileData?.id });
    }else{
      setLoading(true);
      dispatch(
        saveData(
          {
            action: SubscribeToCreator,
            data: {
              creatorId: profileData?.id,
            },
          },
          true
        )
      ).then((r) => {
        if (r.status !== 0) {
          dispatch({ type: UpdateCreatorProfileSubscribed, payload: {} });
        } else {
          if (r.message === "InsufficientBalance") {
            let data = r.data;
            NavigateTo(AppRoute.Cons_AddMoney, false, { id: profileData?.id });
          }
        }
      });
      setLoading(false);
    }
    
  };

  return (
    <>
      {profileData?.contentAmount !== "0.00" ? (
        <div>
          <FullScreenLoader loading={loading} />
          <div className="premium-block">
            <div className="premium-block-inner">
              {/* <p>$1.00 - 10 day trial then</p> */}
              <p> One Time Payment of ${profileData?.contentAmount}</p>
              <ul>
                {(!isUserLogin()?
                <>
                <li>Unlock Private Messaging</li>
                <li>View Private Folder</li>
                <li>Get Custom Content Made Just For You</li>
                <li>Become a Member for Free!</li></>:
                <> <li>Access to Private Folder</li>
                <li>Unlock Private One-on-One Messaging</li>
                <li>Receive Customized Content</li></>
                )}
               
                
              </ul>
            </div>
          </div>
          <div className="btn-block" onClick={subscribeToCreator}>
            <span className="submit"> {(!isUserLogin())?"Continue":"Send Payment"}</span>
          </div>
        </div>
      ) : (
        <div>
          <div className="premium-block">
            <div className="premium-block-inner">
              <h5>
                This Content Creator is currently not accepting private
                messages. Please check back at a later date
              </h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
