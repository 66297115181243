import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as CommonFunc from "../utils/Common";
import { saveData } from "../utils/api/apiHelper";
import { Cons_CreateFolder } from "../utils/api/actionConstants";

const CreateFolderModal = ({ modalVisibility }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const hideModal = () => {
    if (modalVisibility) {
      modalVisibility(false);
    }
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const saveBtnClkd = (e) => {
    e.preventDefault();

    if (!name || name == "") {
      CommonFunc.showError("Name is mandatory");
      return;
    }

    const params = {
      name: name,
    };

    const payload = {
      action: Cons_CreateFolder,
      data: params,
    };
    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        hideModal();
      }
    });
  };
  return (
    <div className="modal-wrapper create-folder" onClick={hideModal}>
      <div className="model-inner">
        <div className="model-box" onClick={handleChildClick}>
          <h3>Create Folder</h3>
          <form>
            <div className="form-group">
              <input
                type="text"
                placeholder="Folder Name"
                onChange={onNameChange}
              />
            </div>
            <div className="btn-block">
              <input
                type="button"
                className="submit"
                defaultValue="Save"
                onClick={saveBtnClkd}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
