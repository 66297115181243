import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { AppRoute } from "../router/routerConstants";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Accordion from "../components/Accordion";
const BenefitScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  const benefitData = [
    {
      title: 'We Bring Paying Customers to You',
      content: `No need to self-promote or risk exposure—we connect you with a paying audience, so you can focus on creating.`
    },
    {
      title: 'Complete Control Over Content Access',
      content: `Unlike other platforms, only people you specifically choose to send content to can view it. This ensures you decide exactly who sees your content—no one can access it without your permission, offering you maximum control over your audience.`
    },
    {
      title: 'US-Only Audience Protection',
      content: `Arouzed.com is committed to privacy and legal security, allowing only U.S.-based users on our platform. This keeps your content within U.S. legal boundaries, reducing the risk of exposure or misuse by foreign content scammers.`
    },
    {
      title: 'Protection Against Content Theft',
      content: `To safeguard your work and privacy, we prevent all non-U.S. actors from accessing the site, blocking foreign web scrapers and bulk content copying. This measure protects creators from unauthorized reposting on exploitative sites, keeping your content and identity secure.`
    },
    {
      title: 'Create Custom Content',
      content: `Tailor your content to individual requests and preferences, giving you full creative freedom in a secure, closed environment.`
    },
    {
      title: 'Maintain Your Privacy',
      content: `Blur your profile pictures to control how much you share publicly and customize how visible you want to be to new users.`
    },
    {
      title: 'Customize Your Visibility',
      content: `Easily block your profile from specific states, putting control in your hands over who can access your content even within the U.S.`
    },
    {
      title: 'Earn Discreetly',
      content: `Our platform values your privacy, enabling you to earn income without the need for self-promotion or public exposure, helping you stay secure and comfortable as a creator.`
    },
    {
      title: '100% Women-Owned, Based in the United States',
      content: `Arouzed.com is proudly 100% women-owned and fully U.S.-based. We’re dedicated to supporting female creators with a secure, privacy-focused platform.`
    },
    {
      title: '85/15 Payout Split',
      content: `With an industry-leading 85/15 payout split, creators keep 85% of their earnings. This ensures that more of your hard work goes directly to you.`
    },
  ];
  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="benfits-block">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Benefits</h1>
          </div>
          <div className="creator-benefits">
           
              <Accordion data={benefitData} />
            {/*
             <ul>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                <strong>We Bring Paying Customers to You</strong> No need to self-promote or risk exposure—we connect you with a paying audience, so you can focus on creating.
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                <strong>Build Personal Connections</strong> Engage with members one-on-one and cultivate deeper relationships through direct interactions.
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                <strong>Create Custom Content</strong>
                  Tailor your content to individual requests and desires, giving you full creative control.
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                <strong>Maintain Your Privacy</strong>
                Blur your profile pictures to control how much you share with the public.

              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                <strong>Customize Your Visibility</strong>
                Block your profile from specific states, ensuring control over who can access your content.
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                <strong>Earn Discreetly</strong>
                Enjoy a platform that prioritizes your privacy, allowing you to earn an income discreetly without self-promotion.
              </li>
            </ul> */ }

            <span
              className="submit"
              onClick={() => NavigateTo(AppRoute.REGISTER)}
            >
              Continue
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BenefitScreen;
