import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import * as CommonFunc from "../utils/Common";
import { saveData } from "../utils/api/apiHelper";
import { Cons_CreateFolder } from "../utils/api/actionConstants";

const AgeVerificationModal = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(true);

  const hideModal = () => {
    // if (visible) {
    //   setVisible(false);
    // }
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const saveBtnClkd = (e) => {
    setVisible(false);
    Cookies.set("ageVerification", CommonFunc.generateRandomString(), {
      expires: 365,
    });
  };
  return (
    <>
      {visible && (
        <div className="modal-wrapper create-folder" onClick={hideModal}>
          <div className="model-inner">
            <div className="model-box" onClick={handleChildClick}>
              <form>
                <div>Click to confirm you are at least 18 years of age</div>
                <br></br>
                <div>
                  Click to confirm you are responsible for following all local,
                  state and federal laws
                </div>
                <br></br>
                <div>
                  Click to confirm that by using our site you agree to all
                  <a href="https://arouzed.com/terms_conditions">
                    {` Terms and Conditions`}
                  </a>
                </div>
                <div className="btn-block">
                  <span
                    type="button"
                    className="submit"
                    defaultValue="Yes"
                    onClick={saveBtnClkd}
                  >
                    Yes
                  </span>
                  {/* <span
                      type="button"
                      className="submit"
                      defaultValue="Yes"
                      onClick={saveBtnClkd}
                    >
                      No
                    </span> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AgeVerificationModal;
