import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Images } from "../../../themes";
import Successfull from "../../Successfull";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import {
  LoadAllAddress,
  LoadAllBanks,
  MakeWithDrawal,
} from "../../../utils/api/actionConstants";
import { getData, saveData } from "../../../utils/api/apiHelper";
import { useAddress, useBanks } from "../../../hooks/useStore";
import RenderAddress from "./RenderAddress";
import RenderBanks from "./RenderBanks";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const WithdrawAmountPage = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState({});
  let Address = useAddress();
  let Banks = useBanks();
  const { state } = useLocation();

  useEffect(() => {
    if (!state || !state.type || !state.amount) {
      NavigateTo("/");
    }
  }, []);

  useEffect(() => {
    dispatch(
      getData({
        action: LoadAllBanks,
        data: { offset: 0, limit: 20 },
      })
    );
    dispatch(
      getData({
        action: LoadAllAddress,
        data: { offset: 0, limit: 20 },
      })
    );
  }, []);

  const addWithdrawalBtnClkd = () => {
    // withdrawal
    if (!selectedEntry || !selectedEntry.id) return;
    setConfirmation(false);
    if (state.amount > 0) {
      setLoading(true);
      dispatch(
        saveData({
          action: MakeWithDrawal,
          data: {
            amount: state.amount,
            type: state.type,
            id: selectedEntry.id,
          },
        })
      ).then((r) => {
        setLoading(false);
        if (r === 1) {
          setSuccess(true);
        }
      });
    }
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      {success ? (
        <Successfull
          type={"addWithdrawal"}
          remaining={<strong>${state.remaining}</strong>}
          amount={<strong>${state.amount}</strong>}
        />
      ) : (
        <div className="wrapper">
          <div className="container">
            <div className="withdraw-amount-wrap">
              <div className="heading-block">
                <span className="back-btn" onClick={() => NavigateTo(-1)}>
                  <img src={Images.backImg} alt="back-btn" />
                </span>
                {state.type == "Check" ? (
                  <h1>Check Payment</h1>
                ) : (
                  <h1>Bank Withdrawal</h1>
                )}
              </div>
              <div className="withdraw-amount-inner">
                {state.type == "Bank" ? (
                  <RenderBanks
                    Banks={Banks}
                    setSelected={setSelectedEntry}
                    selected={selectedEntry}
                  />
                ) : (
                  <RenderAddress
                    Address={Address}
                    setSelected={setSelectedEntry}
                    selected={selectedEntry}
                  />
                )}
                <div
                  className="btn-block"
                  onClick={(e) => setConfirmation(true)}
                >
                  <span className="submit">Withdraw of ${state?.amount}</span>
                  <div className="btn-loader" />
                </div>
              </div>
              <div className="loader-wrap">
                <div className="loader" />
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      {confirmation && selectedEntry && (
        <div className="modal-wrapper">
          <div className="model-inner delete-model">
            <div className="model-box">
              <h3>Withdrawal Confirmation</h3>
              <div className="widthdrawl-block">
                <div className="widthdrawl-item">
                  <label>Amount :</label>
                  <span>${state.amount}</span>
                </div>
                <div className="widthdrawl-item">
                  <label>Withdrawal Type :</label>
                  <span>{state.type} Transfer</span>
                </div>
                <div className="widthdrawl-item">
                  <label>
                    Selected {state.type === "Bank" ? "Bank" : "Address"} :
                  </label>
                  <span>
                    {state.type === "Bank"
                      ? selectedEntry?.bankName
                      : selectedEntry?.name}
                  </span>
                </div>
              </div>
              <div className="btn-block">
                <span
                  className="reset-btn"
                  onClick={() => setConfirmation(false)}
                >
                  Cancel
                </span>
                <div className="btn-inner" onClick={addWithdrawalBtnClkd}>
                  <span className="submit">Continue</span>
                  <div className="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawAmountPage;
