import { Images } from "../../themes";
import { useSelector, useDispatch } from "react-redux";
import { AppRoute } from "../../router/routerConstants";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { saveData } from "../../utils/api/apiHelper";
import { UpdateIsBlur } from "../../utils/api/actionConstants";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Settings = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  const onBlurChange = (value) => {
    dispatch(
      saveData({
        action: UpdateIsBlur,
        data: {},
      })
    );
  };
  //
  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="setting-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Settings</h1>
            </div>
            <div className="setting-inner">
              <div
                className="setting-item"
                onClick={() => NavigateTo(AppRoute.ChangePassword)}
              >
                <span className="setting-item-name">Change Password</span>{" "}
                <span className="right-arrow">
                  <img src={Images.chevron_right} alt="arrow" />
                </span>
              </div>
              <div
                className="setting-item"
                onClick={() => NavigateTo(AppRoute.TermsConditions)}
              >
                <span className="setting-item-name">Terms and Conditions</span>{" "}
                <span className="right-arrow">
                  <img src={Images.chevron_right} alt="arrow" />
                </span>
              </div>
              {/* {userData.role === "Creator" && (
                <div className="setting-item">
                  <span className="setting-item-name">Blur Image</span>
                  <span className="right-arrow">
                    <input
                      type="checkbox"
                      onChange={(e) => onBlurChange(e.target.checked)}
                      checked={userData.isBlur}
                    />
                  </span>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Settings;
