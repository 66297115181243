import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Images } from "../../../themes";
import { ListWithDrawal } from "../../../utils/api/actionConstants";

import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useWithDrawal } from "../../../hooks/useStore";
import { getData } from "../../../utils/api/apiHelper";
import {
  generateRandomString,
  getHourMinute,
  getAmPm,
} from "../../../utils/Common";

const WithdrawalRequests = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  let withDrawal = useWithDrawal();

  useEffect(() => {
    dispatch(
      getData({
        action: ListWithDrawal,
        data: { offset: 0, limit: 20 },
      })
    );
  }, []);

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="request-purchased-wrap">
          <div className="heading-block">
            <span className="back-btn" onClick={() => NavigateTo(-1)}>
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>Withdrawal Requests</h1>
          </div>

          <div className="view-request-items wallet-items">
            {withDrawal &&
              withDrawal.length > 0 &&
              withDrawal.map((v, k) => {
                return (
                  <div
                    className="view-request-list"
                    key={generateRandomString()}
                  >
                    <div className="view-request-img">
                      <img src={Images.debit} alt="debit" />
                    </div>
                    <div className="view-request-detail">
                      <div className="View-request-inner">
                        <div className="wallet-top-block">
                          <h3>Withdrawal request for </h3>
                          <div className="price credit">
                            {!v.isPaid && "!"} ${v.amount}
                          </div>
                        </div>
                        <div className="wallet-bottom-block">
                          <p>
                            {getHourMinute(v.createdAt)} {getAmPm(v.createdAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WithdrawalRequests;
