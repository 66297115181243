import { useState, useEffect } from "react";
import { Images } from "../themes";
// import Cookies from "js-cookie";
// import AgeVerificationModal from "../components/AgeVerificationModal";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { Route, Link, Routes, useLocation } from "react-router-dom";

const Footer = () => {
  const [NavigateTo] = useCustomNavigate();
  const [ageVerification, setAgeVerification] = useState(false);

  const popupIgnorePath = [
    "/about",
    "/refund_policy",
    "/2257_statement",
    "/terms_conditions",
    "/complaints",
  ];

  const location = useLocation();
  const d = new Date();

  const { pathname } = location;

  // useEffect(() => {
  //   const ageCheck = Cookies.get("ageVerification");
  //   if (ageCheck) {
  //     setAgeVerification(true);
  //   }
  // }, []);

  return (
    <section className="footer">
      {/* {!ageVerification && !popupIgnorePath.includes(pathname) && (
        <AgeVerificationModal />
      )} */}
      <div className="footer-container">
        <ul>
          {/* <li>
            <span onClick={() => NavigateTo("/about")}>About</span>
          </li> */}
          <li>
            <span onClick={() => NavigateTo("/refund_policy")}>
              Refund Policy
            </span>
          </li>
          <li>
            <span onClick={() => NavigateTo("/complaints")}>Complaints</span>
          </li>
          <li>
            <span onClick={() => NavigateTo("/terms_conditions")}>Terms</span>
          </li>
          <li>
            <span onClick={() => NavigateTo("/2257_statement")}>
              2257 Statement
            </span>
          </li>
        </ul>
        <div className="copyright">
          <p>© {d.getFullYear()} Copyright - support@arouzed.com</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
