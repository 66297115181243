const ReportMessage = ({ onTextChange, onSubmit, modalVisibility }) => {
  const hideModal = () => {
    if (modalVisibility) {
      modalVisibility();
    }
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-wrapper report-user-modal" onClick={hideModal}>
      <div className="model-inner" onClick={handleChildClick}>
        <div className="model-box">
          <h3>Report User</h3>
          <form>
            <div className="form-group texarea">
              <textarea
                placeholder="Describe the issue you would like to report..."
                defaultValue={""}
                onChange={(e) => onTextChange(e.target.value)}
              />
            </div>
            <div className="btn-block">
              <div className="btn-block-in">
                <input
                  type="button"
                  className="submit"
                  defaultValue="Submit"
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportMessage;
