import { useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { AddCards } from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { saveData } from "../../../utils/api/apiHelper";
import * as CommonFunc from "../../../utils/Common";
import States from "../../../utils/US_states.json";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { AppRoute } from "../../../router/routerConstants";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { useLocation } from 'react-router-dom';
const AddNewCard = () => {
  const [NavigateTo] = useCustomNavigate();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [number, setNumber] = useState("");
  const [cerditNumber, setCerditNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCVV] = useState("");
  const [Address1, setAddress1] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zipcode, setZipcode] = useState("");
  //const excludeState = ["Texas","Utah","Montana","Louisiana","Arkansas","Mississippi","Virginia","North Carolina"];
  const excludeState = [];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLocation();

  const AddCard = (e) => {
    e.preventDefault();
    if(excludeState.indexOf(State) !== -1)
    {
      CommonFunc.showError("You can proceed with this states, Please try with another one");
      return;
    }
    if (cerditNumber.replace(/\s/g, '') < 13) {
      CommonFunc.showError("Invalid card number");
      return;
    }
    if (!expiry || expiry.length < 5 || !CommonFunc.validateExpiry(expiry)) {
      CommonFunc.showError("Invalid expiry");
      return;
    }

    if(Address1.length > 59){
      CommonFunc.showError("Address must be less than 59 characters.");
      return;
    }

    if(fname.length > 49){
      CommonFunc.showError("First name must be less than 50 characters.");
      return;
    }

    if(lname.length > 49){
      CommonFunc.showError("Last name must be less than 50 characters.");
      return;
    }

    if(City.length > 39){
      CommonFunc.showError("City must be less than 40 characters.");
      return;
    }

    if(Zipcode.length > 19){
      CommonFunc.showError("Zipcode must be less than 20 characters.");
      return;
    }
    setLoading(true);
    dispatch(
      saveData({
        action: AddCards,
        data: {
          fname: fname,
          lname: lname,
          number: cerditNumber.replace(/\s/g, ''),
          expiry: expiry,
          cvv: cvv,
          Address1: Address1,
          City: City,
          State: State,
          Zipcode: Zipcode,
          Country: 'US',
        },
      })
    ).then((r) => {
      setLoading(false);
      if(r.status===0){
       
      }else{
        NavigateTo(AppRoute.Cons_AddMoney, false, { id: state?.id });
        //NavigateTo(AppRoute.Cons_AddMoney)
      }
        
    });
  };
  const formatCardNumber = (value) => {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, '');
    // Format the card number in groups of 4 digits
    const match = cleaned.match(/.{1,4}/g);
    return match ? match.join(' ') : cleaned;
  };
  const handleCardNumberChange = (e) => {
    const formattedCardNumber = formatCardNumber(e.target.value);
    setNumber(e.target.value.toString())
    setCerditNumber(formattedCardNumber);
  };
  const handleKeyDown = (e) => {
    // Handle backspace key
    if (e.key === 'Backspace') {
      const { selectionStart, value } = e.target;
      if (selectionStart === value.length) {
        // Check if a space should be removed
        if (value[selectionStart - 1] === ' ') {
          setCerditNumber(value.slice(0, -2));
        } else {
          setCerditNumber(value.slice(0, -1));
        }
      }
    }
  };
  const setExpiryCustom = (text) => {
    const noSpecialChars = text.replace(/[^0-9/]/g, "");

    let textTemp = noSpecialChars;
    if (textTemp[0] !== "1" && textTemp[0] !== "0") {
      textTemp = "";
    }

    if (textTemp.length === 2) {
      if (
        parseInt(textTemp.substring(0, 2)) > 12 ||
        parseInt(textTemp.substring(0, 2)) == 0
      ) {
        textTemp = textTemp[0];
      } else if (expiry.length === 1) {
        textTemp += "/";
      } else {
        textTemp = textTemp[0];
      }
    }
    setExpiry(textTemp);
  };

  //   setExpiry(textTemp);
  //   // this.setState({text: textTemp})
  // };

  // const setExpiryCustom = (value) => {
  //   if (value.length > 5) {
  //     return;
  //   }

  //   let rr = /^(0[1-9]|1[0-2])\/([0-9]{2})$/g.test(value);
  //   let val = value;
  //   if (!val.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)) {
  //     setExpiryError(true);
  //   } else {
  //     setExpiryError(false);
  //   }
  //   setExpiry(rr);
  // };

  const handleCVV = (text) => {
    const noSpecialChars = text.replace(/[^0-9/]/g, "");

    setCVV(noSpecialChars);
  };

  return (
    <>
      <FullScreenLoader loading={loading} />
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="new-card-wrap">
            <div className="heading-block">
              <h1>Add New Card</h1>
            </div> 
            <div className="new-card-form">
              <form onSubmit={(e) => AddCard(e)}>
                <div className="form-group">
                  <input
                    type="text"
                    required
                    value={fname}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="Card Holder First Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    required
                    value={lname}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Card Holder Last Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    required
                    value={cerditNumber}
                    onChange={handleCardNumberChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Credit Card Number"
                    maxLength="19"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-7">
                    <input
                      required
                      type="text"
                      value={expiry}
                      onChange={(e) => setExpiryCustom(e.target.value)}
                      placeholder="MM/YY"
                      maxLength={"5"}
                    />
                  </div>
                  <div className="form-group col-5">
                    <input
                      type="text"
                      placeholder="CVV"
                      maxLength={3}
                      onChange={(e) => handleCVV(e.target.value)}
                      value={cvv}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={Address1}
                    required
                    onChange={(e) => setAddress1(e.target.value)}
                    placeholder="Address"
                  />
                </div>
                <div className="form-group">
                  <select required onChange={(e) => setState(e.target.value)}>
                      <option value="" >Please select a State</option>
                      {States.map((st) => {
                          if(excludeState.indexOf(st.label) !== -1)
                           return "null"
                          return(<option key={st.label} selected={State === st.label} value={st.label}>{st.label}</option>)
                        })}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={City}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    value={Zipcode}
                    required
                    onChange={(e) => setZipcode(e.target.value)}
                    placeholder="Zipcode"
                  />
                </div>
                <p className="info text-center">
                  <b>Above address will be used for billing purposes.</b>
                </p>
                {/*
                <p className="info text-center">
                <b>Accounts originating from the following States are currently not permitted due to ID Verification Laws: TX, LA, IN, UT, AR, MS, NC, MT, VA. We are working to upgrade our Platform to adhere to these laws.</b>
                </p> */ }
                <figure className="text-center">
                  <img src={Images.card_logos} alt="" />
                </figure>
                <div className="btn-block">
                  <input type="submit" value="Process Payment & Continue" className="submit" />
                  <div className="btn-loader"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
      <Footer />
    </>
  );
};

export default AddNewCard;
