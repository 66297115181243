import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  searchList: [],
  favList: [],
  creatorProfile: {},
  filter: {},
  tags: [],
  hasMore: false,
};

export const creatorSlice = createSlice({
  name: "creator",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCreatorSearch: (state) => {
      state.searchList = [];
    },
  },
  extraReducers: {
    SearchCreator: (state, action) => {
      state.searchList = action.payload;
    },
    CreatorProfile: (state, action) => {
      state.creatorProfile = action.payload;
    },
    UpdateCreatorProfileSubscribed: (state, action) => {
      let creatorProfile = state.creatorProfile;
      creatorProfile.isBought = true;
      state.creatorProfile = creatorProfile;
    },
    ResetProfile: (state, action) => {
      state.creatorProfile = {};
    },
    SubscribeToCreator: (state, action) => {
      let creatorProfile = state.creatorProfile;
      creatorProfile.isBought = true;
      state.creatorProfile = creatorProfile;
    },
    SetFilter: (state, action) => {
      state.filter = action.payload;
    },
    HasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    GetTags: (state, action) => {
      state.tags = action.payload;
    },
    GetFav: (state, action) => {
      state.favList = action.payload;
    },
    UpdateFav: (state, action) => {
      const list = state.searchList.map((row) => {
        let data = action.payload;
        if (row.id === data.userId) {
          if (row.favorites && row.favorites.length > 0) {
            row.favorites = [];
          } else {
            row.favorites = [data];
          }
        }
        return row;
      });
      state.searchList = list;
    },
  },
});

export const { resetCreatorSearch } = creatorSlice.actions;
export const creatorData = (state) => state.creator;
export default creatorSlice.reducer;
