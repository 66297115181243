import { useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { AddBanks } from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { saveData } from "../../../utils/api/apiHelper";
import * as CommonFunc from "../../../utils/Common";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const AddBank = () => {
  const [NavigateTo] = useCustomNavigate();
  const [name, setName] = useState("");
  const [holderAddress, setHolderAddress] = useState("");
  const [number, setNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankIFSC, setBankIFSC] = useState("");
  const [bankState, setBankState] = useState("");
  const [bankCity, setBankCity] = useState("");

  const dispatch = useDispatch();

  const AddBank = (e) => {
    e.preventDefault();
    const restrictedNames = ["cash app", "cashapp", "cash-app", "sutton"];
    const lowerBankName = bankName.toLowerCase();
    if (restrictedNames.some(name => lowerBankName.includes(name))) {
      CommonFunc.showError("Sutton Bank/Cashapp is not available for wire payments.");
      return;
    }
    dispatch(
      saveData({
        action: AddBanks,
        data: { holderName: name, holderAddress, acountNumber: number, bankName, bankAddress, bankIFSC,bankState,bankCity },
      })
    ).then((r) => {
      CommonFunc.showSuccess("Bank details added successfully");
      setName("");
      setNumber("");
      setBankIFSC("");
      setBankName("");
      setBankState("");
      setBankCity("");
      setHolderAddress("");
      setBankAddress("");
      NavigateTo(-1);
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="add-new-bank-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Add New Bank</h1>
            </div>
            <div className="add-new-bank-inner">
              <form onSubmit={(e) => AddBank(e)}>
                <div className="form-group">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Account Holder Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    value={number}
                    onChange={(e) => {
                      const num = e.target.value.slice(0, 16);
                      setNumber(num);
                    }}
                    placeholder="Account Number"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={holderAddress}
                    onChange={(e) => setHolderAddress(e.target.value)}
                    placeholder="Account Holder Address"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    placeholder="Bank Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={bankAddress}
                    onChange={(e) => setBankAddress(e.target.value)}
                    placeholder="Bank Address"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={bankState}
                    onChange={(e) => setBankState(e.target.value)}
                    placeholder="Bank State"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={bankCity}
                    onChange={(e) => setBankCity(e.target.value)}
                    placeholder="Bank City"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={bankIFSC}
                    onChange={(e) => setBankIFSC(e.target.value)}
                    placeholder="Routing Number"
                    required
                  />
                </div>
                
                <div className="btn-block">
                  <input type="submit" className="submit" defaultValue="Save" />
                  <div className="btn-loader" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddBank;
