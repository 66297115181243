import { createSlice, current } from "@reduxjs/toolkit";
const initialState = {
  lifetimeEarning: 0,
  monthlyEarning: 0,
  walletEntries: [],
  distinctMonthlyWalletEntries: [],
  monthlyWalletEntries: [],
  betweenWalletEntries: [],
};

export const folderSlice = createSlice({
  name: "reports",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: {
    LoadCreatorReports: (state, action) => {
      let payload = action.payload;
      let query = payload.query;
      let data = payload.data;
      if (!data || data.length < 1) return;

      switch (query.action) {
        case "lifeTimeEarning":
          state.lifetimeEarning = data[0].total;
          break;
        case "monthlyEarning":
          state.monthlyEarning = data[0].total;
          break;
        case "activeCreator":
            state.activeCreator = data[0].total;
            break;
        case "walletEntries":
          state.walletEntries = data;
          break;
        case "distinctMonthlyWalletEntries":
          state.distinctMonthlyWalletEntries = data;
          break;
        default:
          break;
      }
    },
    LoadCreatorReportsLimit: (state, action) => {
      let payload = action.payload;
      let query = payload.query;
      let data = payload.data;
      // if (!data || data.length < 1) return;

      switch (query.action) {
        case "walletEntries":
          let length = state.walletEntries.length;
          let walletEntries = current(state.walletEntries);
          if (length < 1) {
            state.walletEntries = data;
          } else {
            if (data.length > 0) {
              let last = walletEntries[length - 1];
              if (last.id !== data[0].id && walletEntries[0].id !== data[0].id) {
                state.walletEntries = [...walletEntries, ...data];
              }
            }
          }
          return;
        case "monthlyWalletEntries":
          state.monthlyWalletEntries = data;
          return;
        case "betweenWalletEntries":
          state.walletEntries = data;
          return;
        default:
          return;
      }
    },
  },
});

export const { LoadCreatorReports } = folderSlice.actions;
export const reportsData = (state) => state.reports;
export default folderSlice.reducer;
