import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Images } from "../../../themes";
import { LoadCreatorReports } from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useReports } from "../../../hooks/useStore";
import { fetchReportData } from "../../../utils/api/apiHelper";
import ShowReportRow from "../../../components/ShowReportRow";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import DateFilter from "../../../components/DateFilter";

const MonthlyEarningsList = () => {
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  const localtion = useLocation();
  const reports = useReports();
  const [showFilter, setShowFilter] = useState(false);

  const filterVisibility = (status) => {
    setShowFilter(status);
  };

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadCreatorReports,
        data: {
          action: "monthlyWalletEntries",
        },
      })
    );
  }, [dispatch]);

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="request-purchased-wrap">
            <div className="heading-block">
              <span className="back-btn">
                <img
                  src={Images.backImg}
                  alt="back-btn"
                  onClick={() => NavigateTo(-1)}
                />
              </span>
              <h1>Monthly Earning</h1>
            </div>
            <div className="earning-block">
              <div className="earning-inner">
                <div className="left-block">
                  <h5>Monthly Earning</h5>
                  <h2>${localtion?.state?.monthlyEarning}</h2>
                </div>
                <div className="right-block">
                  <figure>
                    <img src={Images.monthly_icon} alt="back-btn" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="view-request-items">
              <ShowReportRow rows={reports.monthlyWalletEntries} />
            </div>
            {showFilter && <DateFilter modalVisibility={filterVisibility} />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MonthlyEarningsList;
