import { generateRandomString, viewDateDDMMYYY } from "../utils/Common";
import {  useUser } from "../hooks/useStore";
import { Images } from "../themes";
const ShowReportRow = ({ rows }) => {
  const currentUser = useUser();
  const isCreator = () => {
    return currentUser.role === "Creator";
  };
  if (!rows || rows.length === 0) {
    return <div className="no-data-found">No data found</div>;
  }
  return (
    rows &&
    rows.length > 0 &&
    rows.map((row) => {
      return (
        <div className="view-request-list" key={generateRandomString()}>
          <div className="view-request-img">
            <figure>
            <img src={row && row?.profilePic != "" ? row?.profilePic : (isCreator())?Images.profileMale :Images.default_profile} />
              
            </figure>
          </div>
          <div className="view-request-detail">
            <div className="view-request-inner">
              <h3>{row?.name}</h3>
              <p>{viewDateDDMMYYY(row?.createdAt)}</p>
            </div>
            <div className="price">${row?.amount}</div>
          </div>
        </div>
      );
    })
  );
};

export default ShowReportRow;
