import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserData } from "../redux/userReducer";

import { getStorage, getStorageJson } from "../utils/localStorage";

function PrivateRoute({ children }) {
  const dispatch = useDispatch();
  const token = getStorage("token");
  const user = getStorageJson("user");
  dispatch(updateUserData(user));

  if (!token) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }
  return children;
}

export default PrivateRoute;
