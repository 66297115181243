import { useEffect, useRef, useState } from "react";
import { Images } from "../../../themes";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import { UploadFile } from "../../../utils/api/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import {
  nextSetupPage,
  prevSetupPage,
  updateUserData,
} from "../../../redux/userReducer";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import * as CommonFunc from "../../../utils/Common";
import {
  UpdateCreator,
  UpdateIsBlur,
} from "../../../utils/api/actionConstants";
import FullScreenLoader from "../../../components/FullScreenLoader";
import States from "../../../utils/US_states.json";
import Select from "react-select";

const Setup2 = ({ from }) => {
  const ref = useRef();

  const [NavigateTo] = useCustomNavigate();

  const dispatch = useDispatch();

  const [heading, setHeading] = useState("");
  const [aboutMe, setAboutme] = useState("");
  // const [zip, setZip] = useState("DEFAULT");
  const [state, setState] = useState([]);
  const [img, setImg] = useState();
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [zip, setZip] = useState("");

  const userData = useSelector((state) => state.user.userData);
  useEffect(() => {
    setHeading(userData.heading);
    setAboutme(userData.aboutMe);

    setZip(userData.zipcode);

    const userState = Array.isArray(userData?.state) ? userData?.state : [];

    // const st = userState.map((row) => {
    //   return { label: row, value: row };
    // });

    setState(userState);
    // setZip(
    //   userData.zipcodes && userData.zipcodes.length > 0
    //     ? userData.zipcodes[0]
    //     : "DEFAULT"
    // );
    setImgUrl(userData.profilePic);
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();
    /*if (!imgUrl || imgUrl == "") {
      CommonFunc.showError("Please select a profile Image");
      return;
    }*/
    if (!heading || heading == "") {
      CommonFunc.showError("Heading is mandatory");
      return;
    }

    if (!aboutMe || aboutMe == "") {
      CommonFunc.showError("About me is mandatory");
      return;
    }

    if (!zip || zip == "") {
      CommonFunc.showError("Zipcode is mandatory.");
      return;
    }
    // if (!tags || tags == "") {
    //   CommonFunc.showError("Tags are mandatory");
    //   return;
    // }
    // if (!zip || (zip && zip.length < 5)) {
    //   CommonFunc.showError("Zipcode is mandatory.");
    //   return;
    // }

    const params = {
      heading: heading,
      aboutMe: aboutMe,
     // profilePic: imgUrl,
      zipcode: zip,
      state: state,
    };

    dispatch(updateUserData(params));

    const payload = {
      action: UpdateCreator,
      data: params,
    };
    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        // NavigateTo(AppRoute.SETUP3);
        dispatch(nextSetupPage());
      }
    });
  };

  const onImgClick = (event) => {
    ref.current.click();
  };
  const onImageChange = (e) => {
    setLoading(true);

    const [file] = e.target.files;
    const fd = new FormData();
    fd.append("file", file);
    setImg(URL.createObjectURL(file));
    setImgUrl("");

    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      setLoading(false);

      if (r.length > 0) {
        setImgUrl(r[0]);
      }
    });
  };

  const handleChange = (e) => {
    // e.preventDefault();

    if (state.length === 49) {
      CommonFunc.showError("Only 49 states are allowed.");
      return;
    }

    // if (e.length > 5) {
    //   CommonFunc.showError("Only five states are allowed.");
    //   const new_state = e.slice(0, 5);
    //   setState(new_state);
    //   return;
    // }

    if (state.includes(e.target.name)) {
      const newStates = state.filter((r) => r != e.target.name);

      setState(newStates);
    } else {
      setState([...state, e.target.name]);
    }
  };

  const showStates = () => {
    return (
      <div className="profile-states">
        {States.map((st) => {
          return (
            <div className="setting-item">
              <input
                type="checkbox"
                id={st.label}
                key={st.label}
                name={st.label}
                onChange={(e) => handleChange(e)}
                checked={state.includes(st.label)}
              />
              <label className="setting-item-name" for={st.label}>
                {st.label}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const onBlurChange = (value) => {
    dispatch(
      saveData({
        action: UpdateIsBlur,
        data: {},
      })
    );
  };

  return (
    <>
      <FullScreenLoader loading={loading} />
      <div className="container">
        <div className="setup-profile-wrap">
          <div className="heading-block">
          <span className="back-btn">
            <img
              src={Images.backImg}
              alt="back-btn"
              onClick={() => dispatch(prevSetupPage())}
            />
          </span>
            
            <h1>Profile Setup</h1>
          </div>
          <div className="step-bar">
            <ul className="steps">
            { /*<li className="step is-complete is-active" data-step={1} />
              <li className="step  is-active" data-step={2} />
              <li className="step " data-step={3} />
              <li className="step" data-step={4} />
              {from && from === "edit" ? (
                <li className="step " data-step={5} />
                ) : (
                <li className="step " data-step={5} />
              )} */ }
            </ul>
          </div>
          <div className="form-block">
            <form>
              <div className="all-input">
                {/*<div className="upload-img">
                  <figure>
                    <img
                      src={
                        imgUrl != "" ? imgUrl : img ?? Images.default_profile
                      }
                      alt="profile"
                    />
                  </figure>
                  <span className="upload-icon" onClick={onImgClick}>
                    <img src={Images.cameraIcon} alt="" />
                    <input
                      ref={ref}
                      type="file"
                      id="input_file"
                      accept=".jpg,.jpeg,.png"
                      onChange={onImageChange}
                      style={{ display: "none" }}
                    />
                  </span>
                </div>
                    <div className="main-profile-content">Main Profile Image</div> 
                <div className="blur-image">
                  <label for="blutcheck">Blur profile image</label>
                  <input
                    id="blutcheck"
                    type="checkbox"
                    onChange={(e) => onBlurChange(e.target.checked)}
                    checked={userData.isBlur}
                  ></input>
                </div>*/ }
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="One catchy sentence about yourself..."
                    value={heading}
                    onChange={(event) => setHeading(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    placeholder="Description. Be flirty and have a little fun."
                    defaultValue={""}
                    value={aboutMe}
                    onChange={(event) => setAboutme(event.target.value)}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    value={zip}
                    placeholder="Zip code"
                    onChange={(event) => setZip(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="form-group-content">
                    <h3>Profile Display Exclusion</h3>
                    <p>
                      You may select states where your profile will not be
                      displayed..
                    </p>
                  </div>

                  {/* <div className="setting-item">
                    <span className="setting-item-name">Blur Image</span>
                    <span className="right-arrow">
                      <input
                        type="checkbox"
                        // onChange={(e) => onBlurChange(e.target.checked)}
                        checked={userData.isBlur}
                      />
                    </span>
                  </div> */}
                  {showStates()}

                  {/* <div className="form-group">
                    <Select
                      // defaultValue={state}
                      onChange={handleChange}
                      options={States}
                      value={state}
                      isMulti
                    /> */}

                  {/* <select
                      name="States"
                      id="states"
                      value={state}
                      multiple
                      onChange={handleChange}
                    >
                      <option value="DEFAULT" disabled hidden>
                        Select State
                      </option>
                      {States.map((r) => {
                        return (
                          <option key={r.name} value={r.name}>
                            {r.name}
                          </option>
                        );
                      })}
                    </select> */}
                  {/* </div> */}
                </div>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleContinue}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setup2;
