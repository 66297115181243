import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { AppRoute } from "../router/routerConstants";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { saveData } from "../utils/api/apiHelper";
import { SignUp } from "../utils/api/actionConstants";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as Validate from "../utils/validatorXerox";
import Accordion from "../components/Accordion";

const BenefitScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [secondConfirm, setSecondConfirm] = useState(false);
  const [thirdConfirm, setThirdConfirm] = useState(false);

  const { state } = useLocation();

  const benefitData = [
    {
      title: 'Find Your Perfect Match',
      content: `Search for the right creator who fits your preferences and get custom content made just for you.`
    },
    {
      title: 'Custom Content Just for You',
      content: `Receive personalized content tailored to your desires—no generic posts, just what you’re looking for.`
    },
    {
      title: 'More Women Signing Up Every Day',
      content: `Discover fresh faces daily as new creators join to share their unique and exclusive content.`
    },
    {
      title: 'Private, One-on-One Messaging',
      content: `Connect with creators on a personal level through private chats designed to build a real connection.`
    },
    {
      title: 'Chat for Free—No Credit Card Required',
      content: `Explore the platform and start conversations with creators at no cost.`
    },
    {
      title: 'Discover Private Creators You Won’t Find Elsewhere',
      content: `Engage with unique, shy creators who prefer a more personal and discreet platform.`
    },
    {
      title: 'Exclusive, U.S.-Based Creators',
      content: `Enjoy content and connections with women who value privacy and cater to your specific interests.`
    }
  ];

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    if(value){
      const cleaned = value.replace(/\D/g, '');
      
      // Format according to phone number length
      const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
      
      if (match) {
        const formattedNumber = `${match[1]}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
        return formattedNumber.trim();
      }
    }
    return value;
  };
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, '');
  
    // Limit the input to 10 digits
    if (cleaned.length <= 10) {
      const formattedPhone = formatPhoneNumber(cleaned);
      setPhone(formattedPhone);
    }
  };
  const showError = (message) => {
    toast.error(message);
    toast.clearWaitingQueue();
  };

  const handleSubmit = () => {
    const params = {
      name: name,
      email: email,
      password: password,
      userType: "Consumer",
      mobileNumber: phone.replace(/\D/g, '')
    };
    if (!phone || phone.trim() === "") {
      showError("Please enter your phone number");
      return;
    }
    let validate = Validate.dynamicValidationNew("RegisterConsumer", params);
    if (validate !== "") {
      showError(validate);
      return;
    }
    const regex = /^[^@]+$/;
    if(!regex.test(name)){
      showError("Username should not contain the '@' character");
      return;
    }
    if (!phone || phone.trim() === "") {
      showError("Please enter your phone number");
      return;
    }
    
    // Optional: Validate phone number format (e.g., must be digits only and of a certain length)
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phone.replace(/\D/g, ''))) {
      showError("Please enter a valid phone number with 10 digits.");
      return;
    }

    if (!confirm) {
      showError("Please select Age confirmation");
      return;
    }
    if (!secondConfirm) {
      showError("Please select local, state, and federal laws");
      return;
    }
    if (!thirdConfirm) {
      showError("Please select terms and conditions");
      return;
    }

    dispatch(
      saveData({
        action: SignUp,
        definition: "RegisterConsumer",
        data: params,
      })
    ).then((r) => {
      if (r === 1) {
        NavigateTo(`${AppRoute.Search_Profile}/${state?.id}`);
        //NavigateTo(AppRoute.PAYMENT);
        // NavigateTo(AppRoute.REGIS_SUCCESS, true);
      }
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className="benfits-block">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Benefits</h1>
          </div>
          <div className="creator-benefits new-data">
            <Accordion data={benefitData} />
          </div>
          <div className="creator-benefits cusmr-bnfts-top-info">
            
            <div className="form-block cusmr-bnft-form">
            <div className="new-user">
              Already have an Account?{" "}
              <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login here</span>
            </div>
            <form className="reg-horizontal-line">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Username - Not Real Name"
                  onChange={onNameChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={onEmailChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Pick a Strong Password"
                    onChange={onPasswordChange}
                  />
                  {!showPassword ? (
                    <span
                      className="hide-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <span
                      className="show-pass"
                      onClick={() => setShowPassword(!showPassword)}
                    ></span>
                  )}
                </div>
              </div>
              <div className="agelaw">
                <span className="confirm">
                  <input
                    type="checkbox"
                    id="eighteenplus"
                    name="eighteenplus"
                    onChange={() => {
                      setConfirm(!confirm);
                    }}
                    checked={confirm}
                  />
                  <label for="eighteenplus">
                    Click to confirm you are at least 18 years of age
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="laws"
                    name="laws"
                    onChange={() => {
                      setSecondConfirm(!secondConfirm);
                    }}
                    checked={secondConfirm}
                  />
                  <label for="laws">
                    Click to confirm you are responsible for following all
                    local, state, and federal laws
                  </label>
                </span>

                <span className="confirm">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    onChange={() => {
                      setThirdConfirm(!thirdConfirm);
                    }}
                    checked={thirdConfirm}
                  />
                  <label for="terms">
                    Click to confirm that by using our site you agree to all{" "}
                    <a target="_blank" href="/terms_conditions">
                      Terms and Conditions
                    </a>
                  </label>
                </span>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleSubmit}
              />
             {/* <div className="new-user">
                Already a User?{" "}
                <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login</span>
              </div> */ }
            </form>
          </div>
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BenefitScreen;
