export const setStorage = (key, data) => {
  return localStorage.setItem(key, data);
};

export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const setStorageJson = (key, data) => {
  return setStorage(key, JSON.stringify(data));
};

export const getStorageJson = (key) => {
  let data = getStorage(key);
  try {
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
};

export const removeStorage = (key) => {
  return localStorage.removeItem(key);
};
