import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getData, saveData } from "../../utils/api/apiHelper";
import { Images } from "../../themes";
import { AppRoute } from "../../router/routerConstants";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import {
  LoadSubscription,
  // BuySubscription,
  CancelSubscription,
} from "../../utils/api/actionConstants";
import { useSubscription } from "../../hooks/useStore";
import { getDateShow, IsSubscriptionValid } from "../../utils/Common";
import FullScrenLoader from "../../components/FullScreenLoader";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ManageSubscriptions = () => {
  const [show, setShow] = useState(false);
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let subscription = useSubscription();

  useEffect(() => {
    dispatch(
      getData({
        action: LoadSubscription,
      })
    );
  }, []);

  const buySubscription = () => {
    setLoading(true);
    NavigateTo(AppRoute.PAYMENT);
  };

  const cancelSubscription = () => {
    dispatch(
      saveData({
        action: CancelSubscription,
      })
    ).then((r) => {
      setShow(false);
    });
  };

  let nextRenewal = getDateShow(subscription.validTill);
  let subsNotExpired = IsSubscriptionValid(subscription.validTill);
  return (
    <div className="wrapper">
      <FullScrenLoader loading={loading} />
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="manage-subscription-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Manage Subscription</h1>
            </div>
            <div className="manage-subscription-inner">
              <div className="plan-wrap">
                <div className="paln-top">
                  <div className="plan-left-block">
                    <div className="plan-heading">
                      <h2>$1</h2>
                      <span>/ month</span>
                    </div>
                    <p>Premium Plan</p>
                  </div>
                  {subscription ? (
                    subscription?.id &&
                    subscription?.isCanceled === false &&
                    subsNotExpired ? (
                      <span className="active-btn">Active</span>
                    ) : (
                      <span className="">InActive</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {subscription?.id &&
                  subscription.isCanceled === false &&
                  subsNotExpired === false && (
                    <div className="plan-bottom">
                      <p>
                        Subscription Plan Renews on
                        <span>
                          {nextRenewal?.month} {nextRenewal?.day} ,
                          {nextRenewal?.year}
                        </span>
                      </p>
                    </div>
                  )}
                {subscription?.id && subscription?.isCanceled === true && (
                  <div className="plan-bottom">
                    <p>Subscription Canceled</p>
                  </div>
                )}
              </div>
              {/* 
              {subscription?.id &&
              subsNotExpired &&
              subscription?.isCanceled === false ? (
                <div className="btn-block">
                  <div
                    className="btn-inner"
                    onClick={(e) => {
                      setShow(true);
                    }}
                  >
                    <span className="submit">Cancel Subscription</span>
                    <div className="btn-loader" />
                  </div>
                </div>
              ) : (
                <div className="btn-block">
                  <div className="btn-inner" onClick={buySubscription}>
                    {subscription?.id &&
                    (subscription?.isCanceled === true ||
                      subsNotExpired === false) ? (
                      <span className="submit">Renew Subscription</span>
                    ) : (
                      <span className="submit">
                        {loading ? "Loading" : "Buy Subscription"}
                      </span>
                    )}
                    <div className="btn-loader" />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {show && (
        <div className="modal-wrapper">
          <div className="model-inner delete-model">
            <div className="model-box">
              <h3>Cancel Subscription</h3>
              <figure className="delete-icon">
                <img src={Images.cancel_subscription} alt="delete" />
              </figure>
              <div className="delet-content">
                Are you sure, want to Cancel your
                <strong>$1</strong> Subscription Plan?
              </div>
              <div className="btn-block">
                <span
                  className="reset-btn"
                  onClick={(e) => {
                    setShow(false);
                  }}
                >
                  Not, now
                </span>
                <div
                  className="btn-inner"
                  onClick={(e) => {
                    cancelSubscription();
                  }}
                >
                  <span className="submit">Yes, sure</span>
                  <div className="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageSubscriptions;
