import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { AppRoute } from "../../../router/routerConstants";
import { LoadAllBanks, DeleteBanks } from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useBanks } from "../../../hooks/useStore";
import { getData, deleteData } from "../../../utils/api/apiHelper";
import { SetDefaultBank } from "../../../utils/api/actionConstants";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { saveData } from "../../../utils/api/apiHelper";

const ManageBanks = () => {
  const [NavigateTo] = useCustomNavigate();
  const [seletedBank, setSelectedBank] = useState("");
  const dispatch = useDispatch();
  let banks = useBanks();

  useEffect(() => {
    getBanks();
  }, []);

  const getBanks = () => {
    dispatch(
      getData({
        action: LoadAllBanks,
        data: { offset: 0, limit: 20 },
      })
    );
  };

  const deleteBank = () => {
    if (!seletedBank) return "";
    dispatch(
      deleteData({
        action: DeleteBanks,
        data: { id: seletedBank },
      })
    ).then((r) => {
      setSelectedBank("");
    });
  };

  const getLast4Digit = (value) => {
    if (!value || value.length < 4) return "";
    return value.substr(value.length - 4, 4);
  };

  const setDefault = (id) => {
    dispatch(
      saveData({
        action: SetDefaultBank,
        data: { id: id },
      }) 
    ).then((r) => {
      getBanks();
    });
  };

  const viewSelectedBank = (bid) => {
    NavigateTo("/creator/editbank/"+ bid);
  }

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="add-money-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Manage Bank</h1>
            </div>
            <div className="manage-card-block">
              {banks &&
                banks.length > 0 &&
                banks.map((v, k) => {
                  return (
                    <div className="add-money-account" key={k}>
                      <div className="add-money-account-top">
                        <i>
                          <img src={Images.bank_icon} alt="card_icon" />
                        </i>
                        <h5>{v.bankName}</h5>
                        <i
                          className="edit-icon"
                          onClick={() => viewSelectedBank(v.id)}
                        >
                          <img src={Images.edit_icon} alt="edit" />
                        </i>
                        <i
                          className="trash-icon"
                          onClick={() => setSelectedBank(v.id)}
                        >
                          <img src={Images.trash} alt="trash" />
                        </i>
                      </div>
                      <div className="card-detail">
                        <div className="card-holder-name">
                          <label>Account Holder Name</label>
                          <h4>{v.holderName}</h4>
                        </div>
                        <label>Account Holder Number</label>
                        <div className="card-number-block">
                          <p className="card-number">
                            <span className="hide"> ● ● ● ●</span>
                            <span className="hide"> ● ● ● ●</span>
                            <span className="hide"> ● ● ● ●</span>
                            <span className="show">
                              {getLast4Digit(v.acountNumber)}
                            </span>
                          </p>
                          <div className="select-cotent">
                            <input
                              onChange={() => !v.isDefault && setDefault(v.id)}
                              type="checkbox"
                              name="select"
                              checked={v.isDefault}
                            />
                            <label />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div
                className="btn-block"
                onClick={() => NavigateTo(AppRoute.AddBank)}
              >
                <span className="submit">Add New Bank</span>
                <div className="btn-loader" />
              </div>
            </div>
            <div className="loader-wrap">
              <div className="loader" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {seletedBank && seletedBank.length > 0 && (
        <div className="modal-wrapper">
          <div className="model-inner delete-model">
            <div className="model-box">
              <h3>Delete Bank</h3>
              <figure className="delete-icon">
                <img src={Images.ic_delete_account} alt="delete" />
              </figure>
              <div className="delet-content">
                Are you sure, you want to delete your <strong>Bank? </strong>
              </div>
              <div className="btn-block">
                <span className="reset-btn" onClick={() => setSelectedBank("")}>
                  No
                </span>
                <div className="btn-inner" onClick={() => deleteBank()}>
                  <span className="submit">Yes</span>
                  <div className="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageBanks;
