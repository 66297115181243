import { AppRoute } from "../router/routerConstants";
import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { getStorage, getStorageJson } from "../utils/localStorage";

const ConsumerTab = ({ activeTab = 0 }) => {
  const [NavigateTo] = useCustomNavigate();

  const tabChanged = (index) => {
    switch (index) {
      case 0:
        NavigateTo(AppRoute.CONS_Search);
        break;
      case 1:
        NavigateTo(AppRoute.CONS_FOLDER);
        break;
      case 2:
        NavigateTo(AppRoute.Chat);
        break;
      case 3:
        NavigateTo(AppRoute.Cons_Profile);
        break;
      default:
        break;
    }
  };

  return (
    <div className="chat-bar">
      <ul>
        <li>
          <span
            href="#"
            className={activeTab === 0 && "active"}
            onClick={() => tabChanged(0)}
          >
            <img src={Images.tab_search_deactive} alt="" />
          </span>
        </li>
        <li>
          <span
            href="#"
            className={activeTab === 1 && "active"}
            onClick={() => tabChanged(1)}
          >
            <img src={Images.notification_icon} alt="" />
          </span>
        </li>
        <li>
          <span
            href="#"
            className={activeTab === 2 && "active"}
            onClick={() => tabChanged(2)}
          >
            <img src={Images.chat_icon} alt="" />
          </span>
        </li>
        <li>
          <span
            href="#"
            className={activeTab === 3 && "active"}
            onClick={() => tabChanged(3)}
          >
            <img src={Images.profile_icon} alt="" />
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ConsumerTab;
