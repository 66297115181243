import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Images } from "../themes";
import { AppRoute } from "../router/routerConstants";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { getData } from "../utils/api/apiHelper";
import { getStaticPage } from "../utils/api/actionConstants";
import * as DOMPurify from "dompurify";

const Help = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const staticPages = useSelector((state) => state.staticPage);

  const pageData = staticPages ? staticPages["Help"] : {};

  const bb = pageData?.body;

  useEffect(() => {
    dispatch(
      getData({
        action: getStaticPage,
        data: {
          id: "Help",
        },
      })
    );
  }, []);
  return (
    <div className="wrapper">
      <Header showLogout={true} />

      <div className="wrapper">
        <div className="container">
          <div className="setting-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>{pageData?.title}</h1>
            </div>

            <div style={{ flex: "column" }}>
              <div>
                {
                  <div
                    className="static-content"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(bb),
                    }}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Help;
