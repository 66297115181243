import { useEffect, useState, useRef } from "react";
import { saveData, getData } from "../../utils/api/apiHelper";
import { useSelector, useDispatch } from "react-redux";
import { updateUserData } from "../../redux/userReducer";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { UpdateCreator, GetTags } from "../../utils/api/actionConstants";
import FullScreenLoader from "../../components/FullScreenLoader";
import * as CommonFunc from "../../utils/Common";
import backBtn from '../../assets/images/back-btn.png'
import Header from "../../components/Header";
import TagsInput from "../../components/TagsInput";
import { hairColorList, bodytype, ethnicity } from "../../utils/Constants";
import Footer from "../../components/Footer";
import { AppRoute } from "../../router/routerConstants";


const MyInformationSettings = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [NavigateTo] = useCustomNavigate();
    const [heading, setHeading] = useState("");
    const [aboutMe, setAboutme] = useState("");
    const [zip, setZip] = useState("");
    const userData = useSelector((state) => state.user.userData);
    const [age, setAge] = useState("18");
    const [feet, setFeet] = useState("");
    const [inch, setInch] = useState("");
    const [selectedBodyType, setSelectedBodyType] = useState("DEFAULT");
    const [selectedEthnicity, setSelectedEthnicity] = useState("DEFAULT");
    const [hairColor, setHairColor] = useState("DEFAULT");
    const [tags, setTags] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const topRef = useRef(null);

    useEffect(() => {
        topRef.current?.focus();
    }, []);
    const tagsList = useSelector((state) => {
        return state.creator.tags;
      });
    
      const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        if(value){
            const cleaned = value.replace(/\D/g, '');
            
            // Format according to phone number length
            const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
            
            if (match) {
                const formattedNumber = `${match[1]}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
                return formattedNumber.trim();
            }
        }
        return value;
    };
    const handlePhoneChange = (e) => {
        const { value } = e.target;
        
        // Remove all non-digit characters
        const cleaned = value.replace(/\D/g, '');
      
        // Limit the input to 10 digits
        if (cleaned.length <= 10) {
          const formattedPhone = formatPhoneNumber(cleaned);
          setPhone(formattedPhone);
        }
    };
    useEffect(() => {
        setHeading(userData.heading);
        setAboutme(userData.aboutMe);
        setZip(userData.zipcode);
        setName(userData.name);
        setPhone(formatPhoneNumber(userData.mobileNumber));
        setEmail(userData.email);
        setPassword(userData.password);

        dispatch(getData({ action: GetTags }));
        setAge(userData.age);
        setSelectedBodyType(
          userData.bodyType == "" ? "DEFAULT" : userData.bodyType
        );
        setSelectedEthnicity(
          userData.ethnicity == "" ? "DEFAULT" : userData.ethnicity
        );
        setHairColor(userData.hairColor == "" ? "DEFAULT" : userData.hairColor);
        setTags(userData.tags);
    
        const ht = userData.height;
    
        if (ht) {
          const htsplit = ht.split(".");
    
          setFeet(htsplit[0] ?? "");
          setInch(htsplit[1] ?? "");
        }
      }, []);

      const handleChange = (e) => {
        e.preventDefault();
        setSelectedBodyType(e.target.value);
      };
    
      const handleEthnicityChange = (e) => {
        e.preventDefault();
        setSelectedEthnicity(e.target.value);
      };
      const handleHairColorChange = (e) => {
        e.preventDefault();
        setHairColor(e.target.value);
      };

      const handleContinue = (e) => {
        e.preventDefault();
        if (!heading || heading == "") {
          CommonFunc.showError("Heading is mandatory");
          return;
        }
    
        if (!aboutMe || aboutMe == "") {
          CommonFunc.showError("About me is mandatory");
          return;
        }
    
        if (!zip || zip == "") {
          CommonFunc.showError("Zipcode is mandatory.");
          return;
        }

        if(!name || name == ""){
            CommonFunc.showError("Username is mandatory.");
            return;
        }
        const regex = /^[^@]+$/;
        if(!regex.test(name)){
            CommonFunc.showError("Username should not contain the '@' character");
            return;
        }

        if(!email || email == ""){
            CommonFunc.showError("Email is mandatory.");
            return;
        }
        if (!phone || phone.trim() === "") {
            CommonFunc.showError("Please enter your phone number");
            return;
        }
        
        // Optional: Validate phone number format (e.g., must be digits only and of a certain length)
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(phone.replace(/\D/g, ''))) {
            CommonFunc.showError("Please enter a valid phone number with 10 digits.");
            return;
        }
        /*if(!password || password == ""){
            CommonFunc.showError("Password is mandatory.");
            return;
        }*/

        if (!age || age == "") {
            CommonFunc.showError("Please enter your age");
            return;
        }
      
          if (age < parseInt(18, 10)) {
            CommonFunc.showError("Age must be greater than or equal to 18 years.");
            return;
          }
          
          if (!feet || feet == "") {
            CommonFunc.showError("Please enter your height in feet and inches");
            return;
          }
      
          if (!inch || inch == "") {
            CommonFunc.showError("Please enter your height in feet and inches");
            return;
          }
      
          if (!selectedBodyType || selectedBodyType == "DEFAULT") {
            CommonFunc.showError("Please select Body type");
            return;
          }
          if (!selectedEthnicity || selectedEthnicity == "DEFAULT") {
            CommonFunc.showError("Please select Ethnicity");
            return;
          }
      
          if (!hairColor || hairColor == "DEFAULT") {
            CommonFunc.showError("Please select hair color");
            return;
          }
        
        const params = {
            heading: heading,
            aboutMe: aboutMe,
            zipcode: zip,
            age: age,
            bodyType: selectedBodyType,
            ethnicity: selectedEthnicity,
            hairColor: hairColor,
            height: feet + "." + inch,
            tags: tags,
            name: name,
            email: email,
            mobileNumber: phone.replace(/\D/g, ''),
            password: password,
            isMyInfoCompleted: true

        };
       
       dispatch(updateUserData(params));
        const payload = {
          action: UpdateCreator,
          data: params,
        };
        dispatch(saveData(payload)).then((r) => {
          if (r === 1) {
            CommonFunc.showSuccess("User data updated successfully");
            NavigateTo(AppRoute.Profile);
          }
        });
      };

    return (
        <>
        <div className="wrapper" >
            <Header showLogout={true} />
            <FullScreenLoader loading={loading} />
            <div className="container">
                <div className="request-purchased-wrap">
                    <div className="heading-block">
                        <span className="back-btn" onClick={() => NavigateTo(-1)}><img src={backBtn} alt="back-btn"/></span>
                    </div>
                    <div className="page-heading-block text-center">
                        <h2>My Information Settings</h2>
                    </div>
                    <div className="form-block">
                    <form>  
                        <div className="all-input">
                            <div className="form-inner-holder">
                                <div className="form-group">
                                    <input ref={topRef}
                                        type="text"
                                        placeholder="Catchy Phrase or Sentence"
                                        value={heading}
                                        onChange={(event) => setHeading(event.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        placeholder="Enticing Description of yourself"
                                        defaultValue={""}
                                        value={aboutMe}
                                        onChange={(event) => setAboutme(event.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={zip}
                                        placeholder="Zip code"
                                        onChange={(event) => setZip(event.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="form-inner-holder">
                                <div className="form-group upe">
                                    <div className="row">
                                        <div className="col-3">
                                            <h3>Username</h3>
                                        </div>    
                                        <div className="col-7">
                                                <input
                                                type="text"
                                                value={name}
                                                placeholder="Username - Not Real Name"
                                                onChange={(event) => setName(event.target.value)}
                                                />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group upe">                           
                                <div className="row">
                                    <div className="col-3">
                                        <h3>Password</h3>
                                    </div>     
                                    <div className="col-7">
                                        
                                            <input
                                            type="password"
                                            value={password}
                                            placeholder="Change Password Here"
                                            onChange={(event) => setPassword(event.target.value)}
                                            />
                                        
                                    </div>
                                </div>
                                </div>
                                <div className="form-group upe">
                                    <div className="row">
                                        <div className="col-3">
                                            <h3>Email</h3>
                                        </div>    
                                        <div className="col-7">
                                            
                                                <input
                                                type="text"
                                                value={email}
                                                placeholder="Email"
                                                onChange={(event) => setEmail(event.target.value)}
                                                />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group upe">
                                    <div className="row">
                                        <div className="col-3">
                                            <h3>Phone</h3>
                                        </div>    
                                        <div className="col-7">
                                            
                                                <input
                                                type="text"
                                                value={phone}
                                                placeholder="Phone"
                                                onChange={handlePhoneChange}
                                                />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-inner-holder">
                            <div className="form-group">
                                <input
                                type="number"
                                value={age}
                                placeholder="Age"
                                onBlur={() => {
                                    if (age < parseInt(18, 10)) {
                                    setAge("18");
                                    }
                                }}
                                min={18}
                                onChange={(event) => setAge(event.target.value)}
                                />
                            </div>

                            <div className="row">
                                <div className="col-6">
                                <div className="form-group">
                                    <input
                                    type="number"
                                    value={feet}
                                    max={10}
                                    min={0}
                                    placeholder="Height(Feet)"
                                    onChange={(event) => setFeet(event.target.value)}
                                    />
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="form-group">
                                    <input
                                    type="number"
                                    value={inch}
                                    max={12}
                                    min={0}
                                    placeholder="Height(Inch)"
                                    onChange={(event) => setInch(event.target.value)}
                                    />
                                </div>
                                </div>

                                <div className="col-6">
                                <div className="form-group">
                                    <select
                                    name="Body Type"
                                    id="body_type"
                                    value={selectedBodyType}
                                    onChange={handleChange}
                                    >
                                    <option value="DEFAULT" disabled hidden>
                                        Select Body Type
                                    </option>
                                    {bodytype.map((r) => {
                                        return (
                                        <option key={r.value} value={r.value}>
                                            {r.label}
                                        </option>
                                        );
                                    })}
                                    </select>
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="form-group">
                                    <select
                                    name="Ethnicity"
                                    id="ethnicity"
                                    value={selectedEthnicity}
                                    onChange={handleEthnicityChange}
                                    >
                                    <option value="DEFAULT" disabled hidden>
                                        Select Ethnicity
                                    </option>
                                    {ethnicity.map((r) => {
                                        return (
                                        <option key={r.value} value={r.value}>
                                            {r.label}
                                        </option>
                                        );
                                    })}
                                    </select>
                                </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <select
                                name="Hair Color"
                                id="hairColor"
                                value={hairColor}
                                onChange={handleHairColorChange}
                                >
                                <option value="DEFAULT" disabled hidden>
                                    Select Hair Color
                                </option>
                                {hairColorList.map((r) => {
                                    return (
                                    <option key={r.value} value={r.value}>
                                        {r.label}
                                    </option>
                                    );
                                })}
                                </select>
                            </div>

                            <div className="form-group">
                                <TagsInput
                                onChange={(tags) => setTags(tags)}
                                selectedTags={tags}
                                tagsList={[]}
                                />
                            </div>
                            <div className="upload-text">
                                <small>Add tags to tell users what you like, and what you're into.
                                (seperated by commas or enter button)</small>
                            </div>
                            </div>
                        </div>
                        <input
                            className="submit"
                            type="button"
                            defaultValue="Save"
                            onClick={handleContinue}
                        />
                    </form>
                    </div>
                </div>
            </div>
            <Footer/>
            </div>
        </>
    );
}

export default MyInformationSettings;