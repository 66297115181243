import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { AppRoute } from "../router/routerConstants";
import { useFreeProfiles } from "../hooks/useStore";
import { getData } from "../utils/api/apiHelper";
import { LoadFreeProfiles } from "../utils/api/actionConstants";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getStorage, getStorageJson } from "../utils/localStorage";
import BlurryLoadingImage from "../components/BlurryLoadingImage";

const ConsumerOnboarding = () => {
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  const freeProfiles = useFreeProfiles();

  useEffect(() => {
    dispatch(
      getData({
        action: LoadFreeProfiles,
      })
    );
  }, []);
 /* const onContinue = () => {
    if (isUserLogin()) {
      NavigateTo(AppRoute.PAYMENT, true);
    } else {
      // NavigateTo(AppRoute.LOGIN, true);
      NavigateTo(AppRoute.REGISTER, true);
    }
  }; */

  const onWomenContinue = () => {
    if (isUserLogin()) {
      NavigateTo(AppRoute.PAYMENT, true);
    } else {
      NavigateTo(AppRoute.BENEFITS, false);
    }
  };

  const onMenContinue = () => {
    NavigateTo(AppRoute.CONS_Search, true);
    /*if (isUserLogin()) {
      NavigateTo(AppRoute.PAYMENT, true);
    } else {
      NavigateTo(AppRoute.CBENEFITS, false);
    }*/
  };

  const isUserLogin = () => {
    const token = getStorage("token");
    const user = getStorageJson("user");

    if (token && user && user.roleId) {
      return true;
    }
    return false;
  };

  const profile_arr = (freeProfiles ?? []).filter((r) => r.isApproved != 2);

  return (
    <div className="wrapper ls-wrapper">
      <Header showLogout={false} />
      <div className="container">
        <div className=" consumer-onboarding-wrap">
          <div className="logo-block hide-on-mob">
            <span>
              <img src={Images.header_logo} />
            </span>
          </div>
          <div className="show-on-mob">
              <div className="btn-block" onClick={onMenContinue}>
                <span className="submit">
                  {isUserLogin() ? "Show Me The Women" : "Show Me The Women"}
                </span> 
                <div className="btn-loader"></div>
              </div>
              <div className="creator-benefits">
            <div className="my-ac-form-info-ls landing-page-txt">
              <div className="form-inner-holder consumer-info-text1">
                <div className="text-center"><p>Exclusive custom content made just for you - only at <a href=".">Arouzed.com</a></p> </div>
              </div>
            </div>
          </div>
          </div>
          
          <div className="gallery-img mob-margin-off">
            <div className="uploded-imges">
              <div className="row">
                {profile_arr &&
                  profile_arr.length > 0 &&
                  profile_arr.map((v, k) => {
                    return (
                      <div className="col-6" key={k}>
                        <div className="inner-uploaded">
                          <figure>
                            <BlurryLoadingImage
                              isBlur={v.isBlur}
                              profilePic={v.profilePic}
                            />
                          </figure>
                          {/*<span className="text-type-in">{v.age}</span> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="creator-benefits hide-on-mob">
            <div className="my-ac-form-info-ls landing-page-txt">
              <div className="form-inner-holder consumer-info-text1">
                <div className="text-center"><p>Exclusive custom content made just for you - only at <a href=".">Arouzed.com</a></p> </div>
              </div>
            </div>
          </div>
          <div className="consumer-onboarding-bottom">
            {/*<div className="btn-block" onClick={onContinue}>
              <span className="submit">
                {isUserLogin() ? "Continue" : "Continue"}
              </span>
              <div className="btn-loader"></div>
                </div> */ }
              <div className="btn-block" onClick={onWomenContinue}>
              <span className="submit">
                {isUserLogin() ? "Become a Creator " : "Become a Creator "}
              </span>
              <div className="btn-loader"></div>
              </div>

              <div className="btn-block hide-on-mob" onClick={onMenContinue}>
              <span className="submit">
                {isUserLogin() ? "Show Me The Women" : "Show Me The Women"}
              </span> 
              <div className="btn-loader"></div>
              </div>

              <div className="landing-page-new-user">
                Already have an Account?{" "}
                <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login here</span>
              </div>
          </div>
          <div className="loader-wrap">
            <div className="loader"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ConsumerOnboarding;
