import { Images } from "../../../themes";

import { useDispatch } from "react-redux";
import { getData, deleteData } from "../../../utils/api/apiHelper";
import { saveData } from "../../../utils/api/apiHelper";
import {
  LoadAllBanks,
  SetDefaultBank,
} from "../../../utils/api/actionConstants";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function RenderBanks(props) {
  const dispatch = useDispatch();

  const getLast4Digit = (value) => {
    if (!value || value.length < 4) return "";
    return value.substr(value.length - 4, 4);
  };

  const getBanks = () => {
    dispatch(
      getData({
        action: LoadAllBanks,
        data: { offset: 0, limit: 20 },
      })
    );
  };

  const setDefault = (v) => {
    props.setSelected(v);

    dispatch(
      saveData({
        action: SetDefaultBank,
        data: { id: v.id },
      })
    ).then((r) => {
      getBanks();
    });
  };

  useEffect(() => {
    props.Banks.forEach((r) => {
      if (r.isDefault) {
        props.setSelected(r);
      }
    });
  }, [props.Banks]);

  return (
    <>
      {props.Banks && props.Banks.length > 0 ? (
        props.Banks.map((v, k) => (
          <div className="add-money-account" key={k}>
            <div className="add-money-account-top">
              <i>
                <img src={Images.bank_icon} alt="card_icon" />
              </i>
              <h5>{v.bankName}</h5>
            </div>
            <div className="card-detail">
              <div className="card-holder-name">
                <label>Account Holder Name</label>
                <h4>{v.holderName}</h4>
              </div>
              <label>Account Holder Number</label>
              <div className="card-number-block">
                <p className="card-number">
                  <span className="hide"> ● ● ● ●</span>
                  <span className="hide"> ● ● ● ●</span>
                  <span className="hide"> ● ● ● ●</span>
                  <span className="show">
                    {getLast4Digit(v.acountNumber)}
                  </span>
                </p>
                <div className="select-cotent">
                  <input
                    onChange={() => !v.isDefault && setDefault(v)}
                    type="checkbox"
                    name="select"
                    checked={v.isDefault}
                  />
                  <label />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="add-money-account">
          <p>No bank record found. Please add a bank account before proceed
            <Link to="/creator/managebank"> here.</Link>
          </p>
        </div>
      )}
    </>
  );
}
