const ReportUser = ({ onSelect, onSubmit, modalVisibility,creatorUser }) => {

  let reportData = [
    "Report Spam",
    "Report Illegal Content",
    "Report Fraud",
    "Report Fake Account",
    "Report Fake Content",
    "Other",
  ];
  if(creatorUser){
    reportData = [
      "Harassment / Bullying",
      "Non Paying Member",
      "Spam",
      "Other",
    ];
  }

  const hideModal = () => {
    if (modalVisibility) {
      modalVisibility();
    }
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const Options = () => {
    const onSelection = (reason) => {
      if (onSelect) {
        onSelect(reason);
      }
    };

    return reportData.map((r) => {
      return (
        <div className="radio-select">
          <input
            type="radio"
            name="select-one"
            onChange={() => onSelection(r)}
          />
          <label>{r}</label>
        </div>
      );
    });
  };

  return (
    <div className="modal-wrapper" onClick={hideModal}>
      <div className="model-inner" onClick={handleChildClick}>
        <div className="model-box">
          <h3>Select One </h3>
          <form>
            <div className="form-group">{Options()}</div>
            <div className="modal-btn-block">
              <div className="btn-block">
                <input
                  type="button"
                  className="submit"
                  defaultValue="Continue"
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportUser;
