export const AppRoute = {
  SPLASH: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  BENEFITS: "/benefits",
  CBENEFITS: "/consumerBenefits",
  RESETPASS: "/resetPassword",
  FORGOTPASS: "/forgotPassword",
  REGIS_SUCCESS: "/registrationSuccess",
  OTPVerification: "/otp-verification",
  SETUP: "/setup",
  PrivacyPolicy: "/privacy_policy",
  TermsConditions: "/terms_conditions",

  //Creator
  FOLDER: "/creator/folder",
  Folder_Files: "/creator/folder_files",
  Creator_Upload_Files: "/creator/:folderName/upload-files",
  Upload_Files: "/creator/upload_files",
  Search_Profile: "/creator/profile",
  Profile: "/creator/account",
  StateExclusion : "/creator/stateExclussion",
  MyInformation: "/creator/myinformation",
  PicVideos: "/creator/pictureandvideo",
  ProfileImages: "/creator/profileimages",
  AutomatedMessage: "/creator/automated-message",
  PrivateIntroductoryFolder: "/creator/privateintroductoryfolder",
  
  // CreatorHome: "/chat",
  CreatorHome: "/creator/account",
  ManageBank: "/creator/managebank",
  AddBank: "/creator/addbank",
  EditBank: "/creator/editbank",
  ManageAddress: "/creator/manageaddress",
  AddAddress: "/creator/addaddress",
  EditAddress: "/creator/editaddress",
  Crea_Withdraw: "/creator/walletwithdraw",
  Crea_WithdrawPage: "/creator/walletwithdraw_page",
  Withdrawal_request: "/creator/withdrawal_request",
  Reports: "/reports",

  //Consumer
  CONS_FOLDER: "/consumer/folder",
  CONS_FILES: "/consumer/files",
  CONS_Search: "/consumer/search",
  Cons_Home: "/consumer/search",
  Cons_Profile: "/consumer/profile",
  Cons_Wallet: "/wallet",
  Cons_Edit_Profile: "/consumer/profile/edit",
  Cons_Cards: "/consumer/cards",
  Favorites: "/consumer/favorites",
  Cons_Subscription: "/consumer/cards",
  Cons_AddCard: "/consumer/add_card",

  PAYMENT: "/payment",
  SubscriptionSuccess: "/subscription-success",

  AddBillingAddress: "/consumer/add_billing_address",
  Billing_Address: "/consumer/billing_address",

  Cons_AddMoney: "/consumer/addmoney",
  Cons_subscribe: "/subscribe",
  UnSubscribed: "/unsubscribed",

  Settings: "/settings",
  Subscriptions: "/subscription",
  PaymentHistory: "/payment_history",
  ChangePassword: "/changepassword",
  Chat: "/chat",
  ChatMessage: "/chat/message",

  // report
  Earnings: "/earnings",
  MonthlyEarningsList: "/reports/monthly-earning",
};
