import { GET, POST, PUT, DELETE, UPLOAD } from "./Api";
import * as AllApis from "./apiRoutes";
import { toast } from "react-toastify";
import * as Validate from "../validatorXerox";
import { replaceValues } from "../Common";

const showError = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export const getData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  if (data?.data?.id) {
    url = url + "/" + data?.data?.id;
  }

  if (data.data) {
    const params = data.data;
    let str = "";
    Object.keys(params).forEach((r) => {
      str += `${r}=${params[r]}&`;
    });

    url = url + "?" + str;
  }

  //   dispatch({ type: action + "Loading", payload: true });
  return GET(url).then((r) => {
    // dispatch({ type: action + "Loading", payload: false });
    if (r.status && r.status === 1) {
      dispatch({ type: action, payload: r.data });
      if (data.sendAll) {
        return r.data;
      }
      return 1;
    } else {
      // error etc.
      showError(r && r.message);
      return 0;
    }
  });
};

export const fetchReportData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  url = prepareUrl(url, data.data);

  return GET(url).then((r) => {
    dispatch({ type: action + "Loading", payload: false });
    if (r.status && r.status === 1) {
      dispatch({
        type: action,
        payload: {
          query: data.data,
          data: r.data,
        },
      });
      if (data.sendAll) {
        return r.data;
      }
      return 1;
    } else {
      // error etc.
      return 0;
    }
  });
};

export const saveData =
  (data, back = false) =>
  async (dispatch) => {
    let action = data.action;
    let url = AllApis[action];
    if (!url) {
      return "with error";
    }
    // data * url parser
    // Validation.
    if (data.definition) {
      let validate = Validate.dynamicValidationNew(data.definition, data.data);
      if (validate !== "") {
        showError(validate);
        return Object.assign({}, data.data, validate);
      }
    }
  console.log(url,"url")
    return POST(url, data.data).then((r) => {
      console.log(r,">>>>>>>>>>>")
      if (r.status && r.status === 1) {
        // if(action === "SubscribeToCreator"){
        //   return r
        // }
        dispatch({ type: action, payload: r.data });
        if (back) return r.data;
        return 1;
      } else {
        
        showError(r && r.message);
        if (action === "SubscribeToCreator") {
          return r;
        }
        if(typeof r.response !== "undefined" && r.response.data){
          return r.response.data;
        }
        
        return r;
      }
    });
  };

export const PostData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }
  // data * url parser
  // Validation.
  if (data.definition) {
    let validate = Validate.dynamicValidationNew(data.definition, data.data);
    if (validate !== "") {
      showError(validate);
      return Object.assign({}, data.data, validate);
    }
  }

  return POST(url, data.data).then((r) => {
    if (r.status && r.status === 1) {
      return r.data;
    } else {
      showError(r && r.message);
      return 0;
    }
  });
};

export const updateData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }
  // data * url parser.

  if (data.definition) {
    let validate = Validate.dynamicValidationNew(data.definition, data.data);
    if (validate !== "") {
      return Object.assign({}, data.data, validate);
    }
  }

  return PUT(url, data.data).then((r) => {
    showError(r && r.message);
    if (r.code && r.code === 200) {
      dispatch({ type: action, payload: r.data });
      return 1;
    } else {
      return 0;
    }
  });
};

export const deleteData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  if (data.definition) {
    let validate = Validate.dynamicValidationNew(data.definition, data.data);
    if (validate !== "") {
      return Object.assign({}, data.data, validate);
    }
  }
  // data * url parser.
  // Mustache can be used for that.

  if (!data.data.id) {
    return 0;
  }

  url = url + "/" + data?.data?.id;

  return DELETE(url).then((r) => {
    showError(r && r.message);
    if (r.status && r.status === 1) {
      dispatch({ type: action, payload: data.data });
      return 1;
    } else {
      return 0;
    }
  });
};

export const saveFormData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  // data * url parser.
  // Mustache can be used for that.
  console.log(url,"url")
  return UPLOAD(url, data.data).then((r) => {
    if (r.status && r.status === 1) {
      return r.data;
    } else {
      showError(r && r.message);
      return r;
    }
    // if (r.code && r.code === 200) {
    //   return r.data;
    // } else {
    //   return r;
    // }
  });
};

const prepareUrl = (url, data) => {
  if (url.indexOf("{{") !== -1) {
    url = replaceValues(url, data);
  }
  return url;
};

export const showErrorInUI = showError;
