import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { AppRoute } from "../../../router/routerConstants";
import { LoadAllCards, DeleteCards } from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useCards, useUser } from "../../../hooks/useStore";
import { getData, deleteData } from "../../../utils/api/apiHelper";
import { SetDefaultCard } from "../../../utils/api/actionConstants";
import { saveData } from "../../../utils/api/apiHelper";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const Managecard = () => {
  const [NavigateTo] = useCustomNavigate();
  const [seletedCard, setSelectedCard] = useState("");
  const [cardName, setCardName] = useState("");
  const dispatch = useDispatch();
  let cards = useCards();
  let user = useUser();

  useEffect(() => {
    getCards();
  }, []);

  const getCards = () => {
    dispatch(
      getData({
        action: LoadAllCards,
        data: { offset: 0, limit: 20 },
      })
    );
  };

  const deleteCard = () => {
    dispatch(
      deleteData({
        action: DeleteCards,
        data: { id: seletedCard },
      })
    ).then((r) => {
      setSelectedCard("");
    });
  };

  const setDefault = (id) => {
    dispatch(
      saveData({
        action: SetDefaultCard,
        data: { id: id },
      })
    ).then((r) => {
      getCards();
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="add-money-wrap">
          <div className="heading-block">
            <span className="back-btn" onClick={() => NavigateTo(-1)}>
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>Manage Card</h1>
          </div>
          <div className="manage-card-block">
            {cards &&
              cards.length > 0 &&
              cards.map((v, k) => {
                return (
                  <div className="add-money-account" key={k}>
                    <div className="add-money-account-top">
                      <i>
                        <img src={Images.ic_add_card} alt="card_icon" />
                      </i>
                      <h5>{v.name}</h5>
                      <i
                        className="trash-icon"
                        onClick={() => {
                          setCardName(v.name);
                          setSelectedCard(v.id);
                        }}
                      >
                        <img src={Images.trash} alt="trash" />
                      </i>
                    </div>
                    <div className="card-detail">
                      <div className="card-holder-name">
                        <label>Card Holder Name</label>
                        <h4>{user.name}</h4>
                      </div>
                      <label>Card Number</label>
                      <div className="card-number-block">
                        <p className="card-number">
                          <span className="hide"> ● ● ● ●</span>
                          <span className="hide"> ● ● ● ●</span>
                          <span className="hide"> ● ● ● ●</span>
                          <span className="show">{v.number}</span>
                        </p>
                        <div className="select-cotent">
                          <input
                            onChange={() => !v.isDefault && setDefault(v.id)}
                            type="checkbox"
                            name="select"
                            checked={v.isDefault}
                          />

                          <label />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div
              className="btn-block"
              onClick={() => NavigateTo(AppRoute.Cons_AddCard)}
            >
              <span className="submit"> Add New Card</span>
              <div className="btn-loader" />
            </div>
          </div>
          <div className="loader-wrap">
            <div className="loader" />
          </div>
        </div>
      </div>
      <Footer />
      {seletedCard && seletedCard.length > 0 && (
        <div className="modal-wrapper">
          <div className="model-inner delete-model">
            <div className="model-box">
              <h3>Delete Card</h3>
              <figure className="delete-icon">
                <img src={Images.ic_delete_card} alt="delete" />
              </figure>
              <div className="delet-content">
                Are you sure, want to delete your <strong>{cardName}? </strong>
              </div>
              <div className="btn-block">
                <span className="reset-btn" onClick={() => setSelectedCard("")}>
                  Not, now
                </span>
                <div className="btn-inner" onClick={() => deleteCard()}>
                  <span className="submit">Yes, sure</span>
                  <div className="btn-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Managecard;
