import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserData } from "../redux/userReducer";
import { getStorage, getStorageJson } from "../utils/localStorage";

import { AppRoute } from "../router/routerConstants";
import { IsUserSubscribed } from "../utils/Common";

function OnlyCreator({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = getStorage("token");
  const user = getStorageJson("user");
  dispatch(updateUserData(user));

  const IsSubscribed = () => {
    const user = getStorageJson("user");
    return IsUserSubscribed(user);
  };

  if (token) {
    if (user.role != "Creator") {
      if (IsSubscribed()) {
        return <Navigate to={AppRoute.CONS_Search} />;
      } else {
        return <Navigate to={AppRoute.PAYMENT} />;
      }
    } else {
      if (!user.isSetupComplete && location.pathname != "/setup") {
        return <Navigate to={AppRoute.SETUP} />;
      }
    }
  }
  return children;
}

export default OnlyCreator;
