const ImageMessage = (message, currentUser, show,openLightbox) => {
  const url = message.message;
  const handleClick = () => {
    if (message.message) {
      openLightbox(message.message);
    }
  };
  return (
    <div
      className={`${
        currentUser ? "sent-msg" : "receive-msg"
      } more-content img-receive`}
    >
      <img src={url} alt=""  onClick={handleClick}/>

      {!currentUser && (
        <>
          <button
            className="more-content savepay pointer"
            onClick={(e) => show(message)}
          >
            Save
          </button>
        </>
      )}
    </div>
  );
};
export default ImageMessage;
