// Login Constants
export const LoginUser = "user/login";
export const GetUserData = "user";

// Signup Constants
export const SignUp = "user/register";
export const VerifyOtp = "user/verify-otp";
export const LoadSubscription = "user/subscription";
export const BuySubscription = "user/subscription";
export const CancelSubscription = "user/subscription/cancel";

// ForgetPassword Constants
export const SendEmail = "user/resetpassword-start";
export const ResetPassword = "user/resetpassword";
export const UpdatePassword = "user/updatePassword";

// UploadFile constants
export const UploadFile = "upload";

// creator subscription
export const SubscribeToCreator = "consumer/subscribe-creator";

// Billing Address
export const LoadAllBillingAddress = "billing-address/";
export const DeleteBillingAddress = "billing-address";
export const AddBillingAddress = "billing-address/add";

//Update profile const
export const updateCreator = "user/update-creator";
export const updateConsumer = "user/update-consumer";
export const updateUserpics = "user/update-userpics";
export const UpdateIsBlur = "user/updateblur";
export const UpdateIsNotification = "user/update-notification";

// creator
export const ProfileView = "creator/profile-view";
export const LoadFolder = "creator/list-folder";
export const FetchFolderFiles = "creator/list-content";
export const AddContent = "creator/add-content";
export const SearchCreator = "creator/search";
export const CreatorProfile = "creator";
export const UpdateFav = "user/favorite/";
export const GetFav = "user/favorite/";
export const GetTags = "tags";
export const Cons_CreateFolder_Creator= "creator/create-folder";
export const SetPrice = "user/private-folder-price/add";
export const Cons_CreateFolder_Registration = "creator/create-folder"
export const Get_Cons_CreateFolder_Creator = "creator/list-all-folder"
export const Delete_Folder = "creator/delete-folder"
export const Delete_Content = "creator/delete-content"
// consumer
export const Cons_LoadFolder = "consumer/list-folder";
export const Cons_LoadFolderFiles = "consumer/list-content";
export const Cons_CreateFolder = "consumer/create-folder";
export const IsConsumerActive = "consumer/is-valid";


// chat
export const MakePaymentRequest = "chat/message";
export const CreateChat = "chat";

export const LoadChats = "chat";
export const LoadChatMessage = "chat/message";
export const ReadAllChatMessage = "chat/message/read";
export const ChatBlockMember = "chat/message/block";
export const UnReadAllChatMessage = "chat/count-unread";
export const SaveMedia = "consumer/add-content";
export const ReportUser = "user/report";

// wallet
export const LoadWallet = "user/wallet";
export const LoadCurrentWalletBalance = "user/wallet/current";
export const CheckIfSubscriptionAllowed = "user/subscription-allowed";

// card
export const LoadAllCards = "user/cards";
export const DeleteCards = "user/cards";
export const AddCards = "user/cards/add";
export const SetDefaultCard = "user/cards/setdefault";

// banks
export const LoadAllBanks = "user/banks";
export const DeleteBanks = "user/banks";
export const AddBanks = "user/banks/add";
export const LoadBankData = "user/banks/edit";
export const UpdateBank = "user/banks/update";
export const SetDefaultBank = "user/banks/setdefault";

// address
export const LoadAllAddress = "user/address";
export const DeleteAddress = "user/address";
export const AddAddress = "user/address/add";
export const LoadAddressData = "user/address/edit";
export const UpdateAddress = "user/address/update";
export const SetDefaultAddress = "user/address/setdefault";

// payment
export const MakePayment = "payment";
export const MakeWithDrawal = "payment/withdrawal";
export const ListWithDrawal = "payment/withdrawal";
export const LoadPaymentHistory = "user/subscription-history";

//static pages
export const getStaticPage = "admin/staticPages";
export const StaticData = "static/default";

// reports
export const LoadFreeProfiles = "user/free-creator";
export const LoadCreatorReports = "reports/creator?action={{action}}";
export const LoadCreatorReportsLimit =
  "reports/creator?action={{action}}&offset={{offset}}&&limit={{limit}}&startDate={{startDate}}&endDate={{endDate}}";

export const ComingSoonApi = "user/comingsoon";
