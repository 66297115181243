import React, { useEffect, useState } from "react";
import { Images } from "../../../themes";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { FetchFolderFiles,Delete_Content } from "../../../utils/api/actionConstants";
import { getData,deleteData } from "../../../utils/api/apiHelper";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Lightbox from "../../../components/Lightbox";
import ReactPlayer from "react-player";
import DeletePopup from "../../../components/DeletePopup";

const FolderFiles = () => {
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [contentId, setContentId] = useState();
  const [flag, setFlag] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const folder = useSelector((state) => {
    if (flag === 0) {
      return [];
    } else {
      return state?.folder?.folderFiles;
    }
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { state } = useLocation();
  useEffect(() => {
    dispatch(
      getData({
        action: FetchFolderFiles,
        data: { folderId: state?.id, offset: 0, limit: 80 },
      })
    )
      .then((resp) => {
        setFlag(1);
      })
      .catch((err) => {
        setFlag(0);
      });
  }, []);

  const navigate = () => {
    NavigateTo(
      `/creator/${state?.name.replace(/ /g, "-")}/upload-files`,
      false,
      { id: state?.id, name: state?.name }
    );
  };

  const isFromChat = state.from === "chat";

  const folderFiles = folder;

  const isFileSeelected = (file) => {
    const r_file = selectedFiles.filter((r) => r.id === file.id);

    return r_file.length > 0;
  };
  const media= folderFiles.map((v, k) => {
    if(v.type ==='video'){
      return  { url: v.source, type: v.type };
    }
    return  { url: v.source, type:"photo" };
      
    
  })
  const openLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const deletePop = (id) =>{
    setDeleteModal(true);
    setContentId(id)
  }
  const deleteContent = () =>{
    setDeleteModal(false);
    dispatch(
        deleteData({
          action: Delete_Content,
          data: { id: contentId },
        })
      ).then((r) => {
        
      });

  }
  const hideModal = () =>{
    setDeleteModal(false);

  }
  const ShowList = () => {
    return (
      folderFiles.length > 0 &&
      folderFiles.map((v, k) => {
        if (v.type === "video") {
          return (
            <div className="col-6" key={k}>
              <div className="inner-uploaded files-to-edit-del">
                {/* <iframe src={v.source} frameBorder="0"></iframe> */}
                {/* <img src={Images.photo_video} alt="" /> */}
                <div className="delete-icon">
                  <button  className="delete-folder" onClick={(e) => deletePop( v.id)}>x</button>
                </div>
                <div className="image-box">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url={v.source}
                  controls
                  
                  config={{
                    file: {
                      attributes: {
                        controlsList: " nodownload  ",
                      },
                    },
                  }}
                />
                </div>
                {/* <video controls>
                    <source src={v.source} type="video/ogg" />
                    Your browser does not support the video tag.
                  </video> */}
                {isFromChat && (
                  <div className="select-cotent">
                    <input
                      type="checkbox"
                      name="select"
                      checked={isFileSeelected(v)}
                      onClick={(e) => selectFiles(e, k)}
                    />
                    <label />
                  </div>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="col-6" key={k}>
              <div
                className="inner-uploaded files-to-edit-del"
                onClick={(e) => selectFiles(e, k)}
              >
                
                 <div className="delete-icon ">
                  <button className="delete-folder" onClick={(e) => deletePop(v.id)}>x</button>
                </div>
                <div className="image-box">
                <figure>
               
                  <img src={v.source} alt=""  onClick={() => openLightbox(k)}/>
                </figure>
                </div>
                {isFromChat && (
                  <div className="select-cotent">
                    <input
                      type="checkbox"
                      name="select"
                      checked={isFileSeelected(v)}
                    />
                    <label />
                  </div>
                )}
              </div>
            </div>
          );
        }
      })
      
    );
  };

  const selectFiles = (e, index) => {
    const sel_file = folderFiles[index];

    let tempselectedFiles = selectedFiles ?? [];
    const file = tempselectedFiles.filter((r) => r.id === sel_file.id);

    if (file.length > 0) {
      tempselectedFiles = tempselectedFiles.filter((r) => r.id !== sel_file.id);
    } else {
      tempselectedFiles = [...tempselectedFiles, sel_file];
    }
    setSelectedFiles(tempselectedFiles);
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className=" setup-profile-wrap photo-video-wrap full-pages">
          <div className="heading-block">
            <span onClick={() => NavigateTo(-1)} className="back-btn">
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>{state?.name}</h1>
            {isFromChat && (
              <span className="upload-link" onClick={navigate}>
                <img src={Images.uploadLink} alt="" />
              </span>
            )}
          </div>

          <p>
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. */}
          </p>
          <div className="gallery-img">
            <div className="uploded-imges">
              <div className="row">{folderFiles && ShowList()} 
              {lightboxOpen && (
                <Lightbox media={media} startIndex={currentIndex} onClose={closeLightbox} />
              )}
              
              </div>
            </div>
          </div>
          {isFromChat ? (
            <div className="bottom-bar">
              <div className="left-block">
                <h4>{selectedFiles.length}</h4>
                <p>Files selected</p>
              </div>
              <div
                className="right-block"
                onClick={() => {
                  dispatch({
                    type: "UpdateSelectedFiles",
                    payload: { files: selectedFiles, status: true },
                  });

                  NavigateTo(-2);
                }}
              >
                <span>Send</span>
                <div className="btn-loader" />
              </div>
            </div>
          ) : (
            <>
              <span className="message-btn" onClick={navigate}>
                <img src={Images.messageIcon} alt="" />
              </span>
            </>
          )}
        </div>
      </div>
      {deleteModal && (
        <DeletePopup
          hideModal={hideModal}
          deletefunc={deleteContent}
          message={"You want to delete this file"}
        />
      )}
      <Footer />
    </div>
  );
};

export default FolderFiles;
