import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { LoadCreatorReports } from "../../../utils/api/actionConstants";
import { fetchReportData } from "../../../utils/api/apiHelper";
import { useReports } from "../../../hooks/useStore";

import ShowReportRow from "../../../components/ShowReportRow";
import MonthYearFilter from "../../../components/MonthYearFilter";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const EarningsList = () => {
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const reports = useReports();
  const localtion = useLocation();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const filterVisibility = (status) => {
    setShowFilter(status);
  };

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadCreatorReports,
        data: {
          action: "walletEntries",
          limit: 20,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (year && month) {
      dispatch(
        fetchReportData({
          action: LoadCreatorReports,
          data: {
            action: "distinctMonthlyWalletEntries",
            passedMonth: year + "-" + month,
          },
        })
      );
    }
  }, [dispatch, month, year]);

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="request-purchased-wrap">
            <div className="heading-block">
              <span className="back-btn">
                <img
                  src={Images.backImg}
                  alt="back-btn"
                  onClick={() => NavigateTo(-1)}
                />
              </span>
              <h1>Lifetime Earning</h1>
            </div>
            <div className="earning-block">
              <div className="earning-inner">
                <div className="left-block">
                  <h5>Lifetime Earning</h5>
                  <h2>${localtion?.state?.lifeEarning || 0}</h2>
                </div>
                <div className="right-block">
                  <figure>
                    <img src={Images.save_money} alt="back-btn" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="filter-block">
              <div className="filter-inner">
                <h3>March 2022</h3>
                <span className="fiter-icon">
                  <img
                    src={Images.filter_icon}
                    alt="filter icon"
                    onClick={() => filterVisibility(true)}
                  />
                </span>
              </div>
            </div>
            <div className="view-request-items">
              {month && year ? (
                <ShowReportRow rows={reports.distinctMonthlyWalletEntries} />
              ) : (
                <ShowReportRow rows={reports.walletEntries} />
              )}
            </div>
            {showFilter && (
              <MonthYearFilter
                onSubmit={(e) => {
                  setMonth(e.month);
                  setYear(e.year);
                  setShowFilter(false);
                }}
                modalVisibility={filterVisibility}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EarningsList;
