import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserData } from "../redux/userReducer";
import { getStorage, getStorageJson } from "../utils/localStorage";

import { AppRoute } from "../router/routerConstants";
import { IsUserSubscribed } from "../utils/Common";

function RouteAfterLogin({ children }) {
  const dispatch = useDispatch();
  const token = getStorage("token");
  const user = getStorageJson("user");
  dispatch(updateUserData(user));

  const IsSubscribed = () => {
    const user = getStorageJson("user");
    return IsUserSubscribed(user);
  };

  if (token) {
    if (user.role === "Creator") {
      if (user.isSetupComplete) {
        return <Navigate to={AppRoute.CreatorHome} />;
      } else {
        return <Navigate to={AppRoute.SETUP} />;
      }
    } else {
    
        return <Navigate to={AppRoute.CONS_Search} />;
    }
  }
  return children;
}

export default RouteAfterLogin;
