import { Images } from "../../themes";
import { useEffect, useState } from "react";
import { AppRoute } from "../../router/routerConstants";
import { SendEmail } from "../../utils/api/actionConstants";
import { saveData } from "../../utils/api/apiHelper";
import { useDispatch } from "react-redux";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ForgotPassword = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      saveData({
        action: SendEmail,
        definition: "SendEmail",
        data: {
          email: email,
        },
      })
    ).then((r) => {
      if (r === 1) {
        navigate(AppRoute.RESETPASS, { state: { email: email } });
      }
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container small-container">
        <div className="forgot-pass-wrap">
          <div className="heading-block">
            <span href="#" className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Forgot Password</h1>
          </div>
          <div className="form-block">
            <form>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={onEmailChange}
                />
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
