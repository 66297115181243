import { Images } from "../../../themes";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { AppRoute } from "../../../router/routerConstants";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ConsumerTab from "../../../components/ConsumerTab";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUser } from "../../../hooks/useStore";

import { GetFav } from "../../../utils/api/actionConstants";
import { getData, PostData, saveData } from "../../../utils/api/apiHelper";
import InfiniteScroll from "react-infinite-scroll-component";
import BlurryLoadingImage from "../../../components/BlurryLoadingImage";
import socket from "../../../socket/socket";

import { UpdateFav } from "../../../utils/api/actionConstants";

const Faourites = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const favList = useSelector((state) => {
    return state.creator.favList;
  });

  const currentUser = useUser();

  useEffect(() => {
    socket.connect();
    socket.emit("sign-in", currentUser);
  }, []);

  useEffect(() => {
    dispatch(getData({ action: GetFav }));
  }, []);

  const profileClkd = (id) => {
    dispatch({ type: "ResetProfile" });
    NavigateTo(`${AppRoute.Search_Profile}/${id}`);
  };

  const favClkd = (e, id) => {
    e.stopPropagation();
    dispatch(
      saveData({
        action: UpdateFav,
        data: {
          userId: id,
        },
      })
    ).then((resp) => {
      if (resp == 1) {
        dispatch(getData({ action: GetFav }));
      }
    });
  };

  const CreatorProfileComponent = () => {
    if (favList.length === 0) {
      return <div>No User found.</div>;
    }
    return favList.map((row, index) => {
      return (
        <div className="col-6" onClick={(e) => profileClkd(row.id)} key={index}>
          <div className="inner-uploaded">
            <a>
              <span
                className={`fav-btn active`}
                onClick={(e) => favClkd(e, row.id)}
              ></span>
            </a>
            <figure>
              <BlurryLoadingImage
                profilePic={row.profilePic ? row.profilePic : null}
                isBlur={row.isBlur}
              />
              {/* <img src={row.profilePic ? row.profilePic : null} alt="" /> */}
            </figure>
            { /*<span className="text-type-in">{row.age}</span>*/} 
          </div>
        </div>
      );
    });
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className=" setup-profile-wrap search-wrap favourite-icon">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Favorites</h1>
          </div>

          <div id="scrollableDiv" className="gallery-img">
            <div className="uploded-imges">
              <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={favList.length} //This is important field to render the next data
                // next={() => fetchData({}, false, offset)}

                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <div className="row">{CreatorProfileComponent()}</div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faourites;
