import Images from "../themes/Images";
import React, { useEffect, useState, useRef } from "react";

const ChooseFolder = ({ folderList = [], cancel, save }) => {
  const [selectedFolder, setSelectedFolder] = useState();
  const showFolders = () => {
    return folderList.map((row) => {
      return (
        <div className="video-photo-filter-item">
          <div className="left-block">
            <figure className="icon">
              <img src={Images.video_image_folder} alt="" />
            </figure>
            <label htmlFor="photos">{row.name}</label>
          </div>
          <div className="select-cotent">
            <input
              type="checkbox"
              name="select"
              onClick={() => setSelectedFolder(row.id)}
              checked={row.id == selectedFolder}
            />
            <label />
          </div>
        </div>
      );
    });
  };
  return (
    <div className="search-filter-slide video-photo-filter">
      <div className="search-filter-slide-inner">
        <h2>Choose Folder</h2>
        <div className="video-photo-filter-block">
          {showFolders()}
          <div className="btn-block">
            <span className="reset-btn" onClick={() => cancel()}>
              Cancel
            </span>
            <div className="btn-inner">
              <span
                className="submit"
                onClick={() => {
                  selectedFolder && save(selectedFolder);
                }}
              >
                Save
              </span>
              <div className="btn-loader" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseFolder;
