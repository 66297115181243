import { useUser } from "../../../hooks/useStore";
import { Images } from "../../../themes";
import { useDispatch } from "react-redux";
import { getData, deleteData } from "../../../utils/api/apiHelper";
import { saveData } from "../../../utils/api/apiHelper";
import {
  LoadAllAddress,
  SetDefaultAddress,
} from "../../../utils/api/actionConstants";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function RenderAddress(props) {
  let user = useUser();
  const dispatch = useDispatch();

  const getAddress = () => {
    dispatch(
      getData({
        action: LoadAllAddress,
        data: { offset: 0, limit: 20 },
      })
    );
  };

  const setDefault = (v) => {
    props.setSelected(v);
    dispatch(
      saveData({
        action: SetDefaultAddress,
        data: { id: v.id },
      })
    ).then((r) => {
      getAddress();
    });
  };

  useEffect(() => {
    props.Address.forEach((r) => {
      if (r.isDefault) {
        props.setSelected(r);
      }
    });
  }, [props.Address]);

  return (
    <>
      {props.Address && props.Address.length > 0 ? (
        props.Address.map((v, k) => (
            <div className="add-money-account" key={k}>
              <div className="add-money-account-top">
                <i>
                  <img src={Images.address_icon} alt="card_icon" />
                </i>
                <h5>{v.name}</h5>
              </div>
              <div className="card-detail">
                <div className="card-holder-name address-item">
                  <label>{user.name}</label>
                  <label>
                    {v.lane1}, {v.lane2}, {v.city}, {v.state}, {v.zipcode},{" "}
                    {v.country}
                  </label>
                </div>
                <div className="card-number-block single-content-radio">
                  <div className="select-cotent">
                    <input
                      onChange={() => !v.isDefault && setDefault(v)}
                      type="checkbox"
                      name="select"
                      checked={v.isDefault}
                    />
                    <label />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="add-money-account">
            <p>No address record found. Please add an address before proceed
              <Link to="/creator/manageaddress"> here.</Link>
            </p>
          </div>
        )}
    </>
  );
}
