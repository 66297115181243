import { useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { AddAddress } from "../../../utils/api/actionConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { saveData } from "../../../utils/api/apiHelper";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import * as CommonFunc from "../../../utils/Common";

const NewAddress = () => {
  const [NavigateTo] = useCustomNavigate();
  const [Name, setName] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zipcode, setZipcode] = useState("");
  const [Country, setCountry] = useState("");

  const dispatch = useDispatch();

  const AddAddressFunc = (e) => {
    e.preventDefault();
    dispatch(
      saveData({
        action: AddAddress,
        data: {
          name: Name,
          lane1: Address1,
          lane2: Address2,
          city: City,
          state: State,
          zipCode: Zipcode,
          country: Country
        },
      })
    ).then((r) => {
      CommonFunc.showSuccess("Address details added successfully");
      setName("");
      setAddress1("");
      setAddress2("");
      setState("");
      setZipcode("");
      setCountry("");
      NavigateTo(-1);
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="add-new-address-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Add New Address</h1>
            </div>
            <div className="add-new-address-inner">
              <form onSubmit={(e) => AddAddressFunc(e)}>
                <div className="form-group">
                  <input
                    type="text"
                    value={Name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your  Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={Address1}
                    required
                    onChange={(e) => setAddress1(e.target.value)}
                    placeholder="Address Line 1"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={Address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    placeholder="Address Line 2"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={City}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={State}
                    required
                    onChange={(e) => setState(e.target.value)}
                    placeholder="State"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={Zipcode}
                    required
                    onChange={(e) => setZipcode(e.target.value)}
                    placeholder="Zipcode"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={Country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Country"
                  />
                </div>
                <p className="info">
                  Above address will be used to send check while withdrawing
                  money from wallet, if selected method to withdraw is check
                  payment
                </p>
                <div className="btn-block">
                  <input type="submit" className="submit" defaultValue="Save" />
                  <div className="btn-loader" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewAddress;
