// Login/Signup
export const LoginUser = "LoginUser";
export const LogOutUser = "LogOutUser";
export const GetUserData = "GetUserData";
export const SignUp = "SignUp";
export const VerifyOtp = "VerifyOtp";
export const SendEmail = "SendEmail";
export const ResetPassword = "ResetPassword";
export const UpdatePassword = "UpdatePassword";
export const UploadFile = "UploadFile";


// subscription
export const LoadSubscription = "LoadSubscription";
export const BuySubscription = "BuySubscription";
export const CancelSubscription = "CancelSubscription";

export const SubscribeToCreator = "SubscribeToCreator";
export const UpdateCreatorProfileSubscribed = "UpdateCreatorProfileSubscribed";

//Update
export const UpdateConsumer = "updateConsumer";
export const UpdateCreator = "updateCreator";
export const UpdateUserpics = "updateUserpics";
export const UpdateIsBlur = "UpdateIsBlur";
export const UpdateIsNotification = "UpdateIsNotification";

// creator
export const ProfileView = "ProfileView";
export const LoadFolder = "LoadFolder";
export const FetchFolderFiles = "FetchFolderFiles";
export const AddContent = "AddContent";
export const SearchCreator = "SearchCreator";
export const Creator_Profile = "CreatorProfile";
export const SetFilter = "SetFilter";
export const GetTags = "GetTags";
export const UpdateFav = "UpdateFav";
export const GetFav = "GetFav";
export const SetPrice = "SetPrice";
export const Cons_CreateFolder_Creator = "Cons_CreateFolder_Creator";
export const Cons_CreateFolder_Registration = "Cons_CreateFolder_Registration"
export const Get_Cons_CreateFolder_Creator = "Get_Cons_CreateFolder_Creator"
export const Delete_Folder = "Delete_Folder"
export const Delete_Content = "Delete_Content"
// consumer
export const Cons_LoadFolder = "Cons_LoadFolder";
export const Cons_LoadFolderFiles = "Cons_LoadFolderFiles";
export const Cons_CreateFolder = "Cons_CreateFolder";
export const IsConsumerActive = "IsConsumerActive";


// chat
export const UnReadAllChatMessage = "UnReadAllChatMessage";
export const CreateChat = "CreateChat";
export const LoadChats = "LoadChats";
export const LoadChatMessage = "LoadChatMessage";
export const ReadAllChatMessage = "ReadAllChatMessage";
export const ChatBlockMember = "ChatBlockMember";
export const UpdateCurrentChat = "UpdateCurrentChat";
export const UpdateCurrentChatRead = "UpdateCurrentChatRead";

export const AddMessage = "AddMessage";
export const UpdateMessage = "UpdateMessage";
export const UpdatePaymentMessage = "UpdatePaymentMessage";

export const UpdateOnline = "UpdateOnline";
export const UpdateOffline = "UpdateOffline";
export const MakePaymentRequest = "MakePaymentRequest";
export const SaveMedia = "SaveMedia";
export const ReportUser = "ReportUser";

// wallet
export const LoadWallet = "LoadWallet";
export const LoadCurrentWalletBalance = "LoadCurrentWalletBalance";

// cards
export const LoadAllCards = "LoadAllCards";
export const DeleteCards = "DeleteCards";
export const AddCards = "AddCards";
export const SetDefaultCard = "SetDefaultCard";

// banks
export const LoadAllBanks = "LoadAllBanks";
export const DeleteBanks = "DeleteBanks";
export const AddBanks = "AddBanks";
export const UpdateBank = "UpdateBank";
export const LoadBankData = "LoadBankData";
export const SetDefaultBank = "SetDefaultBank";

// address
export const LoadAllAddress = "LoadAllAddress";
export const DeleteAddress = "DeleteAddress";
export const AddAddress = "AddAddress";
export const UpdateAddress = "UpdateAddress";
export const LoadAddressData = "LoadAddressData";
export const SetDefaultAddress = "SetDefaultAddress";

// billing address
export const LoadAllBillingAddress = "LoadAllBillingAddress";
export const DeleteBillingAddress = "DeleteBillingAddress";
export const AddBillingAddress = "AddBillingAddress";

// payment
export const MakePayment = "MakePayment";
export const MakeWithDrawal = "MakeWithDrawal";
export const ListWithDrawal = "ListWithDrawal";
export const LoadPaymentHistory = "LoadPaymentHistory";

export const getStaticPage = "getStaticPage";
export const StaticData = "StaticData";

export const CheckIfSubscriptionAllowed = "CheckIfSubscriptionAllowed";

// load profiles
export const LoadFreeProfiles = "LoadFreeProfiles";
export const LoadCreatorReports = "LoadCreatorReports";
export const LoadCreatorReportsLimit = "LoadCreatorReportsLimit";

export const ComingSoonApi = "ComingSoonApi";
