import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../../themes";
import { LoadCreatorReports, LoadAllCards, AddCards, BuySubscription, LoadFreeProfiles } from "../../utils/api/actionConstants";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { useCards, useUser, useSubscription, useFreeProfiles } from "../../hooks/useStore";
import { saveData, getData } from "../../utils/api/apiHelper";
import { getDateShow } from "../../utils/Common";
import { useReports } from "../../hooks/useStore";
import { fetchReportData } from "../../utils/api/apiHelper";
import BlurryLoadingImage from "../../components/BlurryLoadingImage";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import NewCard from "../consumer/wallet/NewCard";
import { AppRoute } from "../../router/routerConstants";
import FullScreenLoader from "../../components/FullScreenLoader";
const ChooseCard = () => {
  const [NavigateTo] = useCustomNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let cards = useCards();
  let subscription = useSubscription();
  let user = useUser();
  const reports = useReports();
  const freeProfiles = useFreeProfiles();
  
  useEffect(() => {
    NavigateTo(AppRoute.CONS_Search);
  }, []);
  useEffect(() => {
    dispatch(
      getData({
        action: LoadFreeProfiles,
      })
    );
  }, []);

  const profile_arr = (freeProfiles ?? []).filter((r) => r.isApproved != 2);

  useEffect(() => {
    getCards();
  }, []);

  const wait =  (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getCards = () => {
    setLoading(true);
    dispatch(
      getData({
        action: LoadAllCards,
        data: { offset: 0, limit: 20 },
      })
    ).then((resp) => {
      setLoading(false);
    });
  };

  const makeSubscribtion = (obj) => {
    setLoading(true);
    let data;
    if (cards && cards.length > 0) {
      data = {
        card: cards[0],
      };
    } else {
      data = obj;
    }

    dispatch(
      saveData({
        action: BuySubscription,
        data: data,
      })
    ).then((r) => {
      if (r == 1) {
        NavigateTo(AppRoute.CONS_Search);
      }
      setLoading(false);
    });
  };

  const addCard = (obj) => {
    setLoading(true);
    dispatch(
      saveData({
        action: AddCards,
        data: obj,
      })
    ).then(async(r) => {
      //console.log(r.status);
     if(r.status===0){
     // console.log(1);
      setLoading(false);
     }else{
      //console.log("This line will execute immediately");
      // Wait for 15 seconds
      await wait(15000);
      setLoading(false);
      // Code to execute after 15 seconds
     // console.log("This line will execute after 15 seconds");
      getCards();
     }
    }).catch((e) =>  {

    });
  };

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadCreatorReports,
        data: {
          action: "activeCreator",
        },
      })
    );
  }, [dispatch]);

  /* if (loading) {
    return (
      <Suspense>
        <div className="wrapper">
          <Header showLogout={true} />
          <div className="wrapper">
            <div className="container">
              <div className="manage-subscription-wrap">
                <div className="heading-block">
                  <h1>loading...</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  } */

  let nextRenewal = getDateShow(subscription?.validTill);
  return (
    
    <div className="wrapper">
      <Header showLogout={true} />
      <FullScreenLoader loading={loading} />
      {(!subscription ||
        !subscription.hasOwnProperty("id") ||
        subscription?.isCanceled === true) && (
        <div className="container">
          <div className="add-money-wrap consumer-onboarding-wrap">
            <div className="heading-block">
              <h1>Arouzed.com</h1>
            </div>
            { profile_arr && profile_arr.length > 0 && (
              <div className="gallery-img">
                <div className="uploded-imges">
                  <div className="row">
                    { profile_arr.map((v, k) => {
                     // console.log(v,"hello world")
                        return (
                        //   <div className="col-6" key={k}>
                        //   <div className="inner-uploaded">
                        //     <figure>
                        //       <BlurryLoadingImage
                        //         isBlur={v.isBlur}
                        //         profilePic={v.profilePic}
                        //       />
                        //     </figure>
                        //     <span className="text-type-in">{v.age}</span>
                        //   </div>
                        // </div>
                          <div className="col-6" key={k}>
                            <div className="inner-uploaded">
                              <figure>
                                <BlurryLoadingImage
                                  isBlur={v.isBlur}
                                  profilePic={v.profilePic}
                                />
                              </figure>
                              <span className="text-type-in">{v.age}</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
              <div className="creator-benefits">
            <h3>Member Benefits</h3>
            <ul>

              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Exclusive content from unique creators
              </li>

              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Access to women not available on other platforms
              </li>

              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Custom content created just for you
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Search and filter to find just the right girls
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Only $1/month + 10% Platform Fee - Cancel anytime
              </li>
            </ul>
            <p><b>Why Arouzed charges a Platform Fee</b></p>
            <p>All platforms charge platform fees of typically 20%, they just hide it by charging the creator who passes the cost onto you. Here, we only charge 10% and instead of hiding it, we tell you upfront. This allows us to pay out Creators 100% of their earnings, which is very attractive for Creators.</p>
            <ul>

              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Browse around for just $1.00
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Find a girl you like? You can spend more if you want.
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Don't like what you see? More creators are signing up every day.
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Custom adult content made just for you by amateur content creators, available exclusively to Arouzed.com Members.
              </li>
              
            </ul>
           
          </div>
            {/*
            <div className="creator-benefits payment-benefits-list">
            <ul>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Discover Exclusive Content From Unique Creators
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Access Women Not Available on Other Platforms
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Enjoy Personalized, One-on-One Experiences
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Get Custom Content Tailored to Your Desires
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Interact Directly with Creators for a More Personalized Experience
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Explore a Diverse Range of Content Catered Specifically to You
              </li>
              <li>
                <i className="list-icon">
                  <img src={Images.list_icom} />
                </i>
                Only $1 per Month + 10% of Your Spend - Cancel Anytime
              </li>
            </ul>
          </div> */ }

            <div className="manage-card-block">
              {cards &&
                cards.length > 0 &&
                cards.map((v, k) => {
                  //console.log(v)
                  return (
                    <div className="add-money-account" key={k}>
                      <div className="add-money-account-top">
                        <i>
                          <img src={Images.ic_add_card} alt="card_icon" />
                        </i>
                        <h5>{v.name}</h5>
                      </div>
                      <div className="card-detail">
                        { /*<div className="card-holder-name">
                          <label>Card Holder Name</label>
                          <h4>{user.name}</h4>
                        </div> */ }
                        <label>Card Number</label>
                        <div className="card-number-block">
                          <p className="card-number">
                            <span className="hide"> ● ● ● ●</span>
                            <span className="hide"> ● ● ● ●</span>
                            <span className="hide"> ● ● ● ●</span>
                            <span className="show">{v.number}</span>
                          </p>
                        </div>
                      </div>
                      <div className="btn-block">
                        <input
                          type="button"
                          onClick={makeSubscribtion}
                          value="Upgrade membership"
                          className="submit"
                        />
                        <div className="btn-loader"></div>
                      </div>
                    </div>
                  );
                })}
              {cards && cards.length === 0 && <NewCard addCard={addCard} />}
            </div>
            <div className="loader-wrap">
              <div className="loader" />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ChooseCard;
