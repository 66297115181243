import { useNavigate } from "react-router-dom";
export function useCustomNavigate() {
  const navigate = useNavigate();
  const NavigateTo = (path, replace = false, state = {}) => {
    if (replace) {
      navigate(path, { replace: true }, { state: state });
    } else {
      navigate(path, { state: state });
    }
  };

  return [NavigateTo];
}

export default useCustomNavigate;
