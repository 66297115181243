import { AppRoute } from "../router/routerConstants";
import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";

const CreatorTab = ({ activeTab = 0 }) => {
  const [NavigateTo] = useCustomNavigate();
  const tabChanged = (index) => {
    switch (index) {
      case 0:
        NavigateTo(AppRoute.Chat);
        break;
      case 1:
        NavigateTo(AppRoute.FOLDER);
        break;
      case 2:
        NavigateTo(AppRoute.Reports);
        break;
      case 3:
        NavigateTo(AppRoute.Profile);
        break;
      case 4:
        NavigateTo(AppRoute.TermsConditions);
        break;
      default:
        break;
    }
  };

  return (
    <div className="chat-bar">
      <ul>
      <li>
          <span
            href="#"
            className={activeTab === 3 ? "active" : ""}
            onClick={() => tabChanged(3)}
          >
            <img src={Images.profile_icon} alt="" />
            <h5>My Account</h5>
          </span>
        </li>
        <li>
          <span
            href="#"
            className={activeTab === 0 ? "active" : ""}
            onClick={() => tabChanged(0)}
          >
            <img src={Images.chat_icon} alt="" />
            <h5>Private Messages</h5>
          </span>
        </li>
        <li>
          <span
            href="#"
            className={activeTab === 2 ? "active" : ""}
            onClick={() => tabChanged(2)}
          >
            <img src={Images.notification_icon2} alt="" />
            <h5>Reports</h5>
          </span>
        </li>
        <li>
          <span
            href="#"
            className={activeTab === 1 ? "active" : ""}
            onClick={() => tabChanged(4)}
          >
            <img src={Images.notification_icon} alt="" />
            <h5>Terms & Conditions & Agreement</h5>
          </span>
        </li>
       { /*} <li>
          <span
            href="#"
            className={activeTab === 1 ? "active" : ""}
            onClick={() => tabChanged(1)}
          >
            <img src={Images.notification_icon} alt="" />
          </span>
        </li> */ }
      </ul>
    </div>
  );
};

export default CreatorTab;
