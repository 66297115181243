import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserData } from "../redux/userReducer";
import { getStorage, getStorageJson } from "../utils/localStorage";

import { AppRoute } from "../router/routerConstants";

function OnlyConsumer({ children }) {
  const dispatch = useDispatch();
  const token = getStorage("token");
  const user = getStorageJson("user");
  dispatch(updateUserData(user));

  if (token) {
    if (user.role === "Creator") {
      return <Navigate to={AppRoute.CreatorHome} />;
    }
  }
  return children;
}

export default OnlyConsumer;
