import { Images } from "../../../themes";
import React, { useEffect } from "react";
import { AppRoute } from "../../../router/routerConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import ConsumerTab from "../../../components/ConsumerTab";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import socket from "../../../socket/socket";
import { removeStorage } from "../../../utils/localStorage";
import { LogOutUser } from "../../../utils/api/actionConstants";
import { LoadSubscription } from "../../../utils/api/actionConstants";
import { getData } from "../../../utils/api/apiHelper";

const MyAcccount = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  let user = currentUser.userData;
  let userProfile = user.profilePic ? user.profilePic : Images.default_profile;

  useEffect(() => {
    dispatch(
      getData({
        action: LoadSubscription,
      })
    );
  }, []);

  const LogOut = () => {
    if (socket) {
      socket.close();
    }
    removeStorage("user");
    removeStorage("token");
    dispatch({ type: LogOutUser });
    NavigateTo(AppRoute.LOGIN, true);
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="my_account-wrap">
          <div className="heading-block">
            <h1 className="align-left">My Account</h1>
          </div>
          <div className="profile-edit-block">
            <div className="profile-edit-inner">
              {/*<figure>
                <img src={userProfile} alt="img" />
              </figure> */}
              <div className="profile-edit-content">
                <h3>{user.name}</h3>
                <span className="mail-link">{user.email}</span>
                <span
                  className="edit-btn"
                  onClick={() => NavigateTo(AppRoute.Cons_Edit_Profile)}
                >
                  <i>
                    <img src={Images.edit_icon} alt="edit" />
                  </i>
                  Edit Profile
                </span>
              </div>
            </div>
          </div>
          <div className="my-account-detail">
            <div
              className="my-account-detail-item my-wallet"
              onClick={() => NavigateTo(AppRoute.Cons_Wallet)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.my_wallet} alt="wallet" />
                </figure>
                <span className="item-name">My Wallet</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>
            {/* <div
              className="my-account-detail-item manage-card"
              onClick={() => NavigateTo(AppRoute.Cons_Cards)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.ic_manage_card} alt="manage_card" />
                </figure>
                <span className="item-name">Manage Card</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div> */}
            {/* <div
              className="my-account-detail-item manage-card"
              onClick={() => NavigateTo(AppRoute.Billing_Address)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.ic_manage_card} alt="manage_card" />
                </figure>
                <span className="item-name">Manage BillingAddress</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div> */}
            <div
              className="my-account-detail-item manage-card"
              onClick={() => NavigateTo(AppRoute.Favorites)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.ic_manage_card} alt="manage_card" />
                </figure>
                <span className="item-name">My Favorites </span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>
            {/*
            <div
              className="my-account-detail-item manage-subscription"
              onClick={() => NavigateTo(AppRoute.Subscriptions)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.manage_subscription} alt="subscription" />
                </figure>
                <span className="item-name">Manage Subscription</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div> */ }
            <div
              className="my-account-detail-item manage-subscription"
              onClick={() => NavigateTo(AppRoute.CONS_FOLDER)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.ic_image_content} alt="content" />
                </figure>
                <span className="item-name">Content Library</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>
            <div
              className="my-account-detail-item setting"
              onClick={() => NavigateTo(AppRoute.Settings)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.Setting} alt="setting" />
                </figure>
                <span className="item-name">Settings</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>
            <span className="logout-btn" onClick={LogOut}>
              Logout
            </span>
          </div>
          <ConsumerTab activeTab={3} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyAcccount;
