import React from "react";
import { Images } from "./../themes";

const BlurryLoadingImage = ({ profilePic, isBlur,fullImage=false }) => {
  return (
    <img
      src={profilePic ? profilePic : Images.default_profile}
      alt="img"
      style={{ filter: isBlur ? (fullImage==true)?"blur(15px)" :"blur(4.5px)" : "none" }}
    />
  );
};

export default BlurryLoadingImage;
