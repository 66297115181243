export const bodytype = [
  { value: "Slim", label: "Slim" },
  { value: "Athletic", label: "Athletic" },
  { value: "Average", label: "Average" },
  { value: "Curvy", label: "Curvy" },
  { value: "Full Figure", label: "Full Figure" },
  { value: "Heavyset", label: "Heavyset" },
];

export const hairColorList = [
  { value: "Auburn", label: "Auburn" },
  { value: "Black", label: "Black" },
  { value: "Blonde", label: "Blonde" },
  { value: "Brunette", label: "Brunette" },
  { value: "Light Brown", label: "Light Brown" },
  { value: "Dark Brown", label: "Dark Brown" },
  { value: "Grey", label: "Grey" },
  { value: "Red", label: "Red" },
  { value: "White", label: "White" },
  { value: "Other", label: "Other" },
];

export const ethnicity = [
  { value: "Asian", label: "Asian" },
  { value: "Black / African Descent", label: "Black / African Descent" },
  { value: "Latin / Hispanic", label: "Latin / Hispanic" },
  { value: "East Indian", label: "East Indian" },
  { value: "Middle Eastern", label: "Middle Eastern" },
  { value: "Mixed", label: "Mixed" },
  { value: "Native American", label: "Native American" },
  { value: "Pacific Islander", label: "Pacific Islander" },
  { value: "White / Caucasian", label: "White / Caucasian" },
  { value: "Other", label: "Other" },
];
