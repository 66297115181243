import React, { useState, useEffect } from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Images } from "../../themes";
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { toast } from "react-toastify";
import { AppRoute } from "../../router/routerConstants";
import { saveData } from "../../utils/api/apiHelper";
import { useDispatch } from "react-redux";
import { VerifyOtp } from "../../utils/api/actionConstants";

const OtpVerification = () => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const { state } = useLocation();
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const showError = (message) => {
    toast.error(message);
    toast.clearWaitingQueue();
  };

  useEffect(() => {
    
    if (!state?.userId) {
      NavigateTo(AppRoute.LOGIN, true); // Redirect to login if user_id doesn't exist
    }
  }, [state]);

  // Handle input change for each box
  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return; // Only accept digits
    let newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    // Automatically focus on the next input box
    if (value && index < 3) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
    // Automatically submit OTP when all boxes are filled
    if (newOtp.every(num => num !== '') && index === 3) {
      //onSubmit && onSubmit(newOtp.join('')); // Combine OTP and submit
    }
  };
  const handleKeyDown = (e, index) => {
    const { key } = e;
    // If backspace is pressed and the current input is empty, move to the previous input
    if (key === 'Backspace' && otp[index] === '' && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  // Handle paste event to allow pasting OTP
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').trim();
    if (pasteData.length === 4 && /^\d+$/.test(pasteData)) {
      setOtp(pasteData.split(''));
      document.getElementById(`otp-3`).focus();
      // Automatically submit OTP after paste
      //onSubmit && onSubmit(pasteData);
    }
    e.preventDefault(); // Prevent default paste behavior
  };
  const verifyOtp = () => {
    if (otp.some(field => field === '')) {
      showError("Please enter a valid 4-digit OTP.");
      return;
    }

    // Check if all fields contain digits
    if (!/^\d+$/.test(otp.join(''))) {
      showError('Please enter a valid 4-digit OTP.');
      return;
    }
    const params = {
      id: state?.userId,
      otp: otp.join(''),
    };
    dispatch(
      saveData({
        action: VerifyOtp,
        data: params,
      },true)
    ).then((r) => {
      console.log(r);
        if (r.user.role == "Creator") {
          NavigateTo(AppRoute.SETUP);
        } else {
          NavigateTo(AppRoute.CONS_Search);
        }
    });
  }

  useEffect(() => {
    // Attach the paste event listener to the OTP input fields
    const otpInputs = document.querySelectorAll('.otp-box');
    otpInputs.forEach(input => {
      input.addEventListener('paste', handlePaste);
    });

    return () => {
      // Cleanup the event listener on unmount
      otpInputs.forEach(input => {
        input.removeEventListener('paste', handlePaste);
      });
    };
  }, []);

  return (
    <div className="wrapper">
    <Header showLogout={false} />
    <div className="container small-container">
      <div className="login-wrap">
        <div className="logo-block">
          <span>
            <img src={Images.coming_soon_logo} alt="logo" />
          </span>
        </div>
        <div className="form-block otp-verify">
          <form>
            <div className="form-group">
                {otp.map((digit, index) => (
                    <input
                    key={index}
                    type="text"
                    id={`otp-${index}`}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="otp-box"
                    />
                ))}
            </div>
            <input
              className="submit"
              type="button"
              defaultValue="Verify OTP"
              onClick={verifyOtp}
            />          
          </form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
    
  );
};

export default OtpVerification;