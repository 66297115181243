import React, { useState } from "react";
const DeletePopup = ({hideModal,deletefunc,message}) => {
  return (
    <div className="modal-wrapper delete-popup" onClick={() => hideModal()}>
      <div className="model-inner">
        <div className="model-box">
            <h4>Are you sure ?</h4>
            <h6>{message}</h6>
            <button onClick={() => deletefunc()}>Yes</button>
            <button onClick={() => hideModal()}>No</button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
