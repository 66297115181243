import { Images } from "../../themes";
import { useState } from "react";
import { AppRoute } from "../../router/routerConstants";
import { useDispatch, useSelector } from "react-redux";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { saveData } from "../../utils/api/apiHelper";
import Header from "../../components/Header";
import { UpdatePassword } from "../../utils/api/actionConstants";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import * as Validate from "../../utils/validatorXerox";

const ChangePassword = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfPassword, setShowconfPassword] = useState(false);

  const showError = (message) => {
    toast.error(message);
    toast.clearWaitingQueue();
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onconPasswordChange = (event) => {
    setConfPassword(event.target.value);
  };

  const onOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleSubmit = () => {
    const params = {
      password: oldPassword,
      newPassword: password,
    };

    if (oldPassword.length === 0) {
      showError("Old Password can't be blank");
      return;
    }
    if (password.length === 0) {
      showError("New Password can't be blank");
      return;
    }
    if (password !== confPassword) {
      showError("Password do not match");
      return;
    }

    dispatch(
      saveData({
        action: UpdatePassword,
        definition: "ResetPassword",
        data: params,
      })
    ).then((r) => {
      if (r === 1) {
        NavigateTo(-1);
      }
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="change-password-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
              <h1>Change Password</h1>
            </div>
            <div className="change-password-inner">
              <form>
                <div className="form-group">
                  <div className="pass-input">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      placeholder="Old Password"
                      onChange={onOldPasswordChange}
                    />
                    {!showOldPassword ? (
                      <span
                        className="hide-pass"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      />
                    ) : (
                      <span
                        className="show-pass"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      ></span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="pass-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      onChange={onPasswordChange}
                    />
                    {!showPassword ? (
                      <span
                        className="hide-pass"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <span
                        className="show-pass"
                        onClick={() => setShowPassword(!showPassword)}
                      ></span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="pass-input">
                    <input
                      type={showconfPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      onChange={onconPasswordChange}
                    />

                    {!showconfPassword ? (
                      <span
                        className="hide-pass"
                        onClick={() => setShowconfPassword(!showconfPassword)}
                      />
                    ) : (
                      <span
                        className="show-pass"
                        onClick={() => setShowconfPassword(!showconfPassword)}
                      ></span>
                    )}
                  </div>
                </div>
                <div className="btn-block">
                  <input
                    className="submit"
                    type="button"
                    defaultValue="Save"
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
