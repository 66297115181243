import { useEffect } from "react";
import { Images } from "../../../themes";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useState } from "react";
import { saveData, getData } from "../../../utils/api/apiHelper";
import { useSelector, useDispatch } from "react-redux";
import {
  nextSetupPage,
  prevSetupPage,
  updateUserData,
} from "../../../redux/userReducer";
import * as CommonFunc from "../../../utils/Common";
import { UpdateCreator, GetTags } from "../../../utils/api/actionConstants";
import TagsInput from "../../../components/TagsInput";

import { hairColorList, bodytype, ethnicity } from "../../../utils/Constants";

const Setup1 = ({ from }) => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const [age, setAge] = useState("18");
  const [feet, setFeet] = useState("");
  const [inch, setInch] = useState("");
  const [selectedBodyType, setSelectedBodyType] = useState("DEFAULT");
  const [selectedEthnicity, setSelectedEthnicity] = useState("DEFAULT");
  const [hairColor, setHairColor] = useState("DEFAULT");
  const [tags, setTags] = useState([]);

  const userData = useSelector((state) => state.user.userData);

  const tagsList = useSelector((state) => {
    return state.creator.tags;
  });

  useEffect(() => {
    dispatch(getData({ action: GetTags }));
    setAge(userData.age);
    setSelectedBodyType(
      userData.bodyType == "" ? "DEFAULT" : userData.bodyType
    );
    setSelectedEthnicity(
      userData.ethnicity == "" ? "DEFAULT" : userData.ethnicity
    );
    setHairColor(userData.hairColor == "" ? "DEFAULT" : userData.hairColor);
    setTags(userData.tags);

    const ht = userData.height;

    if (ht) {
      const htsplit = ht.split(".");

      setFeet(htsplit[0] ?? "");
      setInch(htsplit[1] ?? "");
    }
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setSelectedBodyType(e.target.value);
  };

  const handleEthnicityChange = (e) => {
    e.preventDefault();
    setSelectedEthnicity(e.target.value);
  };
  const handleHairColorChange = (e) => {
    e.preventDefault();
    setHairColor(e.target.value);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (!age || age == "") {
      CommonFunc.showError("Please enter your age");
      return;
    }

    if (age < parseInt(18, 10)) {
      CommonFunc.showError("Age must be greater than or equal to 18 years.");
      return;
    }

    if (!feet || feet == "") {
      CommonFunc.showError("Please enter your height in feet and inches");
      return;
    }

    if (!inch || inch == "") {
      CommonFunc.showError("Please enter your height in feet and inches");
      return;
    }

    if (!selectedBodyType || selectedBodyType == "DEFAULT") {
      CommonFunc.showError("Please select Body type");
      return;
    }
    if (!selectedEthnicity || selectedEthnicity == "DEFAULT") {
      CommonFunc.showError("Please select Ethnicity");
      return;
    }

    if (!hairColor || hairColor == "DEFAULT") {
      CommonFunc.showError("Please select hair color");
      return;
    }

    const params = {
      age: age,
      bodyType: selectedBodyType,
      ethnicity: selectedEthnicity,
      hairColor: hairColor,
      height: feet + "." + inch,
      tags: tags,
    };
    dispatch(updateUserData(params));

    const payload = {
      action: UpdateCreator,
      data: params,
    };
    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        // NavigateTo(AppRoute.SETUP2);
        dispatch(nextSetupPage());
      }
    });
  };

  return (
    <div className="container">
      <div className="setup-profile-wrap">
        <div className="heading-block">
          <span className="back-btn">
            <img
              src={Images.backImg}
              alt="back-btn"
              onClick={() => dispatch(prevSetupPage())}
            />
          </span>
          <h1>Physical Characteristics</h1>
        </div>
        <div className="step-bar">
          
          <ul className="steps">
            <li className="step is-complete is-active" data-step={1} />
            <li className="step is-complete" data-step={2} />
            <li className="step is-active" data-step={3} />
            <li className="step" data-step={4} />
            {from && from === "edit" ? (
              <li className="step " data-step={5} />
            ) : (
              <li className="step " data-step={5} />
            )}
          </ul>
        </div>
        <div className="form-block">
          <form>
            {/* <div className="upload-text">
              <p>
                <img src={Images.tellMeMore} alt="" />
                Tips: These filters will be used in profile search
              </p>
            </div> */}
            
            <div className="all-input">
              <div className="form-group">
                <input
                  type="number"
                  value={age}
                  placeholder="Age"
                  onBlur={() => {
                    if (age < parseInt(18, 10)) {
                      setAge("18");
                    }
                  }}
                  min={18}
                  onChange={(event) => setAge(event.target.value)}
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <input
                      type="number"
                      value={feet}
                      max={10}
                      min={0}
                      placeholder="Height(Feet)"
                      onChange={(event) => setFeet(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <input
                      type="number"
                      value={inch}
                      max={12}
                      min={0}
                      placeholder="Height(Inch)"
                      onChange={(event) => setInch(event.target.value)}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <select
                      name="Body Type"
                      id="body_type"
                      value={selectedBodyType}
                      onChange={handleChange}
                    >
                      <option value="DEFAULT" disabled hidden>
                        Select Body Type
                      </option>
                      {bodytype.map((r) => {
                        return (
                          <option key={r.value} value={r.value}>
                            {r.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <select
                      name="Ethnicity"
                      id="ethnicity"
                      value={selectedEthnicity}
                      onChange={handleEthnicityChange}
                    >
                      <option value="DEFAULT" disabled hidden>
                        Select Ethnicity
                      </option>
                      {ethnicity.map((r) => {
                        return (
                          <option key={r.value} value={r.value}>
                            {r.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <select
                  name="Hair Color"
                  id="hairColor"
                  value={hairColor}
                  onChange={handleHairColorChange}
                >
                  <option value="DEFAULT" disabled hidden>
                    Select Hair Color
                  </option>
                  {hairColorList.map((r) => {
                    return (
                      <option key={r.value} value={r.value}>
                        {r.label}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group">
                <TagsInput
                  onChange={(tags) => setTags(tags)}
                  selectedTags={tags}
                  tagsList={[]}
                />
              </div>
              <div className="upload-text">
                Add tags to tell users what you like, and what you're into.
                (seperated by commas or enter button)
              </div>

              {/* <div className="form-group">
                <input
                  type="text"
                  value={zip}
                  placeholder="Zip code"
                  onChange={(event) => setZip(event.target.value)}
                />
              </div> */}
            </div>
            <input
              className="submit"
              type="button"
              defaultValue="Continue"
              onClick={handleContinue}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Setup1;
