import { useEffect, useState } from "react";
import { Images } from "../../../themes";
import { useSelector, useDispatch } from "react-redux";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import * as CommonFunc from "../../../utils/Common";
import {
  nextSetupPage,
  prevSetupPage,
  updateUserData,
} from "../../../redux/userReducer";
import {
  LoadFolder,
  FetchFolderFiles,
} from "../../../utils/api/actionConstants";
import { UploadFile } from "../../../utils/api/actionConstants";
import {
  UpdateprivatePic,
  UpdateCreator,
} from "../../../utils/api/actionConstants";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { AddContent, SetPrice } from "../../../utils/api/actionConstants";
import { getData } from "../../../utils/api/apiHelper";

const Setup4New = () => {
  // const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [img4, setImg4] = useState();
  const [img5, setImg5] = useState();
  const [img6, setImg6] = useState();
  const [img7, setImg7] = useState();
  const [img8, setImg8] = useState();
  const [img9, setImg9] = useState();
  const [img10, setImg10] = useState();
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);

  const [imgurlArray, setImgUrlArray] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  let privatePic = useSelector((state) => state.folder?.folderPrivate);
  let currentUser = useSelector((state) => state.user.userData);

  useEffect(() => {
    dispatch(
      getData({
        action: FetchFolderFiles,
        data: { folder: "Private", offset: 0, limit: 80 },
      })
    );
    setPrice(currentUser.contentAmount);
  }, []);

  useEffect(() => {
    //Remove after image approval from admin
    if (privatePic && privatePic.length > 10) {
      privatePic = privatePic.slice(-10).map((r) => r);
    }

    if (privatePic && privatePic.length == 10) {
      if (typeof privatePic[0] == "object") {
        privatePic = privatePic.map((r) => r.source);
      }
      setImgUrlArray(privatePic);
      setImg1(privatePic[0]);
      setImg2(privatePic[1]);
      setImg3(privatePic[2]);
      setImg4(privatePic[3]);
      setImg5(privatePic[4]);
      setImg6(privatePic[5]);
      setImg7(privatePic[6]);
      setImg8(privatePic[7]);
      setImg9(privatePic[8]);
      setImg10(privatePic[9]);
    }
  }, [privatePic]);

  const onImageChange1 = (e, imgNum) => {
    const [file] = e.target.files;

    if (imgNum === 1) {
      setImg1(URL.createObjectURL(file));
    } else if (imgNum === 2) {
      setImg2(URL.createObjectURL(file));
    } else if (imgNum === 3) {
      setImg3(URL.createObjectURL(file));
    } else if (imgNum === 4) {
      setImg4(URL.createObjectURL(file));
    } else if (imgNum === 5) {
      setImg5(URL.createObjectURL(file));
    } else if (imgNum === 6) {
      setImg6(URL.createObjectURL(file));
    } else if (imgNum === 7) {
      setImg7(URL.createObjectURL(file));
    } else if (imgNum === 8) {
      setImg8(URL.createObjectURL(file));
    } else if (imgNum === 9) {
      setImg9(URL.createObjectURL(file));
    } else if (imgNum === 10) {
      setImg10(URL.createObjectURL(file));
    }
    setLoading(true);
    imageUpload(file, imgNum);
  };

  const imageUpload = (file, imgNum) => {
    const fd = new FormData();
    fd.append("file", file);
    let imgUrls = imgurlArray;
    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      if (r.length > 0) {
        if (imgNum === 1) {
          // setImg1(r[0]);
          imgUrls[0] = r[0];
        } else if (imgNum === 2) {
          // setImg2(r[0]);
          imgUrls[1] = r[0];
        } else if (imgNum === 3) {
          // setImg3(r[0]);
          imgUrls[2] = r[0];
        } else if (imgNum === 4) {
          // setImg4(r[0]);
          imgUrls[3] = r[0];
        } else if (imgNum === 5) {
          // setImg1(r[0]);
          imgUrls[4] = r[0];
        } else if (imgNum === 6) {
          // setImg2(r[0]);
          imgUrls[5] = r[0];
        } else if (imgNum === 7) {
          // setImg3(r[0]);
          imgUrls[6] = r[0];
        } else if (imgNum === 8) {
          // setImg4(r[0]);
          imgUrls[7] = r[0];
        } else if (imgNum === 9) {
          // setImg3(r[0]);
          imgUrls[8] = r[0];
        } else if (imgNum === 10) {
          // setImg4(r[0]);
          imgUrls[9] = r[0];
        }
        setLoading(false);
        setImgUrlArray(imgUrls);
      }
    });
  };

  const DefaultView = ({ imgNum }) => {
    return (
      <div className="inner-uploaded">
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={(e) => onImageChange1(e, imgNum)}
        />
        <img className="upload-icon" src={Images.uploadImgIcon} />
      </div>
    );
  };

  const ImageView = ({ imgNum }) => {
    let image = "";
    if (imgNum === 1) {
      image = img1;
    } else if (imgNum === 2) {
      image = img2;
    } else if (imgNum === 3) {
      image = img3;
    } else if (imgNum === 4) {
      image = img4;
    } else if (imgNum === 5) {
      image = img5;
    } else if (imgNum === 6) {
      image = img6;
    } else if (imgNum === 7) {
      image = img7;
    } else if (imgNum === 8) {
      image = img8;
    } else if (imgNum === 9) {
      image = img9;
    } else if (imgNum === 10) {
      image = img10;
    }

    return (
      <div className="inner-uploaded">
        <figure>
          <img src={image} />
          <span className="upload-link">
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={(e) => onImageChange1(e, imgNum)}
            />
            <img src={Images.cameraIcon2} alt="" />
          </span>
        </figure>
      </div>
    );
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const imgNotSelected = imgurlArray.filter((r) => r === "");

    if (imgNotSelected.length > 0) {
      CommonFunc.showError("Please select all Images");
      return;
    }

    if (!price || !(Number(price) > 0)) {
      CommonFunc.showError("Please enter price for your private images");
      return;
    }

    const params = {
      folder: "Private",
      files: imgurlArray,
    };
    const payload = {
      action: AddContent,
      data: params,
    };

    const pricePayload = {
      action: SetPrice,
      data: { price: price },
    };
    dispatch(saveData(pricePayload)).then((r) => {});

    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        const payload = {
          action: UpdateCreator,
          data: { isSetupComplete: true },
        };

        dispatch(saveData(payload)).then((r) => {
          if (r === 1) {
            dispatch(updateUserData({ isSetupComplete: true }));
            // NavigateTo(AppRoute.SETUP4);
            dispatch(nextSetupPage());
          }
        });
      }
    });

    // const params = {
    //   allProfilePics: imgurlArray,
    // };
    // dispatch(updateUserData(params));

    // // setStorageJson("userpic", imgurlArray);

    // const payload = {
    //   action: UpdateprivatePic,
    //   data: { profiles: imgurlArray },
    // };
    // dispatch(saveData(payload)).then((r) => {
    //   if (r === 1) {
    //     const payload = {
    //       action: UpdateCreator,
    //       data: { isSetupComplete: true },
    //     };

    //     dispatch(saveData(payload)).then((r) => {
    //       if (r === 1) {
    //         dispatch(updateUserData({ isSetupComplete: true }));
    //         // NavigateTo(AppRoute.SETUP4);
    //         dispatch(nextSetupPage());
    //       }
    //     });
    //   }
    // });
  };

  return (
    <div>
      <FullScreenLoader loading={loading} />
      <div className="container">
        <div className="setup-profile-wrap">
          <div className="heading-block">
            <span className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => dispatch(prevSetupPage())}
              />
            </span>
            <h1>Introductory Private</h1>
          </div>
          <div className="step-bar">
            <ul className="steps">
              <li className="step is-complete is-active" data-step={1} />
              <li className="step is-complete" data-step={2} />
              <li className="step is-complete" data-step={3} />
              <li className="step is-complete" data-step={4} />
              <li className="step is-active" data-step={5} />
            </ul>
          </div>
          <div className="form-block">
            <form>
              <div className="upload-text">
                <p>
                  <img src={Images.tellMeMore} alt="" />
                  Upload 10 separate NC17/R-Rated photos. Clients will be able
                  to view this folder and message you privately for custom
                  content only if they pay your Introductory Private Folder
                  price that you set below.
                </p>
              </div>
              <div className="uploded-imges">
                <div className="row">
                  <div className="col-6">
                    {img1 ? (
                      <ImageView imgNum={1} />
                    ) : (
                      <DefaultView imgNum={1} />
                    )}
                  </div>
                  <div className="col-6">
                    {img2 ? (
                      <ImageView imgNum={2} />
                    ) : (
                      <DefaultView imgNum={2} />
                    )}
                  </div>
                  <div className="col-6">
                    {img3 ? (
                      <ImageView imgNum={3} />
                    ) : (
                      <DefaultView imgNum={3} />
                    )}
                  </div>
                  <div className="col-6">
                    {img4 ? (
                      <ImageView imgNum={4} />
                    ) : (
                      <DefaultView imgNum={4} />
                    )}
                  </div>

                  <div className="col-6">
                    {img5 ? (
                      <ImageView imgNum={5} />
                    ) : (
                      <DefaultView imgNum={5} />
                    )}
                  </div>
                  <div className="col-6">
                    {img6 ? (
                      <ImageView imgNum={6} />
                    ) : (
                      <DefaultView imgNum={6} />
                    )}
                  </div>
                  <div className="col-6">
                    {img7 ? (
                      <ImageView imgNum={7} />
                    ) : (
                      <DefaultView imgNum={7} />
                    )}
                  </div>
                  <div className="col-6">
                    {img8 ? (
                      <ImageView imgNum={8} />
                    ) : (
                      <DefaultView imgNum={8} />
                    )}
                  </div>

                  <div className="col-6">
                    {img9 ? (
                      <ImageView imgNum={9} />
                    ) : (
                      <DefaultView imgNum={9} />
                    )}
                  </div>
                  <div className="col-6">
                    {img10 ? (
                      <ImageView imgNum={10} />
                    ) : (
                      <DefaultView imgNum={10} />
                    )}
                  </div>

                  <div>
                    <p>
                      Set the price for your introductory private folder. No
                      user will be able to message you until they pay this
                      price.
                    </p>
                  </div>

                  <div className="model-inner">
                    <div className="model-box">
                      <input
                        value={price}
                        onChange={(event) => setPrice(event.target.value)}
                        step={0.01}
                        min={0}
                        type="number"
                        pattern="[0-9]*"
                        placeholder="Enter $ amount"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleContinue}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup4New;
