import { Images } from "../../themes";
import { useState } from "react";
import { AppRoute } from "../../router/routerConstants";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { ResetPassword as RstPass } from "../../utils/api/actionConstants";
import { saveData } from "../../utils/api/apiHelper";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ResetPassword = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const [code, seCode] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");

  const onCodeChange = (event) => {
    seCode(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onconPasswordChange = (event) => {
    setConfPassword(event.target.value);
  };

  const { state } = useLocation();

  const handleSubmit = () => {
    if (password.length === 0) {
      toast.error("Password is required");
      toast.clearWaitingQueue();
      return;
    }
    if (password !== confPassword) {
      toast.error("Password do not match");
      toast.clearWaitingQueue();
      return;
    }
    dispatch(
      saveData({
        action: RstPass,
        definition: "ResetPassword",
        data: {
          hash: code,
          email: state?.email,
          password: password,
        },
      })
    ).then((r) => {
      if (r === 1) {
        NavigateTo(AppRoute.LOGIN, true);
      }
    });
  };

  return (
    <div className="wrapper">
      <Header showLogout={false} />
      <div className="container small-container">
        <div className="forgot-pass-wrap">
          <div className="heading-block">
            <span href="#" className="back-btn">
              <img
                src={Images.backImg}
                alt="back-btn"
                onClick={() => NavigateTo(-1)}
              />
            </span>
            <h1>Reset Password</h1>
            
          </div>
          <div className="my-ac-form-info">
        <div className="form-inner-holder"><p>Please check your email for code.</p> 
        <p>Email support@arouzed.com with any issues.</p></div>
        </div>
          <div className="form-block">
            <form>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type="text"
                    placeholder="code"
                    onChange={onCodeChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type="password"
                    placeholder="New Password"
                    onChange={onPasswordChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="pass-input">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    onChange={onconPasswordChange}
                  />
                </div>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Reset Password"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
