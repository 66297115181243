import ReactPlayer from "react-player";

const VideoMessage = (message, currentUser, show) => {
 
  const url = message.message;
  return (
    <div
      className={`${
        currentUser ? "sent-msg" : "receive-msg"
      } more-content img-receive`}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        controls
        config={{
          file: {
            attributes: {
              controlsList: " nodownload  ",
            },
          },
        }}
      />

      

      {!currentUser && (
        <>
          <button pointer
            className="more-content savepay"
            onClick={(e) => show(message)}
          >
            Save
          </button>
        </>
      )}
    </div>
  );
};
export default VideoMessage;
