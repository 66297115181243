// import "./App.css";
// import "../src/assets/css/custom.css";
// import "../src/assets/css/main.css";
// import "../src/assets/css/splash.css";
// import "../src/assets/css/register.css";
// import "../src/assets/css/benfits.css";
// import "../src/assets/css/setup-profile.css";
import "react-toastify/dist/ReactToastify.css";
// import "../src/assets/css/content-library.css";
// import "../src/assets/css/search.css";
// // import "../src/assets/css/my-account.css";
// import "../src/assets/css/my_folder.css";
// import "../src/assets/css/chat.css";
// import "../src/assets/css/request-purchased.css";
import "../src/assets/css/style.css";
import "../src/assets/css/comingsoonstyle.css";

import AppRouter from "./router/AppRouter";

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
