import {  createSlice } from "@reduxjs/toolkit";
const initialState = {
  folders: [],
  pifolder: [],
  folderR: [],
  folderX: [],
  folderPrivate: [],
  co_folders: [],
  co_files: [],
  selectedFiles: [],
  toSendFiles: false,
  hasMore: false,
};

export const folderSlice = createSlice({
  name: "myFolder",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetFolders: (state) => {
      state.folderR = [];
      state.folderFiles = [];
      state.folders = [];
      state.pifolder = [];
      state.folderX = [];
      state.folderPrivate = [];
      state.co_folders = [];
      state.co_files = [];
      state.selectedFiles = [];
      state.toSendFiles = false;
    },
  },
  extraReducers: {
    LoadFolder: (state, action) => {
      state.folderR = action.payload.R;
      state.folderX = action.payload.X;
      state.folderPrivate = action.payload.Private;
    },
    FetchFolderFiles: (state, action) => {
      state.folderFiles =  action.payload.folderFiles;
    },
    Delete_Content: (state, action) => {
      const id = action.payload.id;
      const list = state.folderFiles.filter(item => item.id !== id);
      state.folderFiles = list;
      //return state.filter(item => item.id !== id);
    },
    Cons_LoadFolder: (state, action) => {
      state.co_folders = action.payload;
    },
    Cons_LoadFolderFiles: (state, action) => {
      state.co_files = action.payload;
    },
    HasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    UpdateSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload.files;
      state.toSendFiles = action.payload.status;
    },
    Get_Cons_CreateFolder_Creator:(state,action)=>{
      state.folders = action.payload.allFolders;
      state.pifolder = action.payload.IntroductoryPrivateFolder;
    }
  },
});

export const { LoadFolder, fetchFolderFiles, resetFolders } =
  folderSlice.actions;
export const myFolderData = (state) => state.myFolder;
export default folderSlice.reducer;
