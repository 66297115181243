import { Images } from "../themes";
import { useSelector } from "react-redux";
import MultiRangeSlider from "multi-range-slider-react";
import { useState } from "react";
import TagsInput from "./TagsInput";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getData } from "../utils/api/apiHelper";
import { StaticData } from "../utils/api/actionConstants";

// const bodytype = [
//   { value: "Slim", label: "Slim" },
//   { value: "Athletic", label: "Athletic" },
//   { value: "Average", label: "Average" },
//   { value: "Curvy", label: "Curvy" },
//   { value: "Full Figure", label: "Full Figure" },
//   { value: "Heavyset", label: "Heavyset" },
// ];

// const hairColorList = [
//   { value: "Select hair color", label: "Select hair color" },
//   { value: "Auburn", label: "Auburn" },
//   { value: "Black", label: "Black" },
//   { value: "Blonde", label: "Blonde" },
//   { value: "Light Brown", label: "Light Brown" },
//   { value: "Dark Brown", label: "Dark Brown" },
//   { value: "Grey", label: "Grey" },
//   { value: "Red", label: "Red" },
//   { value: "White", label: "White" },
//   { value: "Other", label: "Other" },
// ];

const ethnicity = [
  { value: "Select ethnicity", label: "Select ethnicity" },
  { value: "Asian", label: "Asian" },
  { value: "Black / African Descent", label: "Black / African Descent" },
  { value: "Latin / Hispanic", label: "Latin / Hispanic" },
  { value: "East Indian", label: "East Indian" },
  { value: "Middle Eastern", label: "Middle Eastern" },
  { value: "Mixed", label: "Mixed" },
  { value: "Native American", label: "Native American" },
  { value: "Pacific Islander", label: "Pacific Islander" },
  { value: "White / Caucasian", label: "White / Caucasian" },
  { value: "Other", label: "Other" },
];

const SearchFilter = ({ modalVisibility, filters, filtersSubmit }) => {
  const dispatch = useDispatch();
  const tagsList = useSelector((state) => {
    return state.creator.tags;
  });

  const minAge = 18;
  const maxAge = 70;

  const [bdtype, setBdType] = useState([
    {
      value: "Select body type",
      label: "Select body type",
    },
  ]);
  const [hrColor, setHrColor] = useState([
    { value: "Select hair color", label: "Select hair color" },
  ]);

  const [minAgeValue, set_minValue] = useState(
    filters.minAgeValue ? filters.minAgeValue : minAge
  );
  const [maxAgeValue, set_maxValue] = useState(
    filters.maxAgeValue ? filters.maxAgeValue : maxAge
  );

  const [selectedBodyType, setSelectedBodyType] = useState(
    filters.bodyType ? filters.bodyType : "DEFAULT"
  );
  const [selectedEthnicity, setSelectedEthnicity] = useState(
    filters.ethnicity ? filters.ethnicity : "DEFAULT"
  );

  const [selectHairColor, setHairColor] = useState(
    filters.hairColor ? filters.hairColor : "DEFAULT"
  );

  const [tags, setTags] = useState(filters.tags ? filters.tags : []);

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const hideModal = () => {
    if (modalVisibility) {
      modalVisibility(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSelectedBodyType(e.target.value);
  };

  const handleEthnicityChange = (e) => {
    e.preventDefault();
    setSelectedEthnicity(e.target.value);
  };

  const handleHairColorChange = (e) => {
    e.preventDefault();
    setHairColor(e.target.value);
  };

  const handleReset = () => {
    setSelectedEthnicity("DEFAULT");
    setSelectedBodyType("DEFAULT");
    setHairColor("DEFAULT");
    set_minValue(minAge);
    set_maxValue(maxAge);
    setTags([]);
  };

  useEffect(() => {
    dispatch(
      getData({
        action: StaticData,
        sendAll: true,
      })
    ).then((r) => {
      const bd = r.bodyType.map((r) => {
        return { label: r, value: r };
      });

      bd.splice(0, 0, {
        value: "Select body type",
        label: "Select body type",
      });

      setBdType(bd);

      const hr = r.hairColor.map((r) => {
        return { label: r, value: r };
      });

      hr.splice(0, 0, {
        value: "Select hair color",
        label: "Select hair color",
      });

      setHrColor(hr);
    });
  }, []);

  const handleSubmit = () => {
    if (filtersSubmit) {
      let filter = {
        minAgeValue,
        maxAgeValue,
        bodyType: selectedBodyType,
        ethnicity: selectedEthnicity,
        hairColor: selectHairColor,
        tags: tags,
      };

      filtersSubmit(filter);
    }
  };

  const onChangeTags = (tags) => {
    setTags(tags);
  };

  return (
    <div className="search-filter-slide" onClick={hideModal}>
      <div className="search-filter-slide-inner" onClick={handleChildClick}>
        <h2>Search Filters</h2>
        <form>
          <div className="price-range-slider">
            <div className="price-range-heading">
              <h3>Age range</h3>
              <p className="range-value">
                <input
                  type="text"
                  id="age"
                  value={`${minAgeValue}-${maxAgeValue}`}
                  readOnly
                />
              </p>
            </div>
            <MultiRangeSlider
              label={false}
              ruler={false}
              style={{
                border: "none",
                boxShadow: "none",
                padding: "15px 10px",
              }}
              min={minAge}
              max={maxAge}
              step={4}
              minValue={minAgeValue}
              maxValue={maxAgeValue}
              onInput={(e) => {
                handleInput(e);
              }}
            />
          </div>
          <select
            name="Body Type"
            id="body_type"
            value={selectedBodyType}
            onChange={handleChange}
          >
            <option value="DEFAULT" disabled hidden>
              Select Body Type
            </option>
            {bdtype.map((r) => {
              return (
                <option key={r.value} value={r.value}>
                  {r.label}
                </option>
              );
            })}
          </select>
          <select
            name="Ethnicity"
            id="ethnicity"
            value={selectedEthnicity}
            onChange={handleEthnicityChange}
          >
            <option value="DEFAULT" disabled hidden>
              Select Ethnicity
            </option>
            {ethnicity.map((r) => {
              return (
                <option key={r.value} value={r.value}>
                  {r.label}
                </option>
              );
            })}
          </select>

          <select
            name="Hair Color"
            id="hairColor"
            value={selectHairColor}
            onChange={handleHairColorChange}
          >
            <option value="DEFAULT" disabled hidden>
              Select Hair Color
            </option>
            {hrColor.map((r) => {
              return (
                <option key={r.value} value={r.value}>
                  {r.label}
                </option>
              );
            })}
          </select>

          <TagsInput
            onChange={onChangeTags}
            selectedTags={tags}
            tagsList={tagsList.map((r) => r.name)}
          />
          {/* <input type="text" placeholder="Tags" />
          <div className="enter-type">
            <p>
              beautiful{" "}
              <span>
                <img src={Images.closeIcon} alt="" />
              </span>
            </p>
            <p>
              gorgeous{" "}
              <span>
                <img src={Images.closeIcon} alt="" />
              </span>
            </p>
            <p>
              brown eye{" "}
              <span>
                <img src={Images.closeIcon} alt="" />
              </span>
            </p>
          </div> */}
          <div className="btn-block">
          <span className="reset-btn" onClick={hideModal}>
              Close
            </span>
            <span className="reset-btn" onClick={handleReset}>
              Reset
            </span>
            <input
              type="button"
              defaultValue="Apply"
              className="submit"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchFilter;
