const FullScreenLoader = ({ loading = false }) => {
  return (
    <>
      {loading && (
        <div className="loader-wrap display-block">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default FullScreenLoader;
