import React, { useState, useRef, useEffect } from "react";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import { UploadFile } from "../../../utils/api/actionConstants";
import {  useDispatch } from "react-redux";
import { AppRoute } from "../../../router/routerConstants";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import {
  updateUserData,
} from "../../../redux/userReducer";
import {
  UpdateCreator
} from "../../../utils/api/actionConstants";
import * as CommonFunc from "../../../utils/Common";
import { Images } from "../../../themes";


/*const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB max file size
const ALLOWED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png"];*/

const AgeVerification = ({ from }) => {
  const fileInputRef = useRef(null);
  const fileIdentityInputRef = useRef(null);
  const [selfieImage, setSelfieImage] = useState(null);
  const [selfieImageUrl, setSelfieImageUrl] = useState(null);
  const [identityImage, setIdentityImage] = useState(null);
  const [identityImageUrl, setIdentityImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [NavigateTo] = useCustomNavigate();
  useEffect(()=>{
    const profilePic = sessionStorage.getItem('uploadProfilePicture')
      setSelfieImage(profilePic)
      setSelfieImageUrl(profilePic)
    const identityPic = sessionStorage.getItem('identityPic')
    setIdentityImage(identityPic)
    setIdentityImageUrl(identityPic)
    },[])
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleIdentityButtonClick = () => {
    fileIdentityInputRef.current.click();
  };


  const handleIdentityImageChange = (e) => {
    setLoading(true);
    const [file] = e.target.files;
    /*if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        CommonFunc.showErrorFileType("Invalid file type. Please upload a JPEG or PNG image.");
        setLoading(false);
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        CommonFunc.showErrorFileSize("File is too large. Please upload an image under 2MB.");
        setLoading(false);
        return;
      }
    } */
    const fd = new FormData();
    fd.append("file", file);
    setIdentityImage(URL.createObjectURL(file));
    setIdentityImageUrl("");
    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      setLoading(false);
      if (r.length > 0) {
        setIdentityImageUrl(r[0]);
        sessionStorage.setItem('identityPic',r[0])
      }
    });
  };
  const onImageChange = (e) => {
    setLoading(true);
    const [file] = e.target.files;

   /* if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        CommonFunc.showErrorFileType("Invalid file type. Please upload a JPEG or PNG image.");
        setLoading(false);
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        CommonFunc.showErrorFileSize("File is too large. Please upload an image under 2MB.");
        setLoading(false);
        return;
      }
    }*/

    const fd = new FormData();
    fd.append("file", file);
    setSelfieImage(URL.createObjectURL(file));
    setSelfieImageUrl("");
    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      setLoading(false);

      if (r.length > 0) {
        setSelfieImageUrl(r[0]);
        sessionStorage.setItem('uploadProfilePicture',r[0])
      }
    });
  };


  const handleContinue = (e) => {
    e.preventDefault();
    if (!selfieImageUrl || selfieImageUrl == "") {
      CommonFunc.showError("Please select a profile Image");
      return;
    }
    if (!identityImageUrl || identityImageUrl == "") {
      CommonFunc.showError("Please select an identity Image");
      return;
    }
    const params = {
        uploadProfilePicture: selfieImageUrl,
        photoIdentity:identityImageUrl,
        isIdUploaded: true
    };

    const payloadData = {
        uploadProfilePicture: selfieImageUrl,
        photoIdentity:identityImageUrl,
        isIdUploaded: true,
        isSetupComplete: true
    }

    dispatch(updateUserData(params));

    const payload = {
      action: UpdateCreator,
      data: payloadData,
      // data: { isSetupComplete: true },
    };
    dispatch(saveData(payload)).then((r) => {
      if (r === 1) {
        // NavigateTo(AppRoute.SETUP3);
        //dispatch(nextSetupPage());
        dispatch(updateUserData({ isSetupComplete: true }));
        sessionStorage.removeItem('uploadProfilePicture');
        sessionStorage.removeItem('identityPic');
        NavigateTo(AppRoute.Profile);
      }
    });
  };

  return (

    <div>
      <FullScreenLoader loading={loading} />
      <div className="container">
        <div className="setup-profile-wrap">
          { /*<div className="heading-block">
            <h1>General Profile Pics</h1>
          </div> */ }
          <div className="step-bar">
            <ul className="steps">
              { /*<li className="step is-active" data-step={1} />
              <li className="step" data-step={2} />
              <li className="step" data-step={3} />
              <li className="step" data-step={4} />
              {from && from === "edit" ? (
                <li className="step " data-step={5} />
              ) : (
                <li className="step " data-step={5} />
              )} */ }
            </ul>
          </div> 
          <div className="form-block text-center age-verif-block">
          <h1>Age Verification</h1>
              <div className="selfie-image">
              
                  <div className="selfie-image-top">
                    {selfieImage ? (
                      <div className="profile-photo-rounded">
                        <img src={selfieImageUrl !== "" ? selfieImageUrl : selfieImage} 
                        className="imagecamera" alt='Taken photo' />
                      </div>
                    ) : (
                      <div className="profile-photo-rounded">
                        <img src={Images.default_profile} className="imagecamera" alt='Default photo' />
                      </div>
                    )}
                    <div className="image-file">
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={onImageChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      <button className="submit" onClick={handleButtonClick}>Upload a selfie</button>
                      { /*<span>---or---</span>
                      <button className="submit" onClick={openSelfieCamera}>Take Selfie</button> */ }
                    </div>
                  </div>
             

               
                  {identityImage ? (
                    <div className="profile-photo-rounded sqr">
                      <img src={identityImage} alt='Taken photo' className="imagecamera" />
                    </div>
                  ) : (
                    <div className="profile-photo-rounded sqr">
                      <img src={Images.idDefault} alt='Default photo' className="imagecamera" />
                    </div>
                  )}

                  <div className="image-file">
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleIdentityImageChange}
                      style={{ display: 'none' }}
                      ref={fileIdentityInputRef}
                    />
                    <p>Upload ID to confirm you are atleast 18 years of age.</p>
                    <button className="acent-btn" onClick={handleIdentityButtonClick}>Upload ID</button>
                  </div>
              </div>
              <input
                className="submit"
                type="button"
                defaultValue="Continue"
                onClick={handleContinue}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;