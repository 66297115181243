import { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { IsUserSubscribed } from "./../utils/Common";
import { useUser } from "../hooks/useStore";
import Subscription from "./Subscription";

const Unsubscribed = () => {
  const [NavigateTo] = useCustomNavigate();
  const currentUser = useUser();

  useEffect(() => {
    if (currentUser) {
      let ifSubscribed = IsUserSubscribed(currentUser);
      if (ifSubscribed) {
        NavigateTo("/");
      }
    }
  }, []);

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="wrapper">
        <div className="container">
          <div className="setting-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-2)}>
                <img src={Images.backImg} alt="back-btn" />
              </span>
            </div>
            <Subscription />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Unsubscribed;
