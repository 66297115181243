import { Images } from "../themes";
import { AppRoute } from "../router/routerConstants";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { resetSetupPage } from "../redux/userReducer";

const Type = {
  profileUpdate: {
    icon: Images.star,
    message: "Profile Updated Successfully!",
    desc1: "You have successfully updated",
    desc2: "your profile.",
    navigate: -1,
    buttonTitle: "Back to My Account ",
  },
  profileCreated: {
    icon: Images.star,
    message: "Profile registered successfully and under review!",
    desc2:
      "In the next step you can begin to upload images safely in the provided R and X rated folders that you can quickly access when private messaging with clients.",
    desc1: "Please keep an eye out for an email from registration@arouzed.com",
    navigate: AppRoute.FOLDER,
    buttonTitle: "Continue",
  },
  addMoney: {
    icon: Images.transaction_successful,
    message: "Transaction Successful!",
    desc1: "You have successfully added ",
    desc2: "to your wallet.",
    desc3: "Arouzed.com will appear on your statement.",
    navigate: -1,
    buttonTitle: "Back to My Wallet ",
  },
  addWithdrawal: {
    icon: Images.transaction_successful,
    message: "Money withdrawn request sent!",
    desc1: "Your request to transfer ",
    desc2: "has been received.",
    navigate: -1,
    buttonTitle: "You now have balance of ",
  },
};

const Successfull = ({ type, amount, remaining,profileid=null,url=null }) => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const data = Type[type];

  const btnClick = () => {
    dispatch(resetSetupPage());
    console.log(url);
    if(type ==="addMoney"){
      if(typeof profileid !=="undefined" && profileid){
        //NavigateTo(data.navigate);
        NavigateTo(`${AppRoute.Search_Profile}/${profileid}`);
      }else if(typeof url !=="undefined" && url){
        NavigateTo(`/${url}`);
      }else{
        NavigateTo(AppRoute.Cons_Wallet);
      }

    }else{
      NavigateTo(data.navigate);
    }
    
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="registraion-successful">
          <div className="successful-img logo-block">
          <img src={Images.header_logo} />
          </div>
      
          <div className="success-block">
            <h2> {data.message}</h2>
          </div>
          <p>
            {[data.desc1, amount]}
            &nbsp;
            {[data.desc2]}
            {data.desc3 && (
              <>
                <br />
                {data.desc3}
              </>
            )}
          </p>
          <div className="btn-block">
            <span className="submit" onClick={btnClick}>
              {data.buttonTitle} &nbsp; {remaining}
            </span>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Successfull;
